import { baseApi, TAGS } from './baseApi'

export const modulesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getModules: builder.query({
      query: () => 'v1/modules',
      providesTags: [TAGS.MODULES]
    })
  })
})

export const { useGetModulesQuery } = modulesApi
