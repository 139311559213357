import { Button } from '@happyfoxinc/web-components'
import { useMemo } from 'react'

import styles from './AppFooter.module.css'

import IndeterminateCircle from 'Icons/indeterminate-circle.svg'

import { SYNC_STATUS } from 'Constants/syncStatus'
import { format, localDateFromUTC } from 'Utils/date'

const AppStatus = ({ syncInfo }) => {
  const { last_sync_at, is_last_sync_successful, is_sync_enabled } = syncInfo || {}

  const lastSyncAtInfo = useMemo(() => {
    if (!last_sync_at) return ''

    const lastSyncStatus = is_last_sync_successful ? 'successful' : 'unsuccessful'
    const lastSyncTimeStamp = format(localDateFromUTC(last_sync_at).toString(), 'DD MMM YYYY HH:mm')

    return `(Last sync done at ${lastSyncTimeStamp} was ${lastSyncStatus})`
  }, [last_sync_at, is_last_sync_successful])

  if (!syncInfo) {
    return <p className={styles.status}>{SYNC_STATUS.APP_ENABLED}</p>
  }

  if (!is_sync_enabled) {
    const syncDisabledMessage = `${SYNC_STATUS.DISABLED} ${lastSyncAtInfo}`
    return <p className={styles.status}>{syncDisabledMessage}</p>
  }

  const syncEnabledMessage = last_sync_at ? `${SYNC_STATUS.APP_ENABLED} ${lastSyncAtInfo}` : SYNC_STATUS.IN_PROGRESS
  return <p className={styles.status}>{syncEnabledMessage}</p>
}

const AppFooter = (props) => {
  const { onUninstall, isUninstalling = false, app = {} } = props
  const { sync_info = null } = app

  return (
    <div className={styles.container}>
      Status
      <AppStatus syncInfo={sync_info} />
      {onUninstall && (
        <Button variant='ghost' className={styles.uninstallButton} onClick={onUninstall} disabled={isUninstalling}>
          Uninstall this app <IndeterminateCircle width='1em' height='1em' />
        </Button>
      )}
    </div>
  )
}

export default AppFooter
