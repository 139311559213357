import baseApi, { TAGS } from './baseApi'

export const appsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getApps: builder.query({
      query: (params) => ({
        url: 'v1/apps',
        method: 'GET',
        params
      }),
      providesTags: (result) => [
        { type: TAGS.APPS, id: 'LIST' },
        ...(result?.map((app) => ({ type: TAGS.APPS, id: app.key })) || [])
      ]
    })
  })
})

export const { useGetAppsQuery } = appsApi
