import { Button, Dropdown, Flex, Modal } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import styles from './Topbar.module.css'

import KB_LINKS from 'Constants/kb-links'
import SlackLogo from 'Src/assetsv3/logos/slack.svg'
import WorkspaceSelect from 'Src/componentsv3/WorkspaceSelect'
import { ACCOUNT_TYPE } from 'Src/constants/account'
import { useGetAccountQuery, useGetProfileQuery } from 'Src/servicesV3/authApi'
import { openUrl, redirect } from 'Src/utils/url'

// import SearchIcon from '../../assetsv3/icons/search.svg'
import SupportHelpIcon from '../../assetsv3/icons/support-copy.svg'
import UserIcon from '../../assetsv3/icons/user.svg'

import ModalV2 from 'Components/Modal'

import { useFeature } from 'Src/components/Features'
import { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'

import Notifications from 'Src/pagesv3/Notifications'
import TimeZone from 'Src/pagesv3/Settings/TimeZone'

import { useGetAllTimeZonesQuery, useGetNotificationsQuery, useGetUserTimeZoneQuery } from 'Src/servicesV3/settingsApi'

import cx from 'classnames'

export const useImageWithFallback = (src) => {
  const [imgSrc, setImgSrc] = useState(src)
  const [error, setError] = useState(false)

  useEffect(() => {
    setImgSrc(src)
    setError(false)
  }, [src])

  const onError = () => {
    setError(true)
    setImgSrc(null)
  }

  return { imgSrc, error, onError }
}

const SettingsModal = ({ isModalOpen, setIsModalOpen }) => {
  const [selectedTab, setSelectedTab] = useState('settings')

  const { isLoading } = useGetNotificationsQuery()
  const { data: timeZones = [], isLoading: isAllTimeZonesLoading } = useGetAllTimeZonesQuery()
  const { data: userTimeZone = {}, isLoading: isUserTimeZoneLoading } = useGetUserTimeZoneQuery()

  const showTimeZone = useFeature([CLIENT_FEATURE_FLAGS.MS_TEAMS_ACCOUNT])

  const SETTINGS_TABS = useMemo(() => {
    return {
      settings: {
        label: 'Settings',
        hidden: false
      },
      timeZone: {
        label: 'Time Zone',
        hidden: !showTimeZone
      }
    }
  }, [showTimeZone])

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName)
  }

  return (
    <ModalV2
      onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      isOpen={isModalOpen}
      onCancel={handleModalClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
    >
      <form className={styles.formContainer}>
        <div className={styles.modalHeader}>
          <div className={styles.modalTabContainer}>
            {Object.keys(SETTINGS_TABS).map(
              (tabName) =>
                !SETTINGS_TABS[tabName].hidden && (
                  <div
                    key={tabName}
                    className={cx(styles.modalTabItem, selectedTab === tabName && styles.active)}
                    onClick={() => handleTabChange(tabName)}
                  >
                    {SETTINGS_TABS[tabName]?.label}
                  </div>
                )
            )}
          </div>
          <Button type='button' className={styles.closeButton} variant='secondary-outline' onClick={handleModalClose}>
            X
          </Button>
        </div>
        <div className={styles.contentContainer}>
          {selectedTab === 'settings' && <Notifications />}
          {selectedTab === 'timeZone' && (
            <TimeZone
              isLoading={isLoading || isAllTimeZonesLoading || isUserTimeZoneLoading}
              timeZones={timeZones}
              userTimeZone={userTimeZone}
              closeModal={handleModalClose}
            />
          )}
        </div>
      </form>
    </ModalV2>
  )
}

const Topbar = ({ isSearchActive, setIsSearchActive }) => {
  const [openLogoutDropdown, setOpenLogoutDropdown] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(localStorage.getItem('sidebar_collapsed_state') === 'true')

  const { data: user } = useGetProfileQuery()
  const { data: account } = useGetAccountQuery()

  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS

  const searchRef = useRef(null)

  const { imgSrc, error, onError } = useImageWithFallback(user?.avatar_url)

  const handleAccountNameClick = () => {
    openUrl(account.app_url)
  }

  useEffect(() => {
    const targetNode = document.documentElement
    const config = { attributes: true, childList: true, subtree: true }

    const callback = () => {
      const currentState = localStorage.getItem('sidebar_collapsed_state') === 'true'
      if (currentState !== sidebarCollapsed) {
        setSidebarCollapsed(currentState)
      }
    }

    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)

    return () => observer.disconnect()
  }, [sidebarCollapsed])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        setIsSearchActive(true)
      }
      if (event.key === 'Escape') {
        setIsSearchActive(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [setIsSearchActive])

  useEffect(() => {
    if (searchRef.current) {
      if (isSearchActive && searchRef.current) {
        searchRef.current.focus()
      } else {
        searchRef.current.blur()
      }
    }
  }, [isSearchActive])

  const handleLogout = async () => {
    setIsLogoutModalOpen(false)
    setOpenLogoutDropdown(false)
    redirect('logout')
  }

  return (
    <div className={styles.topbarContainer}>
      <div className={styles.searchContainer}>
        <div className={styles.searchWrapper}>
          {/* <SearchIcon className={styles.searchIcon} />
          <input ref={searchRef} type='text' className={styles.searchInput} placeholder='Search' />
          <div className={styles.searchShortcut}>
            <span className={styles.shortcutKey}>CMD ⌘</span>
            <span>K</span>
          </div> */}
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.iconButton} onClick={() => openUrl(KB_LINKS.AAI_HELP)}>
          <SupportHelpIcon />
        </div>
        <WorkspaceSelect />

        <Dropdown.Root open={openLogoutDropdown} onOpenChange={setOpenLogoutDropdown}>
          <Dropdown.Trigger asChild>
            {imgSrc && !error ? (
              <img src={imgSrc} alt='User avatar' className={styles.avatar} onError={onError} />
            ) : (
              <div className={styles.userIcon}>
                <UserIcon />
              </div>
            )}
          </Dropdown.Trigger>
          <Dropdown.Content
            className={styles.dropdownContent}
            sideOffset={6}
            align='end'
            onEscapeKeyDown={() => setOpenLogoutDropdown(false)}
            onInteractOutside={() => setOpenLogoutDropdown(false)}
          >
            <Fragment>
              <div className={styles.userInfo}>
                <p className={styles.userName}>{user?.name}</p>
                <p className={styles.userEmail}>{user?.email}</p>
              </div>
              <div className={styles.divider} />
              <div className={styles.dropdownItemContainer}>
                <div
                  className={styles.dropdownItem}
                  onClick={() => {
                    setOpenLogoutDropdown(false)
                    setIsSettingsModalOpen(true)
                  }}
                >
                  Settings
                </div>
                <div
                  className={styles.dropdownItem}
                  onClick={() => {
                    setOpenLogoutDropdown(false)
                    setIsLogoutModalOpen(true)
                  }}
                >
                  Logout
                </div>
              </div>
            </Fragment>
          </Dropdown.Content>
        </Dropdown.Root>

        <Modal
          size='small'
          title='Logout'
          open={isLogoutModalOpen}
          onCancel={() => setIsLogoutModalOpen(false)}
          showFooter={false}
          bodyClassName={styles.modalBody}
          contentClassName={styles.modalContent}
        >
          <Flex direction='column' gap='12px' align='center'>
            <p>Are you sure you want to logout?</p>
            <Flex gap='15px'>
              <Button variant='solid' onClick={handleLogout}>
                Logout
              </Button>
              <Button variant='outline' onClick={() => setIsLogoutModalOpen(false)}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Modal>

        <SettingsModal isModalOpen={isSettingsModalOpen} setIsModalOpen={setIsSettingsModalOpen} />

        <div className={styles.AccountItem} onClick={handleAccountNameClick}>
          {isSlackAccountType && <SlackLogo height='20' width='20' />}
          {isMsTeamsAccountType && <div className={styles.MicrosoftLogo} />}
          {account.account_name}
        </div>
      </div>
    </div>
  )
}

export default Topbar
