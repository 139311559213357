import { useLocation } from 'react-router-dom'

export const useWorkspaceVisibility = () => {
  const location = useLocation()

  // Add or modify paths where workspace dropdown should be visible
  const visiblePaths = ['/answers', '/apps']

  return visiblePaths.some((path) => location.pathname.startsWith(path))
}
