import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  useGetTicketingConfigurationQuery,
  useSaveTicketingConfigurationMutation
} from 'Src/servicesV3/softwareAccessModuleApi'

const ticketCreationFormSchema = yup.object().shape({
  isTicketCreationEnabled: yup.boolean(),
  category: yup
    .string()
    .nullable()
    .when('isTicketCreationEnabled', {
      is: true,
      then: (schema) => schema.required('Category is required'),
      otherwise: (schema) => schema.notRequired()
    })
    .optional(),
  solvedStatus: yup
    .string()
    .nullable()
    .when('isTicketCreationEnabled', {
      is: true,
      then: (schema) => schema.required('Solved status is required'),
      otherwise: (schema) => schema.notRequired()
    })
    .optional(),
  subject: yup.string().when('isTicketCreationEnabled', {
    is: true,
    then: (schema) => schema.required('Subject is required').min(3, 'Subject must be at least 3 characters'),
    otherwise: (schema) => schema.notRequired()
  })
})

const prepareDefaultValues = (response) => ({
  isTicketCreationEnabled: response?.is_ticket_creation_enabled || false,
  category: response?.category_id?.toString() || null,
  solvedStatus: response?.solved_status_id?.toString() || null,
  subject: response?.subject_template || ''
})

export const useTicketCreationForm = (catalogItemId, isActive) => {
  const { data: response } = useGetTicketingConfigurationQuery(
    { catalogItemId },
    {
      skip: !catalogItemId || !isActive,
      refetchOnMountOrArgChange: true
    }
  )

  const [save, saveResults] = useSaveTicketingConfigurationMutation()

  const methods = useForm({
    resolver: yupResolver(ticketCreationFormSchema),
    defaultValues: useMemo(() => prepareDefaultValues(response), [response]),
    mode: 'onChange'
  })

  useEffect(() => {
    let mounted = true

    if (response && isActive && mounted) {
      const values = prepareDefaultValues(response)
      methods.reset(values, {
        keepDefaultValues: true,
        keepDirty: false
      })
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, response])

  if (!isActive) {
    return { methods: null, isLoading: false }
  }

  const resetForm = () => {
    methods.reset(prepareDefaultValues(response), {
      errors: {},
      keepIsSubmitted: false
    })
  }

  const isTicketConfigurationFormDirty = () => {
    return !_.isEqual(methods.getValues(), prepareDefaultValues(response))
  }

  const handleSave = async (formData) => {
    await methods.trigger()
    const data = formData || methods.getValues()
    const payload = {}
    if (data.isTicketCreationEnabled) {
      payload.is_ticket_creation_enabled = true
      payload.category_id = parseInt(data.category)
      payload.solved_status_id = parseInt(data.solvedStatus)
      payload.subject_template = data.subject
    } else {
      payload.is_ticket_creation_enabled = false
    }

    return save({ catalogItemId, ...payload })
      .unwrap()
      .then((response) => response)
      .catch(() => {
        return Promise.reject(new Error('Failed to save ticketing configuration'))
      })
  }

  return { methods, resetForm, save: handleSave, isTicketConfigurationFormDirty, saveResults }
}
