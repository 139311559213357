// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z_Q7sTmPcs89maJy3dm7{height:100%;display:grid;grid-template-columns:auto 1fr;background-color:var(--page-background-color)}.Avo4TzONCWo4uMosVjNu{display:flex;flex-direction:column;height:calc(100% - var(--topbar-height));padding:2rem 3rem;overflow-y:auto}.JzInYitEu5TZfn_zbeM7{display:flex;flex-direction:column;overflow:hidden}", "",{"version":3,"sources":["webpack://./../src/layoutv3/Main/Main.module.css"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,8BAAA,CACA,6CAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,wCAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".MainContainer {\n  height: 100%;\n  display: grid;\n  grid-template-columns: auto 1fr;\n  background-color: var(--page-background-color);\n}\n\n.Content {\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - var(--topbar-height));\n  padding: 2rem 3rem;\n  overflow-y: auto;\n}\n\n.PageContainer {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainContainer": "Z_Q7sTmPcs89maJy3dm7",
	"Content": "Avo4TzONCWo4uMosVjNu",
	"PageContainer": "JzInYitEu5TZfn_zbeM7"
};
export default ___CSS_LOADER_EXPORT___;
