import baseApi, { TAGS } from './baseApi'

export const adminsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: (params) => ({
        url: 'v1/admins',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.ADMINS]
    }),

    inviteAdmin: builder.mutation({
      query: (data) => ({
        url: 'v1/admins/invite',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.ADMINS]
    }),

    resendInvite: builder.mutation({
      query: (adminId) => ({
        url: `v1/admins/${adminId}/re-invite`,
        method: 'POST'
      }),
      invalidatesTags: [TAGS.ADMINS]
    }),

    updateAdmin: builder.mutation({
      query: ({ adminId, ...data }) => ({
        url: `v1/admins/${adminId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.ADMINS]
    }),

    deleteAdmin: builder.mutation({
      query: (adminId) => ({
        url: `v1/admins/${adminId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.ADMINS]
    })
  })
})

export const {
  useGetAdminsQuery,
  useInviteAdminMutation,
  useResendInviteMutation,
  useUpdateAdminMutation,
  useDeleteAdminMutation
} = adminsApi
