import cx from 'classnames'

import styles from './Skeleton.module.css'

const Skeleton = ({ width, height, circle, className, ...props }) => {
  return (
    <div
      className={cx(styles.skeleton, { [styles.circle]: circle }, className)}
      style={{
        ...props.styles,
        width: typeof width === 'number' ? `${width}px` : width,
        height: typeof height === 'number' ? `${height}px` : height
      }}
    />
  )
}

export default Skeleton
