import { Flex } from '@happyfoxinc/web-components'
import Select, { components } from 'react-select'

const CustomOption = (props) => {
  const { data, isSelected, isFocused, innerRef, innerProps } = props
  return (
    <Flex
      align='center'
      ref={innerRef}
      {...innerProps}
      style={{
        fontFamily: 'inherit',
        fontSize: 'var(--text-sm)',
        padding: '5px 10px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#f9f9f9' : isFocused ? '#f9f9f9' : 'transparent'
      }}
    >
      <input
        type='checkbox'
        checked={isSelected}
        onChange={() => null}
        style={{ marginRight: '10px', fontFamily: 'inherit', fontSize: '12px' }}
      />
      {data.label}
    </Flex>
  )
}

const CustomMultiValue = ({ index, getValue, ...props }) => {
  const values = getValue()
  if (index >= 2) {
    if (index === 2) {
      return (
        <div
          style={{
            fontSize: '12px',
            padding: '2px 6px',
            background: '#f5f5f5',
            borderRadius: 'var(--border-radius-md)',
            marginLeft: '10px'
          }}
        >
          +{values.length - 2}
        </div>
      )
    }
    return null
  }
  return <components.MultiValue {...props} />
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='11' height='11'>
        <path
          fill='none'
          stroke='black'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='round'
          transform='matrix(-0.707107 0.707107 -0.707107 -0.707107 9.19421 4.95115)'
          d='M0 0L5.9747477 0.025252525L6 6'
          fillRule='evenodd'
        />
      </svg>
    </components.DropdownIndicator>
  )
}

const CustomMultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='12' height='11'>
        <path
          fill='none'
          stroke='black'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='bevel'
          transform='matrix(0.707107 0.707107 -0.707107 0.707107 7.73165 2.68822)'
          d='M0.25471932 0.25471932L0.25471932 6.8774219'
          fillRule='evenodd'
        />
        <path
          fill='none'
          stroke='black'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='bevel'
          transform='matrix(0.707107 0.707107 -0.707107 0.707107 3.22881 2.86833)'
          d='M0 0.25471932L6.6227026 0.25471932'
          fillRule='evenodd'
        />
      </svg>
    </components.MultiValueRemove>
  )
}

const CustomClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xlink='http://www.w3.org/1999/xlink'
        width='14'
        height='13'
        className='react-select__clear-indicator'
      >
        <path
          fill='none'
          stroke='black'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='bevel'
          transform='matrix(0.707107 0.707107 -0.707107 0.707107 9.23165 2.68822)'
          d='M0.25471932 0.25471932L0.25471932 6.8774219'
          fillRule='evenodd'
        />
        <path
          fill='none'
          stroke='black'
          strokeWidth='1'
          strokeLinecap='round'
          strokeLinejoin='bevel'
          transform='matrix(0.707107 0.707107 -0.707107 0.707107 4.72881 2.86833)'
          d='M0 0.25471932L6.6227026 0.25471932'
          fillRule='evenodd'
        />
      </svg>
    </components.ClearIndicator>
  )
}

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: '29px',
    height: '29px',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-sm)',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid var(--color-border-default)'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 4px',
    minHeight: '29px',
    height: '29px',
    fontSize: 'var(--text-sm)',
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden'
  }),
  multiValue: (base, { index }) => ({
    ...base,
    margin: '2px',
    backgroundColor: 'var(--color-surface-default)',
    borderRadius: 'var(--border-radius-sm)',
    height: '20px',
    alignItems: 'center',
    border: '1px solid var(--color-border-default)',
    maxWidth: index > 1 ? '50%' : '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: index >= 2 ? 'none' : 'flex',
    '& > div:last-child': {
      flex: '0 0 auto',
      width: '24px',
      height: '100%'
    }
  }),
  multiValueLabel: (base) => ({
    ...base,
    padding: '0 6px',
    fontSize: 'var(--text-sm)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: '1 1 auto'
  }),
  multiValueRemove: (base) => ({
    ...base,
    padding: '0',
    width: '24px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid var(--color-border-default)',
    borderRadius: '0 3px 3px 0',
    cursor: 'pointer',
    flexShrink: 0,
    '&:hover': {
      backgroundColor: '#f8f8f8'
    }
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0 8px'
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '0'
  }),
  menu: (base) => ({
    ...base,
    marginTop: '2px',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.06)',
    border: '1px solid var(--color-border-default)',
    borderRadius: '6px'
  }),
  menuList: (base) => ({
    ...base,
    padding: '4px'
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected ? '#4B7BF5' : isFocused ? '#F5F5F5' : 'transparent',
    color: isSelected ? 'var(--color-surface-default)' : '#000000',
    fontSize: 'var(--text-sm)',
    padding: '6px 8px',
    borderRadius: 'var(--border-radius-sm)',
    cursor: 'pointer',
    ':active': {
      backgroundColor: '#4B7BF5'
    }
  }),
  input: (base) => ({
    ...base,
    caretColor: 'transparent',
    fontSize: 'var(--text-sm)',
    margin: 0,
    padding: 0
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 'var(--text-sm)'
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 'var(--text-sm)',
    color: 'var(--color-text-subtle)'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
}

const SelectCheckbox = (props) => {
  return (
    <Select
      {...props}
      components={{
        ...(props.isMulti && {
          Option: CustomOption,
          MultiValue: CustomMultiValue,
          MultiValueRemove: CustomMultiValueRemove,
          ClearIndicator: CustomClearIndicator
        }),
        IndicatorSeparator: () => null,
        DropdownIndicator
      }}
      styles={customStyles}
    />
  )
}

export default SelectCheckbox
