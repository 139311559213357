import { forwardRef } from 'react'
import 'trix'
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte'

import styles from './Editor.module.scss'

import cx from 'classnames'

const toolbarItems = [
  'bold',
  'italic',
  'strike',
  'link',
  'heading1',
  'quote',
  'code',
  'bullet',
  'number',
  'undo',
  'redo'
]

const Editor = forwardRef((props, ref) => {
  const { defaultValue, onChange, ...rest } = props

  const handleChange = (_event, newValue) => {
    onChange?.(newValue)
  }

  const preventFileUpload = (event) => {
    event.preventDefault()
  }

  return (
    <div className={cx(styles.Container, rest?.styles?.editorContainer)}>
      <ReactTrixRTEToolbar toolbarId={rest?.toolbarId ?? 'trix-editor'} toolbarActions={toolbarItems} />
      <ReactTrixRTEInput
        toolbarId={rest?.toolbarId ?? 'trix-editor'}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFileAccepted={preventFileUpload}
        className={cx(styles.Content, rest?.styles?.editorContent)}
        trixInputRef={ref}
        {...rest}
      />
    </div>
  )
})

Editor.displayName = 'Editor'

export default Editor
