import { Button, Flex, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { useCallback, useMemo } from 'react'

import styles from './SurveyManagementModule.module.css'

import TableLoader from 'Src/componentsv3/TableLoader'

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg'
import SearchIcon from '../../../assetsv3/icons/search.svg'

const campaignColumns = [
  { key: 'name', label: 'Title' },
  { key: 'workspace_name', label: 'Workspace' },
  { key: 'survey_title', label: 'Survey' },
  { key: 'status', label: 'Status' },
  { key: 'distribution_schedule_type', label: 'Distribution Type' },
  { key: 'updated_at', label: 'Updated on' }
]

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const dateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }
  return date
    .toLocaleString('en-US', dateOptions)
    .replace(',', '')
    .replace(/,([^\s])/, ', $1')
}

const capitalize = (str) => {
  if (!str) return '-'
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const CampaignsContent = ({
  campaigns,
  isCampaignsLoading,
  handleCampaignClick,
  handleCampaignStatus,
  handleDeleteCampaign,
  handleCreateCampaign,
  setSearchParams,
  queryParams
}) => {
  const handlePageChange = useCallback(
    (page) => {
      setSearchParams((prev) => ({
        ...prev,
        offset: (page - 1) * (campaigns?.meta?.limit || 10)
      }))
    },
    [setSearchParams, campaigns?.meta?.limit]
  )

  const getCampaignCellContent = (campaign, key) => {
    switch (key) {
      case 'status':
        return (
          <div className={styles.statusContainer}>
            <div className={`${styles.statusDot} ${styles[campaign.status]}`} />
            <span className={styles.statusText}>{capitalize(campaign.status)}</span>
          </div>
        )
      case 'updated_at':
        return formatDate(campaign[key])
      case 'survey_title':
      case 'workspace_name':
      case 'name':
      case 'distribution_schedule_type':
        return capitalize(campaign[key])
      default:
        return campaign[key] ? capitalize(campaign[key]) : '-'
    }
  }

  const renderCampaignCell = useMemo(() => {
    return campaigns.results.map((campaign) => ({
      ...campaign,
      renderedCells: campaignColumns.reduce(
        (acc, header) => ({
          ...acc,
          [header.key]: getCampaignCellContent(campaign, header.key)
        }),
        {}
      )
    }))
  }, [campaigns.results, getCampaignCellContent])

  if (isCampaignsLoading) {
    return <TableLoader rows={4} columns={6} showOptions />
  }

  if (!campaigns?.results?.length && !queryParams.search) {
    return (
      <div className={styles.configurationContainer}>
        <h2 className={styles.noConfigTitle}>No campaigns created yet</h2>
        <p className={styles.noConfigDescription}>
          Create your first campaign to start collecting feedback from your users.
        </p>
        <Button className={styles.buttonContainter} onClick={handleCreateCampaign}>
          Create Campaign
          <span className={styles.arrowContainer}>
            <ArrowRightIcon height='1em' width='1em' />
          </span>
        </Button>
      </div>
    )
  }

  const currentPage = Math.floor((campaigns?.meta?.offset || 0) / (campaigns?.meta?.limit || 10)) + 1

  return (
    <div className={styles.tableContainer}>
      <Flex justify='space-between' align='center' className={styles.tableHeader}>
        <h2 className={styles.tableTitle}>Campaign List</h2>
        <button className={styles.addButton} onClick={handleCreateCampaign}>
          Create Campaign
          <span>+</span>
        </button>
      </Flex>

      <Flex justify='space-between' align='center' className={styles.tableActions}>
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search campaigns'
              value={queryParams.search}
              onChange={(e) => setSearchParams({ ...queryParams, search: e.target.value })}
            />
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          pageSize={campaigns?.meta?.limit || 10}
          totalItems={campaigns?.meta?.total || 0}
          canPreviousPage={currentPage > 1}
          canNextPage={currentPage < Math.ceil((campaigns?.meta?.total || 0) / (campaigns?.meta?.limit || 10))}
          previousPage={() => handlePageChange(currentPage - 1)}
          nextPage={() => handlePageChange(currentPage + 1)}
        />
      </Flex>

      <Table.Root>
        <Table.Header>
          <Table.Row>
            {campaignColumns.map((header) => (
              <Table.ColumnHeaderCell key={header.key}>{header.label}</Table.ColumnHeaderCell>
            ))}
            <Table.ColumnHeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderCampaignCell.map((campaign) => (
            <Table.Row key={campaign.id} clickable>
              {campaignColumns.map((header) => (
                <Table.Cell key={`${campaign.id}-${header.key}`} onClick={() => handleCampaignClick(campaign)}>
                  {campaign.renderedCells[header.key]}
                </Table.Cell>
              ))}
              <TableOptions>
                <TableOption
                  onClick={(e) =>
                    handleCampaignStatus(
                      e,
                      campaign.id,
                      campaign.status,
                      campaign.distribution_schedule_type,
                      campaign.expiry_date
                    )
                  }
                >
                  {campaign.status !== 'closed' ? 'Stop Campaign' : 'Activate Campaign'}
                </TableOption>
                <TableOption onClick={(e) => handleDeleteCampaign(e, campaign.id)}>Delete</TableOption>
              </TableOptions>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>
    </div>
  )
}

export default CampaignsContent
