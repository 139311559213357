import { Button } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import styles from './ReportsModule.module.css'

import ReactSelect from 'Src/componentsv3/ReactSelect'
import DateRangePicker from 'Src/pagesv3/Modules/SurveyManagementModule/components/DateRangePicker/DateRangePicker'
import {
  useExportQuestionResponsesMutation,
  useExportSurveyReportsMutation,
  useGetCampaignsQuery,
  useGetSurveyReportsQuery
} from 'Src/servicesV3/campaignApi'
import { useGetSurveysQuery } from 'Src/servicesV3/surveyApi'

import BackIcon from '../../../../assetsv3/icons/corner-up-left.svg'
import ExportIcon from '../../../../assetsv3/icons/export.svg'
import PercentIcon from '../../../../assetsv3/icons/percent.svg'
import SendIcon from '../../../../assetsv3/icons/send.svg'
import QuestionReport from './QuestionReport'
import ReportsLoader from './ReportsLoader/ReportsLoader'

const ReportsModule = () => {
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const { data: surveys } = useGetSurveysQuery()
  const { data: campaigns } = useGetCampaignsQuery()
  const { data: surveyReport, isLoading } = useGetSurveyReportsQuery(
    {
      survey_id: selectedSurvey?.value,
      campaign: selectedCampaign?.value,
      from: fromDate,
      to: toDate
    },
    { skip: !selectedSurvey }
  )
  const [exportSurveyReports] = useExportSurveyReportsMutation()
  const [exportQuestionResponses] = useExportQuestionResponsesMutation()

  useEffect(() => {
    if (surveys?.results?.length > 0 && !selectedSurvey) {
      const validSurveys = surveys.results.filter((survey) => survey.questions_count > 0)
      if (validSurveys.length > 0) {
        const firstSurvey = validSurveys[0]
        setSelectedSurvey({ value: firstSurvey.id, label: firstSurvey.title })
      }
    }
  }, [surveys, selectedSurvey]);

  const getFilteredCampaigns = useMemo(() => {
    if (!campaigns?.results || !selectedSurvey?.value) return []

    return campaigns.results
      .filter((campaign) => campaign.survey_id === selectedSurvey.value)
      .map((campaign) => ({
        label: campaign.name,
        value: campaign.id
      }))
  }, [campaigns?.results, selectedSurvey?.value])

  const metrics = useMemo(() => {
    if (!surveyReport) return { sent: 0, responses: 0, rate: '0%' };
  
    const totalRequests = surveyReport.total_requests || 0;
    const totalResponses = surveyReport.total_responses || 0;
    const responseRate =
      totalRequests === 0
        ? 0
        : Math.round((totalResponses / totalRequests) * 100);
  
    return {
      sent: totalRequests,
      responses: totalResponses,
      rate: `${responseRate}%`,
    };
  }, [surveyReport]);

  const handleExport = useCallback(async () => {
    const exportPromise = exportSurveyReports({
      survey_id: selectedSurvey?.value,
      campaign: selectedCampaign?.value,
      from: fromDate,
      to: toDate
    })

    toast.promise(exportPromise, {
      loading: 'Exporting reports...',
      success: 'Reports exported successfully',
      error: 'Failed to export reports'
    })
  }, [selectedSurvey?.value, selectedCampaign?.value, fromDate, toDate, exportSurveyReports])

  const handleQuestionDownload = useCallback(
    async (questionId) => {
      const exportPromise = exportQuestionResponses({
        survey_id: selectedSurvey?.value,
        question_id: questionId,
        campaign: selectedCampaign?.value,
        from: fromDate,
        to: toDate
      })

      toast.promise(exportPromise, {
        loading: 'Exporting question responses...',
        success: 'Question responses exported successfully',
        error: 'Failed to export question responses'
      })
    },
    [selectedSurvey?.value, selectedCampaign?.value, fromDate, toDate, exportQuestionResponses]
  )

  const clearFilters = useCallback(() => {
    setSelectedCampaign(null)
    setFromDate(null)
    setToDate(null)
  }, [])

  const handleDateRangeChange = useCallback((from, to) => {
    setFromDate(from)
    setToDate(to)
  }, [])

  const surveyOptions = useMemo(() => {
    return (
      surveys?.results
        ?.filter((survey) => survey.questions_count > 0)
        ?.map((survey) => ({
          label: survey.title,
          value: survey.id
        })) || []
    )
  }, [surveys?.results])

  return (
    <Fragment>
      <div className={styles.clearFiltersContainer}>
        <button className={styles.clearButton} onClick={clearFilters}>
          Clear Filters
        </button>
      </div>
      <div className={styles.container}>
        <div className={styles.filtersSection}>
          <span className={styles.filterLabel}>Filter by</span>
          <div className={styles.filterControls}>
            <ReactSelect
              value={selectedSurvey}
              onChange={setSelectedSurvey}
              options={surveyOptions}
              className={styles.filterSelect}
              placeholder='Select Survey'
            />
            <ReactSelect
              className={styles.filterSelect}
              value={selectedCampaign}
              onChange={setSelectedCampaign}
              options={getFilteredCampaigns}
              placeholder='Select Campaign'
              isDisabled={!selectedSurvey}
            />
            <DateRangePicker fromDate={fromDate} toDate={toDate} disableFutureDates onChange={handleDateRangeChange} />
            <Button className={styles.exportButton} onClick={handleExport}>
              Export Reports
              <ExportIcon className={styles.exportIcon} />
            </Button>
          </div>
        </div>
        <div className={styles.reportsContainer}>
          {isLoading ? (
            <ReportsLoader />
          ) : (
            selectedSurvey &&
            surveyReport && (
              <Fragment>
                <h2 className={styles.surveyTitle}>{surveyReport.survey}</h2>

                <div className={styles.metricsContainer}>
                  <div className={styles.metricBox}>
                    <div className={styles.metricLeft}>
                      <SendIcon />
                      <span className={styles.metricLabel}>Invites Sent</span>
                    </div>
                    <span className={styles.metricValue}>{metrics.sent}</span>
                  </div>
                  <div className={styles.metricBox}>
                    <div className={styles.metricLeft}>
                      <BackIcon />
                      <span className={styles.metricLabel}>Responses</span>
                    </div>
                    <span className={styles.metricValue}>{metrics.responses}</span>
                  </div>
                  <div className={styles.metricBox}>
                    <div className={styles.metricLeft}>
                      <PercentIcon />
                      <span className={styles.metricLabel}>Response Rate</span>
                    </div>
                    <span className={styles.metricValue}>{metrics.rate}</span>
                  </div>
                </div>
                {surveyReport.total_responses !== 0 && (
                  <div className={styles.questionsContainer}>
                    {surveyReport.questions.map((question, index) => (
                      <QuestionReport
                        key={index}
                        questionNumber={index + 1}
                        question={question}
                        onDownload={() => handleQuestionDownload(question.id)}
                      />
                    ))}
                  </div>
                )}
              </Fragment>
            )
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default ReportsModule
