import { Box } from '@happyfoxinc/web-components'
import { Fragment, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './UserGroupForm.module.css'

import NoRender from 'Components/NoRender'
import { APP_SYNCED_FROM_DEFINITIONS, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useGetUserGroupMetadataQuery } from 'Src/servicesV3/userGroupsApi'

const { MANUAL, CONDITIONAL, SYNCED_FROM_APP } = USER_MANAGEMENT_TYPE

const FormatGroupLabel = ({ type }) =>
  type === SYNCED_FROM_APP && (
    <Fragment>
      <div className={styles.addedFromGroupTitleOr}>or</div>
      <div className={styles.addedFromGroupTitleSync}>Sync From</div>
    </Fragment>
  )

const FormatOptionLabel = ({ label, logo }) => {
  const Logo = logo || NoRender
  return (
    <div className={styles.addedFromOptionContainer}>
      {logo && (
        <div className={styles.addedFromLogoContainer}>
          <Logo />
        </div>
      )}
      <div>{label}</div>
    </div>
  )
}

const AddedFromField = ({ onChange }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const { data: optionsMetaData = {}, isLoading: isOptionsLoading } = useGetUserGroupMetadataQuery()

  const options = useMemo(() => {
    const { available_user_management_types: availableTypes, apps_available_to_sync_from: availableApps } =
      optionsMetaData

    if (!availableTypes) {
      return []
    }

    return availableTypes.map((type) => {
      if (type === MANUAL) {
        return {
          type: MANUAL,
          options: [APP_SYNCED_FROM_DEFINITIONS[MANUAL]]
        }
      }
      if (type === CONDITIONAL && optionsMetaData.condition_fields) {
        return {
          type: CONDITIONAL,
          options: [APP_SYNCED_FROM_DEFINITIONS[CONDITIONAL]]
        }
      }
      return {
        type: SYNCED_FROM_APP,
        options: availableApps.map((app) => APP_SYNCED_FROM_DEFINITIONS[app])
      }
    })
  }, [optionsMetaData])

  const customStyles = {
    valueContainer: (provided, { selectProps: { value } }) => ({
      ...provided,
      ...(value && value.value !== MANUAL && value.value !== CONDITIONAL && { paddingLeft: '1px' })
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused || isSelected ? '#F9F9F9' : 'var(--color-surface-default)',
      color: 'var(--color-text-default)'
    }),
    group: (provided, { data: { type } }) => ({
      ...provided,
      ...((type === MANUAL || type === CONDITIONAL) && { paddingTop: '0', paddingBottom: '0' }),
      ...(type !== MANUAL && type !== CONDITIONAL && { paddingTop: '5px' })
    }),
    groupHeading: (provided) => ({
      ...provided,
      textTransform: 'auto',
      fontSize: '13px'
    })
  }

  return (
    <FormField>
      <FormField.Field label='How do you want to add users?' error={errors?.addedFrom?.message}>
        <Box width='330px'>
          <Controller
            name='addedFrom'
            control={control}
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  styles={customStyles}
                  isSearchable={false}
                  onChange={(opt) => {
                    field.onChange(opt)
                    onChange()
                  }}
                  options={options}
                  formatGroupLabel={FormatGroupLabel}
                  formatOptionLabel={FormatOptionLabel}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  isLoading={isOptionsLoading}
                />
              )
            }}
          />
        </Box>
      </FormField.Field>
    </FormField>
  )
}

export default AddedFromField
