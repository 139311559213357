import { ACCOUNT_TYPE } from 'Constants/account'

import baseApi, { TAGS } from './baseApi'

export const userGroupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserGroups: builder.query({
      query: (params) => ({
        url: 'v1/user-groups',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.USER_GROUPS]
    }),

    getUserGroup: builder.query({
      query: (userGroupId) => ({
        url: `v1/user-groups/${userGroupId}`,
        method: 'GET'
      }),
      providesTags: [TAGS.USER_GROUPS]
    }),

    addUserGroup: builder.mutation({
      query: (data) => ({
        url: 'v1/user-groups',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.USER_GROUPS]
    }),

    updateUserGroup: builder.mutation({
      query: ({ userGroupId, ...data }) => ({
        url: `v1/user-groups/${userGroupId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.USER_GROUPS]
    }),

    deleteUserGroup: builder.mutation({
      query: (userGroupId) => ({
        url: `v1/user-groups/${userGroupId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.USER_GROUPS]
    }),

    getUserGroupMetadata: builder.query({
      query: () => ({
        url: 'v1/user-groups/meta-info',
        method: 'GET'
      })
    }),

    reSyncUserGroup: builder.mutation({
      query: (userGroupId) => ({
        url: `v1/user-groups/${userGroupId}/resync`,
        method: 'POST'
      })
    }),

    getAppUserGroups: builder.query({
      query: ({ appName, ...params }) => ({
        url: `v1/apps/${appName}/user-groups`,
        method: 'GET',
        params
      })
    }),

    getSlackUsers: builder.query({
      query: (searchTerm) => ({
        url: `v1/slack/users?name=${searchTerm}`,
        method: 'GET'
      })
    }),

    getMsTeamsUsers: builder.query({
      query: (searchTerm) => ({
        url: `v1/ms-teams/users?name=${searchTerm}`,
        method: 'GET'
      })
    }),

    getUsers: builder.query({
      query: ({ accountType, name }) => ({
        url: accountType === ACCOUNT_TYPE.MS_TEAMS ? `v1/ms-teams/users?name=${name}` : `v1/slack/users?name=${name}`,
        method: 'GET'
      })
    })
  })
})

export const {
  useGetUserGroupsQuery,
  useGetUserGroupQuery,
  useAddUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
  useGetUserGroupMetadataQuery,
  useReSyncUserGroupMutation,
  useGetAppUserGroupsQuery,
  useGetSlackUsersQuery,
  useGetMsTeamsUsersQuery,
  useGetUsersQuery
} = userGroupsApi
