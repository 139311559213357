import styles from './ContentListWithCount.module.css'

import ReadMore from 'Src/componentsv3/ReadMore'

const ContentListWithCount = ({ list }) => {
  return (
    <ul className={styles.listContainer}>
      {list?.map((item, i) => (
        <li key={item + i} className={styles.listItem}>
          <ReadMore content={item.title} count={item.count} />
        </li>
      ))}
    </ul>
  )
}

export default ContentListWithCount
