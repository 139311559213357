import styles from './QuestionReport.module.css'

import DownloadIcon from 'Src/assetsv3/icons/download.svg'

const QuestionReport = ({ questionNumber, question, onDownload }) => {
  const renderChoiceResponses = (answers) => {
    if (!answers?.answers) return null

    return answers.answers.map((response, index) => (
      <div key={index} className={styles.responseItem}>
        <div className={styles.percentageText}>{response.percentage}%</div>
        <div className={styles.progressBar}>
          <div className={styles.progressFill} style={{ width: `${response.percentage * 0.9}%` }} />
          <div className={styles.responseContent}>
            <span className={styles.responseText}>{response.answer}</span>
            <span className={styles.responseCount}>({response.count})</span>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div className={styles.container}>
      <div className={styles.questionText}>
        {questionNumber}. {question.question}
        <span className={styles.requirementStatus}>({question.is_required ? 'required' : 'optional'})</span>
      </div>

      {question.question_type === 'open_ended' ? (
        <button className={styles.openEndedResponse} onClick={onDownload}>
          Download
          <DownloadIcon />
        </button>
      ) : (
        <div className={styles.responseList}>{renderChoiceResponses(question.answers)}</div>
      )}
    </div>
  )
}

export default QuestionReport
