import { Flex } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const AppInstalledCardSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <Flex gap='12px' align='center'>
      <div className={styles.iconSkeleton} />
      <div>
        <div className={`${styles.shimmerBg} ${styles.h20} ${styles.w180} ${styles.mb8}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w240} ${styles.opacity70}`} />
      </div>
    </Flex>
  </div>
)

const KnowledgeConfigurationSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />

    <Flex justify='flex-end' className={styles.mb16}>
      <div className={styles.paginationContainer}>
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w80}`} />
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
      </div>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={`${styles.grid} ${styles.gridCols3} ${styles.headerBg}`}>
        <div className={`${styles.shimmerBg} ${styles.h16}`} />
        <div className={`${styles.shimmerBg} ${styles.h16}`} />
        <div className={`${styles.shimmerBg} ${styles.h16}`} />
      </div>

      {[1, 2, 3, 4].map((row) => (
        <div key={row} className={`${styles.grid} ${styles.gridCols3} ${styles.tableRow}`}>
          <Flex gap='8px' align='center'>
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} />
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
          </Flex>
          <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w80} ${styles.badgeRadius}`} />
          <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w24}`} />
        </div>
      ))}
    </div>
  </div>
)

const AppFooterSkeleton = () => (
  <div className={styles.footerSkeleton}>
    <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
  </div>
)

const GuruSkeleton = () => {
  return (
    <Flex width='100%' gap='25px' direction='column'>
      <AppInstalledCardSkeleton />
      <KnowledgeConfigurationSkeleton />
      <AppFooterSkeleton />
    </Flex>
  )
}

export default GuruSkeleton
