import styles from './ProgressBar.module.css'

const cleanPercent = (percent) => {
  let cleanedPercent = percent

  if (percent < 0 || isNaN(percent)) {
    cleanedPercent = 0
  } else if (percent > 100) {
    cleanedPercent = 100
  }

  return cleanedPercent
}

const ProgressBar = ({ percent }) => {
  const cleanedPercent = cleanPercent(percent)
  return (
    <div className={styles.progressBar}>
      <div style={{ width: cleanedPercent + '%' }} className={styles.fill} />
    </div>
  )
}

export default ProgressBar
