import cx from 'classnames'
import { Fragment } from 'react'

import styles from './MetricCards.module.css'

import { ACCOUNT_TYPE } from 'Constants/account'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'

const MetricTable = ({
  primaryCount,
  othersCount,
  totalCount,
  othersDisabled = false,
  othersNotApplicable = false
}) => {
  const { data: account = {} } = useGetAccountQuery()
  const accountType = account?.account_type === ACCOUNT_TYPE.SLACK ? 'Slack' : 'MS Teams'

  const isOtherCountValid = !!othersCount && !isNaN(othersCount)
  const isOtherDisabledAndCountPresent = othersDisabled && isOtherCountValid

  let othersValue = othersCount
  if (othersNotApplicable) {
    othersValue = 'N/A*'
  } else if (othersDisabled && !isOtherCountValid) {
    othersValue = 'Not Enabled'
  }

  const othersValueDisabled = othersDisabled || othersNotApplicable
  const othersValueNoCount = isNaN(othersValue)

  let othersLabel = 'Other Channels'
  if (!othersNotApplicable && isOtherDisabledAndCountPresent) {
    othersLabel += ' (Not Enabled)'
  }

  return (
    <Fragment>
      <div className={styles.metricRow}>
        <div className={styles.rowLabel}>{accountType}</div>
        <div className={styles.rowValue}>{primaryCount}</div>
      </div>
      <div className={styles.metricRow}>
        <div className={styles.rowLabel}>{othersLabel}</div>
        <div
          className={cx(styles.rowValue, {
            [styles.rowValueDisabled]: othersValueDisabled,
            [styles.rowValueNoCount]: othersValueNoCount
          })}
        >
          {othersValue}
        </div>
      </div>
      <div className={cx(styles.metricRow, styles.totalRow)}>
        <div className={styles.rowLabel}>Total</div>
        <div className={styles.rowValue}>{totalCount}</div>
      </div>
    </Fragment>
  )
}

export default MetricTable
