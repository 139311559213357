import { Outlet, Route, Routes } from 'react-router-dom'

import CompanyPolicyModule from './CompanyPolicyModule/CompanyPolicyModule'
import ModulesSection from './ModulesSection'
import SoftwareAccessModule from './SoftwareAccessModule/SoftwareAccessModule'
import SurveyManagementModule from './SurveyManagementModule/SurveyManagementModule'

const Modules = () => (
  <Routes>
    <Route path='/' element={<Outlet />}>
      <Route index element={<ModulesSection />} />
      <Route path='software-access' element={<SoftwareAccessModule />} />
      <Route path='company-policy' element={<CompanyPolicyModule />} />
      <Route path='survey-management' element={<SurveyManagementModule />} />
    </Route>
  </Routes>
)

export default Modules
