import { Button, Flex, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { useCallback, useMemo } from 'react'

import styles from './SurveyManagementModule.module.css'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import TableLoader from 'Src/componentsv3/TableLoader'
import debounce from 'Src/utils/debounce'

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg'
import SearchIcon from '../../../assetsv3/icons/search.svg'
import WarningIcon from '../../../assetsv3/icons/warning.svg'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const dateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  }
  return date
    .toLocaleString('en-US', dateOptions)
    .replace(',', '')
    .replace(/,([^\s])/, ', $1')
}

const SurveysContent = ({
  surveys,
  isSurveysLoading,
  handleStartSetup,
  handleRowClick,
  handleDeleteSurvey,
  activeMenu,
  setActiveMenu,
  setSearchParams,
  queryParams
}) => {
  const columnHeaders = useMemo(
    () => [
      { key: 'title', label: 'Title' },
      {
        key: 'questions_count',
        label: 'Questions',
        render: (value) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {`${value} Question${value !== 1 ? 's' : ''}`}
            {value === 0 && (
              <Tooltip delayDuration={150}>
                <TooltipTrigger style={{ display: 'flex', border: 'none', background: 'none', padding: 0 }}>
                  <WarningIcon style={{ width: '16px', height: '16px', display: 'block', color: '#ffa726' }} />
                </TooltipTrigger>
                <TooltipContent>
                  <span>Please add questions before distributing the survey</span>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        )
      },
      {
        key: 'campaigns_count',
        label: 'Campaigns',
        render: (value) => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {`${value} Campaign${value !== 1 ? 's' : ''}`}
            {value === 0 && (
              <Tooltip delayDuration={150}>
                <TooltipTrigger style={{ display: 'flex', border: 'none', background: 'none', padding: 0 }}>
                  <WarningIcon style={{ width: '16px', height: '16px', display: 'block', color: '#ffa726' }} />
                </TooltipTrigger>
                <TooltipContent>
                  <span>No campaigns configured for this survey</span>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        )
      },
      { key: 'description', label: 'Description' },
      { key: 'updated_at', label: 'Updated On' }
    ],
    []
  )

  const debouncedSetSearch = useCallback(
    debounce((value) => {
      setSearchParams((prev) => ({ ...prev, search: value }))
    }, 300),
    [setSearchParams]
  )

  const renderCell = useCallback(
    (survey, header) => {
      if (header) {
        if (header.key === 'created_at' || header.key === 'updated_at') {
          return formatDate(survey[header.key])
        }
        if (header.render) {
          return header.render(survey[header.key])
        }
        return survey[header.key]
      }
      return (
        <div className={styles.menuContainer}>
          <button className={styles.menuButton} onClick={(e) => toggleMenu(survey.id, e)}>
            ⋮
          </button>
          {activeMenu === survey.id && (
            <div className={styles.menuDropdown}>
              <button className={styles.menuItem} onClick={(e) => handleDeleteSurvey(e, survey.id)}>
                Remove
              </button>
            </div>
          )}
        </div>
      )
    },
    [activeMenu, handleDeleteSurvey]
  )

  const toggleMenu = useCallback(
    (surveyId, event) => {
      event.stopPropagation()
      setActiveMenu(activeMenu === surveyId ? null : surveyId)
    },
    [activeMenu, setActiveMenu]
  )

  const handleSearchInputChange = (e) => {
    debouncedSetSearch(e.target.value)
  }

  const handlePageChange = useCallback(
    (page) => {
      setSearchParams((prev) => ({
        ...prev,
        offset: (page - 1) * (surveys?.meta?.limit || 10)
      }))
    },
    [setSearchParams, surveys?.meta?.limit]
  )

  if (isSurveysLoading) {
    return <TableLoader rows={4} columns={4} showOptions />
  }

  const renderTableContent = () => (
    <Table.Root>
      <Table.Header>
        <Table.Row>
          {columnHeaders.map((header) => (
            <Table.ColumnHeaderCell key={header.key}>{header.label}</Table.ColumnHeaderCell>
          ))}
          <Table.ColumnHeaderCell isOption />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {surveys?.results?.map((survey) => (
          <Table.Row key={survey.id} clickable>
            {columnHeaders.map((header) => (
              <Table.Cell key={`${survey.id}-${header.key}`} onClick={() => handleRowClick(survey)}>
                {renderCell(survey, header)}
              </Table.Cell>
            ))}
            <TableOptions>
              <TableOption onClick={(e) => handleDeleteSurvey(e, survey.id)}>Delete</TableOption>
            </TableOptions>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  )

  // Only show the "No Surveys" view when there are no surveys and no search query
  if (!surveys?.results?.length && !queryParams?.search) {
    return (
      <div className={styles.ConfigurationContainer}>
        <h2 className={styles.NoConfigTitle}>No Surveys created yet</h2>
        <p className={styles.NoConfigDescription}>
          To get the survey module up and running, set up your survey questions and customize response options.
        </p>
        <Button className={styles.ButtonContainter} onClick={handleStartSetup}>
          Create Survey
          <span className={styles.ArrowContainer}>
            <ArrowRightIcon height='1em' width='1em' />
          </span>
        </Button>
      </div>
    )
  }

  const currentPage = Math.floor((surveys?.meta?.offset || 0) / (surveys?.meta?.limit || 10)) + 1

  return (
    <div className={styles.tableContainer}>
      <Flex justify='space-between' align='center' className={styles.tableHeader}>
        <h2 className={styles.tableTitle}>Survey List</h2>
        <button className={styles.addButton} onClick={handleStartSetup}>
          Create Survey
          <span>+</span>
        </button>
      </Flex>

      <Flex justify='space-between' align='center' className={styles.tableActions}>
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search surveys'
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          pageSize={surveys?.meta?.limit || 10}
          totalItems={surveys?.meta?.total || 0}
          canPreviousPage={currentPage > 1}
          canNextPage={currentPage < Math.ceil((surveys?.meta?.total || 0) / (surveys?.meta?.limit || 10))}
          previousPage={() => handlePageChange(currentPage - 1)}
          nextPage={() => handlePageChange(currentPage + 1)}
        />
      </Flex>
      {renderTableContent()}
    </div>
  )
}

export default SurveysContent
