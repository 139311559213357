import { Button, Flex, Pagination, Table } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { usePagination, useTable } from 'react-table'
import * as yup from 'yup'

import styles from './GitBook.module.css'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import {
  useAddSpaceToSyncMutation,
  useGetAvailableGitBookSpacesQuery,
  useGetCurrentlySyncingGitBookSpacesQuery
} from 'Src/servicesV3/gitbookApi'
import debounce from 'Src/utils/debounce'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { GitBookSpacesSkeleton } from './GitBookSkeleton'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}

export const gitBooksFormValidationSchema = yup.object().shape({
  space_name: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string()
    })
    .required('Space is required')
})

const SpacesList = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))

  const { data = {}, isLoading } = useGetCurrentlySyncingGitBookSpacesQuery(queryParams)
  const { results: spaces = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Space Name',
        accessor: 'title'
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: spaces,
      initialState: { pageIndex: 0, pageSize: PAGE_SIZE },
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [currentWorkspaceId, debouncedSetQueryParams, pageIndex])

  if (isLoading) {
    return 'Loading...'
  }

  return (
    <Fragment>
      <h2 className={styles.heading}>Spaces currently syncing from</h2>
      <Flex justify='flex-end' className={styles.tableActionContainer}>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Header>
        {spaces.length === 0 ? (
          <div className={styles.emptyState}>No spaces found</div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.BodyCell key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </Table.BodyCell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table.Root>
    </Fragment>
  )
}

const GitBookSpaces = () => {
  const { currentWorkspaceId } = useWorkspace()
  const { data, isLoading: spacesApiLoading } = useGetAvailableGitBookSpacesQuery({ workspace_id: currentWorkspaceId })
  const [addSpaceToSync, { isLoading }] = useAddSpaceToSyncMutation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      space_name: null
    },
    resolver: yupResolver(gitBooksFormValidationSchema)
  })

  const spacesOptions = data?.map((space) => ({
    value: space.id,
    label: space.title
  }))

  const handleAddSpace = async (data) => {
    const payload = {
      workspace_id: currentWorkspaceId,
      space_id: data.space_name.value
    }

    try {
      const promise = addSpaceToSync(payload).unwrap()

      toast.promise(promise, {
        loading: 'Adding space to sync',
        success: 'Space added to sync successfully',
        error: 'Failed to add space to sync'
      })

      reset()
    } catch (error) {}
  }

  if (spacesApiLoading) <GitBookSpacesSkeleton />

  return (
    <div>
      <h2 className={styles.heading}>Add a GitBook space to sync</h2>
      <form onSubmit={handleSubmit(handleAddSpace)} className={styles.AddSpaceForm}>
        <FormField>
          <FormField.Field error={errors.site?.message}>
            <Flex direction='column' align='flex-start' gap='1rem'>
              <Controller
                control={control}
                name='site'
                rules={{ required: 'Search and select a site to add' }}
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      options={spacesOptions}
                      placeholder='Select a space'
                      isSearchable
                      isClearable
                    />
                  )
                }}
              />
              <Button variant='outline' type='submit' disabled={isLoading || isSubmitting}>
                + Add
              </Button>
            </Flex>
          </FormField.Field>
        </FormField>
      </form>
      <SpacesList />
    </div>
  )
}

export default GitBookSpaces
