import { Outlet, Route, Routes } from 'react-router-dom'

import UserGroupCreate from './UserGroupCreate'
import UserGroupEdit from './UserGroupEdit'
import UserGroups from './UserGroups'

const UserRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route path='create' element={<UserGroupCreate />} />
        <Route path=':id' index element={<UserGroupEdit />} />
        <Route path='' index element={<UserGroups />} />
      </Route>
    </Routes>
  )
}

export default UserRouter
