import { Button } from '@happyfoxinc/web-components'
import cx from 'classnames'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './ConfigModal.module.css'

import Modal, { ModalBody } from 'Components/Modal'
import AccessControl from 'Src/pagesv3/Modules/SoftwareAccessModule/AccessControl/AccessControl'
import { useAccessControlForm } from 'Src/pagesv3/Modules/SoftwareAccessModule/hooks/useAccessControlForm'
import { useProvisionForm } from 'Src/pagesv3/Modules/SoftwareAccessModule/hooks/useProvisionForm'
import { useTicketCreationForm } from 'Src/pagesv3/Modules/SoftwareAccessModule/hooks/useTicketCreationForm'
import Provisioning from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/Provisioning'
import TicketCreation from 'Src/pagesv3/Modules/SoftwareAccessModule/TicketCreation/TicketCreation'

import { useSoftwareContext } from './SoftwareContext'

const SOFTWARE_CONFIGURATION_TABS = {
  access_control: {
    label: 'Access Control'
  },
  provisioning: {
    label: 'Provisioning'
  },
  ticketing: {
    label: 'Ticketing'
  }
}

const useFormNavigation = (selectedTab) => {
  const getNextTab = useCallback(() => {
    const tabs = Object.keys(SOFTWARE_CONFIGURATION_TABS)
    const currentIndex = tabs.indexOf(selectedTab)
    return tabs[currentIndex + 1] || null
  }, [selectedTab])

  const getPreviousTab = useCallback(() => {
    const tabs = Object.keys(SOFTWARE_CONFIGURATION_TABS)
    const currentIndex = tabs.indexOf(selectedTab)
    return tabs[currentIndex - 1] || null
  }, [selectedTab])

  return { getNextTab, getPreviousTab }
}

const ConfigurationModal = () => {
  const { selectedApp, isModalOpen, catalogItemId, selectedFeature, resetModalState } = useSoftwareContext()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [lastUserAction, setLastUserAction] = useState(null)
  const [selectedTab, setSelectedTab] = useState()

  const isEdit = !!selectedApp?.configuration

  useEffect(() => {
    if (!isModalOpen || !selectedApp || !selectedApp.configuration) {
      setSelectedTab('access_control')
      return
    }

    if (selectedFeature) {
      setSelectedTab(selectedFeature)
      return
    }

    const yetToConfig = Object.keys(selectedApp.configuration)
      .sort()
      .find((feature) => !selectedApp.configuration[feature])

    setSelectedTab(yetToConfig || 'access_control')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp, selectedFeature])

  const { getNextTab, getPreviousTab } = useFormNavigation(selectedTab)

  const {
    methods: accessControlMethods,
    resetForm: resetAccessControlForm,
    save: onSaveAccessControl,
    handleAddAccessControl,
    handleRemoveAccessControl,
    updateAccessControl,
    isLoading,
    saveResults: saveAccessControlResults,
    isAccessControlFormDirty
  } = useAccessControlForm(catalogItemId, selectedTab === 'access_control')

  const {
    methods: provisionMethods,
    resetForm: resetProvisionForm,
    addStep: addProvisionStep,
    removeStep: removeProvisionStep,
    updateStep: updateProvisionStep,
    insertStep: insertProvisionStep,
    save: saveProvisioningSteps,
    saveResults: saveProvisioningResults,
    isLoading: isLoadingProvision,
    isProvisionFormDirty
  } = useProvisionForm(catalogItemId, selectedTab === 'provisioning')

  const {
    methods: ticketingMethods,
    resetForm: resetTicketingForm,
    save: saveTicketConfiguration,
    isTicketConfigurationFormDirty
  } = useTicketCreationForm(catalogItemId, selectedTab === 'ticketing')

  const onSave = async (data) => {
    if (selectedTab === 'access_control' && accessControlMethods) {
      await onSaveAccessControl(data)
        .then(() => {
          setHasUnsavedChanges(false)
          setLastUserAction(null)
          if (isEdit) {
            toast.success('Access control settings saved successfully')
          }
        })
        .catch(() => {
          toast.error('Failed to save access control settings')
        })
    }

    if (selectedTab === 'provisioning' && provisionMethods) {
      await saveProvisioningSteps(data)
        .then(() => {
          setHasUnsavedChanges(false)
          setLastUserAction(null)
          if (isEdit) {
            toast.success('Provisioning steps saved successfully')
          }
        })
        .catch(() => {
          toast.error('Failed to save provisioning steps')
        })
    }

    if (selectedTab === 'ticketing' && ticketingMethods) {
      await saveTicketConfiguration(data)
        .then(() => {
          setHasUnsavedChanges(false)
          setLastUserAction(null)
          if (isEdit) {
            toast.success('Ticket configuration saved successfully')
          }
        })
        .catch(() => {
          toast.error('Failed to save ticket configuration')
        })
    }

    if (!isEdit) {
      const nextTab = getNextTab()
      if (nextTab) {
        setSelectedTab(nextTab)
      } else {
        onResetClose()
      }
    }
  }

  const getCurrentForm = () => {
    switch (selectedTab) {
      case 'access_control':
        return {
          methods: accessControlMethods,
          component: accessControlMethods && (
            <AccessControl
              app={selectedApp}
              isLoading={isLoading || saveAccessControlResults.isLoading}
              onAddAccessControl={handleAddAccessControl}
              onRemoveAccessControl={handleRemoveAccessControl}
              updateAccessControl={updateAccessControl}
            />
          )
        }
      case 'provisioning':
        return {
          methods: provisionMethods,
          component: provisionMethods && (
            <Provisioning
              app={selectedApp}
              addStep={addProvisionStep}
              removeStep={removeProvisionStep}
              insertStep={insertProvisionStep}
              updateStep={updateProvisionStep}
              isLoading={isLoadingProvision || saveProvisioningResults.isLoading}
            />
          )
        }
      case 'ticketing':
        return {
          methods: ticketingMethods,
          component: ticketingMethods && <TicketCreation />
        }
      default:
        return {
          methods: null,
          component: null
        }
    }
  }

  const { methods, component } = getCurrentForm()

  const canNavigate = (userAction) => {
    if (methods.formState.isSubmitted) {
      setHasUnsavedChanges(false)
      setLastUserAction(null)
      return true
    }

    setLastUserAction(userAction)

    if (accessControlMethods && isAccessControlFormDirty()) {
      setHasUnsavedChanges(true)
      return false
    }
    if (ticketingMethods && isTicketConfigurationFormDirty()) {
      setHasUnsavedChanges(true)
      return false
    }
    if (provisionMethods && isProvisionFormDirty()) {
      setHasUnsavedChanges(true)
      return false
    }

    return true
  }

  const handleTabChange = (tabName) => {
    canNavigate({
      type: 'TAB_CHANGE',
      tab: tabName
    }) && setSelectedTab(tabName)
  }

  const onResetClose = () => {
    resetModalState()
    setSelectedTab('access_control')

    if (accessControlMethods) resetAccessControlForm()
    if (ticketingMethods) resetTicketingForm()
    if (provisionMethods) resetProvisionForm()
  }

  const handleModalClose = () => {
    if (!canNavigate({ type: 'MODEL_CLOSE' })) {
      return
    }
    onResetClose()
  }

  const onDiscardChanges = () => {
    setHasUnsavedChanges(false)
    setLastUserAction(null)

    if (lastUserAction && lastUserAction.type === 'TAB_CHANGE') {
      setSelectedTab(lastUserAction.tab)
    } else {
      onResetClose()
    }
  }

  return (
    <Modal
      onRequestClose={handleModalClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      isOpen={isModalOpen}
      onCancel={handleModalClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
    >
      <ModalBody
        style={{
          padding: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <form onSubmit={methods?.handleSubmit(onSave)} className={styles.formContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.appIconWrapper}>
              <img src={selectedApp?.source_meta?.logo} className={styles.icon} />
            </div>
            <div className={styles.modalTabContainer}>
              {Object.keys(SOFTWARE_CONFIGURATION_TABS).map((tabName) => (
                <div
                  key={tabName}
                  className={cx(
                    styles.modalTabItem,
                    selectedTab === tabName && styles.active,
                    !isEdit && selectedTab !== tabName && styles.disabled
                  )}
                  onClick={() => handleTabChange(tabName)}
                >
                  {SOFTWARE_CONFIGURATION_TABS[tabName]?.label}
                </div>
              ))}
            </div>
            <Button type='button' className={styles.closeButton} variant='secondary-outline' onClick={handleModalClose}>
              X
            </Button>
          </div>
          <div className={styles.contentContainer}>
            {component && (
              <div>
                {hasUnsavedChanges && (
                  <div className={styles.warningBanner}>
                    <div className={styles.warningContent}>
                      <div className={styles.warningMessage}>
                        You have unsaved changes in this form. Please save your changes before proceeding.
                      </div>
                      <div className={styles.warningActions}>
                        <Button
                          type='button'
                          variant='outline'
                          onClick={onDiscardChanges}
                          className={cx(styles.warningButton, styles.discard)}
                        >
                          Discard
                        </Button>
                        <Button type='submit' variant='solid' className={cx(styles.warningButton, styles.save)}>
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.contentTitle}>{SOFTWARE_CONFIGURATION_TABS[selectedTab]?.label}</div>
                <FormProvider {...methods}>{component}</FormProvider>
              </div>
            )}
          </div>
          <div className={styles.modalFooter}>
            <Button
              type='button'
              variant='outline'
              className={styles.secondaryButton}
              disabled={!_.isEmpty(methods?.formState.errors) || !getPreviousTab()}
              onClick={() => {
                const tabName = getPreviousTab()
                if (tabName) handleTabChange(tabName)
              }}
            >
              Back
            </Button>
            <Button type='submit' variant='solid' className={styles.primaryButton}>
              {isEdit ? 'Save' : getNextTab() ? 'Save & Proceed' : 'Save & Close'}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ConfigurationModal
