import baseApi, { TAGS } from './baseApi'

export const simpplrApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSimpplrApp: builder.query({
      query: () => ({
        url: '/v1/apps/simpplr',
        method: 'GET'
      }),
      providesTags: [TAGS.SIMPPLR]
    }),

    installSimpplr: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/simpplr/authorize',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SIMPPLR, TAGS.APPS, TAGS.ACCOUNT]
    }),

    uninstallSimpplr: builder.mutation({
      query: () => ({
        url: '/v1/apps/simpplr',
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.SIMPPLR, TAGS.APPS, TAGS.ACCOUNT]
    }),

    getSimpplrSites: builder.query({
      query: (params) => ({
        url: '/v1/apps/simpplr/sites/sync',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.SIMPPLR.SIMPPLR_SITES]
    }),

    addSimpplrSite: builder.mutation({
      query: (id) => ({
        url: '/v1/apps/simpplr/sites/sync',
        method: 'POST',
        body: {
          site_id: id
        }
      }),
      invalidatesTags: [TAGS.SIMPPLR.SIMPPLR_SITES]
    }),

    removeSimpplrSite: builder.mutation({
      query: (id) => ({
        url: `/v1/apps/simpplr/sites/sync/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.SIMPPLR.SIMPPLR_SITES]
    }),

    searchSimpplrSites: builder.query({
      query: (params) => ({
        url: `/v1/apps/simpplr/sites`,
        method: 'GET',
        params
      })
    }),

    enableSyncSimpplr: builder.mutation({
      query: () => ({
        url: '/v1/apps/simpplr/sync/enable',
        method: 'POST'
      }),
      invalidatesTags: [TAGS.SIMPPLR]
    })
  })
})

export const {
  useGetSimpplrAppQuery,
  useInstallSimpplrMutation,
  useUninstallSimpplrMutation,
  useGetSimpplrSitesQuery,
  useAddSimpplrSiteMutation,
  useRemoveSimpplrSiteMutation,
  useSearchSimpplrSitesQuery,
  useEnableSyncSimpplrMutation
} = simpplrApi
