import baseApi, { TAGS } from './baseApi'

export const outlineApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOutlineApp: builder.query({
      query: () => ({
        url: '/v1/apps/outline',
        method: 'GET'
      }),
      providesTags: [TAGS.OUTLINE]
    }),

    installOutline: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/outline',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.OUTLINE, TAGS.APPS, TAGS.ACCOUNT]
    }),

    uninstallOutline: builder.mutation({
      query: () => ({
        url: '/v1/apps/outline',
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.OUTLINE, TAGS.APPS, TAGS.ACCOUNT]
    }),

    getCollections: builder.query({
      query: (params) => ({
        url: '/v1/apps/outline/collections',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.OUTLINE]
    }),

    addCollections: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/outline/collections/sync',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.OUTLINE]
    }),

    getSyncedCollections: builder.query({
      query: (params) => ({
        url: '/v1/apps/outline/collections/sync',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.OUTLINE]
    }),

    removeSyncedCollections: builder.mutation({
      query: (id) => ({
        url: `/v1/apps/outline/collections/sync/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.OUTLINE]
    })
  })
})

export const {
  useGetOutlineAppQuery,
  useInstallOutlineMutation,
  useUninstallOutlineMutation,
  useGetCollectionsQuery,
  useAddCollectionsMutation,
  useGetSyncedCollectionsQuery,
  useRemoveSyncedCollectionsMutation
} = outlineApi
