import { Box } from '@happyfoxinc/web-components'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './TimeZone.module.css'

import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useUpdateUserTimeZoneMutation } from 'Src/servicesV3/settingsApi'
import { getGMTOffsetStringFromSeconds } from 'Utils/time'

const TimeZoneSkeleton = () => {
  return (
    <Box className={styles.container}>
      <div className={styles.subHeading}>Time Zone</div>
      <div className={styles.contentContainer}>
        <Box className={styles.descriptionColumn}>
          <div className={styles.skeletonText} />
          <div className={styles.skeletonParagraph}>
            <div className={styles.skeletonText} />
            <div className={styles.skeletonText} />
          </div>
        </Box>
        <Box className={styles.userInputColumn}>
          <div className={styles.skeletonSelect} />
        </Box>
      </div>
    </Box>
  )
}

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: '29px',
    height: '29px',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-sm)',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid var(--color-border-default)'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 4px',
    minHeight: '29px',
    height: '29px',
    fontSize: 'var(--text-sm)',
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0 8px'
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '0'
  }),
  menu: (base) => ({
    ...base,
    marginTop: '2px',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.06)',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-md)'
  }),
  menuList: (base) => ({
    ...base,
    padding: '4px'
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected ? '#4B7BF5' : isFocused ? '#F5F5F5' : 'transparent',
    color: isSelected ? 'var(--color-surface-default)' : 'var(--color-black)',
    fontSize: 'var(--text-sm)',
    padding: '6px 8px',
    borderRadius: 'var(--border-radius-sm)',
    cursor: 'pointer',
    ':active': {
      backgroundColor: '#4B7BF5'
    }
  }),
  input: (base) => ({
    ...base,
    caretColor: 'transparent',
    fontSize: 'var(--text-sm)',
    margin: 0,
    padding: 0
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 'var(--text-sm)'
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 'var(--text-sm)',
    color: 'var(--color-text-subtle)'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
}

const TimeZoneFormHandler = ({ timeZones, userTimeZone, closeModal }) => {
  const navigate = useNavigate()

  const [updateTimeZone, updateTimeZoneResult] = useUpdateUserTimeZoneMutation()

  const timeZoneOptions = useMemo(
    () =>
      timeZones.map(({ label, name, offset }) => {
        const formattedOffset = getGMTOffsetStringFromSeconds(offset)
        return {
          label: `${formattedOffset} - ${label}`,
          value: name
        }
      }),
    [timeZones]
  )

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      timezone: userTimeZone.name
    }
  })
  const { handleSubmit, watch, control } = formMethods

  const onFormSubmit = useCallback(
    ({ timezone }) => {
      const input = {
        timezone
      }
      const promise = updateTimeZone(input).unwrap()
      toast.promise(promise, {
        loading: 'Updating Time Zone preference',
        success: 'Successfully updated time zone preference',
        error: 'Unable to update time zone preference. Try again...'
      })
    },
    [updateTimeZone]
  )

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onFormSubmit)())
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, onFormSubmit])

  return (
    <div className={styles.contentContainer}>
      <Box className={styles.descriptionColumn}>
        <p>Set time zone</p>
        <p className={styles.muted}>
          Choose your time zone. This timezone is reflected in both the{' '}
          <a
            className={styles.link}
            onClick={() => {
              closeModal()
              navigate('/reports')
            }}
          >
            reports
          </a>{' '}
          and the weekly report posted on Teams.
        </p>
      </Box>
      <Box className={styles.userInputColumn}>
        <Controller
          name='timezone'
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              menuPortalTarget={document.body}
              placeholder='Select Time Zone'
              value={timeZoneOptions.find((opt) => opt.value === field.value)}
              onChange={(opt) => field.onChange(opt.value)}
              isDisabled={updateTimeZoneResult.isLoading}
              options={timeZoneOptions}
              styles={customStyles}
            />
          )}
        />
      </Box>
    </div>
  )
}

const TimeZone = ({ isLoading, timeZones, userTimeZone, closeModal }) => {
  if (isLoading || !timeZones.length || !userTimeZone.name) {
    return <TimeZoneSkeleton />
  }

  return (
    <Box className={styles.container}>
      <TimeZoneFormHandler timeZones={timeZones} userTimeZone={userTimeZone} closeModal={closeModal} />
    </Box>
  )
}

export default TimeZone
