import { Box, Flex } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './Answers.module.css'

import BackButton from 'Src/componentsv3/BackButton'
import { KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Src/constants/user-groups'
import { useAddAnswerMutation } from 'Src/servicesV3/answersApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import AnswerForm from './AnswerForm'
import answerFormValidationSchema from './AnswerForm/answer-form-validation-schema'

const AnswerCreate = () => {
  const navigate = useNavigate()
  const { currentWorkspaceId } = useWorkspace()
  const [addAnswer, addAnswerResult] = useAddAnswerMutation()

  const formMethods = useForm({
    defaultValues: {
      name: '',
      content: '',
      workspace_id: currentWorkspaceId,
      visibility: KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL,
      visibleToGroups: []
    },
    resolver: yupResolver(answerFormValidationSchema)
  })

  const { handleSubmit, reset } = formMethods

  const getPayloadFromFormData = (data) => {
    const payload = {
      name: data.name,
      content: data.content,
      workspace_id: data.workspace_id
    }

    if (data.visibility) {
      payload.visibility = data.visibility
    }

    if (data.visibleToGroups) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    return payload
  }

  const processAnswer = useCallback(
    async (data, addMore = false) => {
      const payload = getPayloadFromFormData(data)
      try {
        const promise = addAnswer(payload).unwrap()
        toast.promise(promise, {
          loading: 'Creating answer',
          success: 'Answer created successfully',
          error: parseErrorMessage('Unable to create answer. Try again...')
        })
        await promise
        if (addMore) {
          reset(undefined, { keepErrors: false })
        }
        navigate('../' + `${addMore ? 'create' : ''}`, { replace: true })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating answer:', error)
      }
    },
    [addAnswer, navigate, reset]
  )

  const onSubmit = useCallback(
    (e) => {
      handleSubmit((data) => processAnswer(data))(e)
    },
    [handleSubmit, processAnswer]
  )

  const onSaveAndAdd = useCallback(
    (e) => {
      handleSubmit((data) => processAnswer(data, true))(e)
    },
    [handleSubmit, processAnswer]
  )

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Create Answer</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <AnswerForm onSubmit={onSubmit} onSaveAndAdd={onSaveAndAdd} isLoading={addAnswerResult.isLoading} />
        </FormProvider>
      </Box>
    </Box>
  )
}

export default AnswerCreate
