import { Flex } from '@happyfoxinc/web-components'
import { memo } from 'react'

import styles from './Components.module.css'

import TrashIcon from 'Src/assetsv3/icons/trash.svg'
import StepFormField from 'Src/pagesv3/Modules/SoftwareAccessModule/Provisioning/components/StepFormField'

const StepForm = memo(({ app, step, index, onDelete }) => {
  return (
    <div className={styles.stepContent}>
      <Flex justify='space-between' align='center' className={styles.stepHeader}>
        <Flex align='flex-end' wrap='wrap' gap='3px'>
          <h3 className={styles.stepTitle}>{step.label}</h3>
        </Flex>
        <Flex align='center' gap='8px'>
          {step.isEdit && (
            <button
              type='button'
              className={styles.iconButton}
              onClick={() => {
                onDelete(index)
              }}
            >
              <TrashIcon />
            </button>
          )}
        </Flex>
      </Flex>

      {step.isEdit &&
        step.fields?.map((field, fieldIndex) => (
          <StepFormField key={`${index}-${field.name}`} field={field} fieldIndex={fieldIndex} index={index} />
        ))}
    </div>
  )
})

StepForm.displayName = 'StepForm'
export default StepForm
