import { Box } from '@happyfoxinc/web-components'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './Modules.module.css'

import { useGetModulesQuery } from 'Src/servicesV3/modulesApi'

import { MODULES } from './constants'
import ModuleCard from './ModuleCard/ModuleCard'

const ModulesHeader = () => (
  <div className='heading-container'>
    <h1 className='heading'>Modules</h1>
    <p className='sub-heading'>
      Choose from our predefined modules to quickly get started based on your specific use case. Once selected, you can
      configure and customize each module to suit your requirements, making it easy to set up and adapt the tool to your
      workflow.
    </p>
  </div>
)

const ModulesSkeleton = () => (
  <div className={styles.grid} role='list'>
    {Object.entries(MODULES).map((_, index) => (
      <ModuleCard key={`skeleton-${index}`} isLoading />
    ))}
  </div>
)

const ModulesSection = () => {
  const navigate = useNavigate()
  const { data: modulesResponse = [], isLoading, error } = useGetModulesQuery()

  const modules = useMemo(
    () =>
      modulesResponse.reduce((acc, module) => {
        const moduleConfig = MODULES[module.name]
        return moduleConfig ? [...acc, { ...module, ...moduleConfig }] : acc
      }, []),
    [modulesResponse]
  )

  return (
    <Box className='page-content'>
      <div className={styles.wrapper}>
        <ModulesHeader />
        {(isLoading || error) && <ModulesSkeleton />}
        {!isLoading && !error && (
          <div className={styles.grid} role='list'>
            {modules.map((module) => (
              <ModuleCard key={module.name} {...module} onClick={() => navigate(module.path)} />
            ))}
          </div>
        )}
      </div>
    </Box>
  )
}

export default ModulesSection
