import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

export const useStepForm = (index, fieldIndex) => {
  const {
    control,
    formState: { errors, dirtyFields, isSubmitted }
  } = useFormContext()

  const getFieldError = useCallback(() => {
    return errors?.steps?.[index]?.fields?.[fieldIndex]
  }, [errors, index, fieldIndex])

  const isFieldDirty = useCallback(
    (fieldName) => {
      return isSubmitted || dirtyFields?.[`steps.${index}.fields.${fieldIndex}.${fieldName}`]
    },
    [isSubmitted, dirtyFields, index, fieldIndex]
  )

  return {
    control,
    getFieldError,
    isFieldDirty
  }
}
