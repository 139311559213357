import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetAppsQuery } from 'Src/servicesV3/appsApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import APPS_CONFIG from '../constants/appsConfig'
import { APP_CATEGORY } from '../constants/appTypes'

export const useAppsData = () => {
  const { currentWorkspaceId } = useWorkspace()
  const { category = APP_CATEGORY.ALL } = useParams()

  const {
    data: appsApiData = [],
    isLoading,
    error
  } = useGetAppsQuery({
    workspace_id: currentWorkspaceId
  })

  const mergedApps = useMemo(() => {
    if (!appsApiData) return []

    return appsApiData
      .map((apiApp) => {
        const staticData = APPS_CONFIG[apiApp.key]
        if (!staticData) return null

        return {
          ...staticData,
          key: apiApp.key,
          status: apiApp.status
        }
      })
      .filter(Boolean)
      .filter((app) => {
        if (category === APP_CATEGORY.ALL) return true

        return Array.isArray(app.category) ? app.category.includes(category) : app.category === category
      })
      .sort((a, b) => a.status.localeCompare(b.status))
  }, [appsApiData, category])

  const getAppById = useMemo(() => {
    return (id) => {
      const apiApp = appsApiData.find((app) => APPS_CONFIG[app.key]?.id === id)
      if (!apiApp) return null

      const staticData = APPS_CONFIG[apiApp.key]
      if (!staticData) return null

      return {
        ...staticData,
        key: apiApp.key,
        status: apiApp.status
      }
    }
  }, [appsApiData])

  return {
    apps: mergedApps,
    isLoading,
    error,
    getAppById
  }
}
