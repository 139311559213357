// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EglUGss54ngzuMHEY3ht{position:relative}.zMGrsU8CWJCfGlUaFzdj{position:absolute;top:-28px;right:0}.UC65kD9qbQSo6WnAjwxD{width:100%;background-color:var(--color-surface-default);border:1px solid var(--color-border-default);transition:border-color .15s ease;color:var(--color-text-default);padding:12px;border-radius:var(--border-radius-sm)}", "",{"version":3,"sources":["webpack://./../src/componentsv3/MergeInput/MergeInput.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,OAAA,CAGF,sBACE,UAAA,CACA,6CAAA,CACA,4CAAA,CACA,iCAAA,CACA,+BAAA,CACA,YAAA,CACA,qCAAA","sourcesContent":[".FieldContainer {\n  position: relative;\n}\n\n.MergeFieldContainer {\n  position: absolute;\n  top: -28px;\n  right: 0;\n}\n\n.textarea {\n  width: 100%;\n  background-color: var(--color-surface-default);\n  border: 1px solid var(--color-border-default);\n  transition: border-color 0.15s ease;\n  color: var(--color-text-default);\n  padding: 12px;\n  border-radius: var(--border-radius-sm);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FieldContainer": "EglUGss54ngzuMHEY3ht",
	"MergeFieldContainer": "zMGrsU8CWJCfGlUaFzdj",
	"textarea": "UC65kD9qbQSo6WnAjwxD"
};
export default ___CSS_LOADER_EXPORT___;
