import cx from 'classnames'

import styles from './MetricCards.module.css'

import ContentOutline from '../ContentOutline'
import Tile from '../Tile'
import MetricTable from './MetricTable'

export const MetricTileTaller = ({
  title,
  info,
  icon,
  primaryCount,
  othersCount,
  totalCount,
  othersDisabled,
  othersNotApplicable,
  totalWithoutFeedback,
  classNames
}) => (
  <div className={styles.metricTileTaller}>
    <Tile title={title} info={info} icon={icon} className={cx(classNames.tile)}>
      <ContentOutline title='Resolved with feedback'>
        <MetricTable
          primaryCount={primaryCount}
          othersCount={othersCount}
          totalCount={totalCount}
          othersDisabled={othersDisabled}
          othersNotApplicable={othersNotApplicable}
        />
      </ContentOutline>
      <ContentOutline title='Resolved without feedback'>
        <div className={cx(styles.metricRow, styles.totalRow)}>
          <div className={styles.rowLabel}>Total</div>
          <div className={styles.rowValue}>{totalWithoutFeedback}</div>
        </div>
      </ContentOutline>
    </Tile>
  </div>
)

export default MetricTileTaller
