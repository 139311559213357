// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mM5Uy6dX24D6tR1RYgbr{position:relative}.BT2JEHSQJb4KdVJfXbMw{position:absolute;top:-28px;right:0}", "",{"version":3,"sources":["webpack://./../src/componentsv3/MergeTrixEditor/MergeTrixEditor.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,OAAA","sourcesContent":[".FieldContainer {\n  position: relative;\n}\n\n.MergeFieldContainer {\n  position: absolute;\n  top: -28px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FieldContainer": "mM5Uy6dX24D6tR1RYgbr",
	"MergeFieldContainer": "BT2JEHSQJb4KdVJfXbMw"
};
export default ___CSS_LOADER_EXPORT___;
