import { Box, Dropdown, Flex } from '@happyfoxinc/web-components'
import cx from 'classnames'
import _ from 'lodash'
import { Fragment } from 'react'
import toast from 'react-hot-toast'

import styles from './SoftwareList.module.css'

import CrossIcon from 'Src/assetsv3/icons/cross.svg'
import TickIcon from 'Src/assetsv3/icons/tick.svg'
import ConfigurationModal from 'Src/pagesv3/Modules/SoftwareAccessModule/ConfigModal'
import {
  useGetSoftwaresQuery,
  useStartAppConfigurationMutation,
  useUpdateSoftwareMutation
} from 'Src/servicesV3/softwareAccessModuleApi'

import { SoftwareProvider, useSoftwareContext } from './SoftwareContext'

const ConfiguredApps = ({ apps = [], isLoading = false }) => {
  const { setSelectedApp, setCatalogItemId, setIsModalOpen, setSelectedFeature } = useSoftwareContext()

  const [updateSoftware] = useUpdateSoftwareMutation()

  if (isLoading) {
    return (
      <div className={styles.appCardsContainer}>
        <div className={styles.appCardsGrid}>
          {Array(3)
            .fill(0)
            .map((_value, index) => (
              <div key={index} className={styles.appCard}>
                <div className={styles.appCardHeader}>
                  <div className={styles.skeletonContainer} />
                  <div className={styles.appInfo}>
                    <div className={styles.skeletonAppName} />
                    <div className={styles.skeletonAppProvider} />
                  </div>
                </div>
                <div className={styles.appCardFooter}>
                  <div className={styles.appFeatures}>
                    {Array(3)
                      .fill(0)
                      .map((_value, index) => (
                        <span key={index} className={styles.skeletonAppName} />
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  const formatText = (text) => {
    return text
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  const onCardSelect = (app) => {
    if (app.status === 'disabled') return

    setSelectedApp(app)
    setIsModalOpen(true)
    setCatalogItemId(app.id)
  }

  const handleDisableApp = (e, app) => {
    e.stopPropagation()
    const promise = updateSoftware({ catalogItemId: app.id, is_enabled: false }).unwrap()
    promise
      .then(() => {
        toast.success('Software has been disabled successfully')
      })
      .catch(() => {
        toast.error('Failed to disable the software. Please try again later.')
      })
  }

  const handleEnableApp = (e, app) => {
    e.stopPropagation()
    const promise = updateSoftware({ catalogItemId: app.id, is_enabled: true }).unwrap()
    promise
      .then(() => {
        toast.success('Software has been enabled successfully')
      })
      .catch(() => {
        toast.error('Failed to enable the software. Please try again later.')
      })
  }

  return (
    <div className={styles.appCardsContainer}>
      {apps && apps.length > 0 && (
        <div className={styles.appCardsGrid}>
          {apps.map((app) => (
            <div
              key={app.id}
              className={cx(styles.appCard, app.status === 'disabled' && styles.disabled)}
              onClick={() => onCardSelect(app)}
            >
              {app.status === 'disabled' && <span className={styles.disabledBadge}>Disabled</span>}
              <div className={styles.appCardHeader}>
                <div className={styles.appLogoContainer}>
                  <img src={app.source_meta.logo} className={styles.appLogo} />
                </div>
                <div className={styles.appInfo}>
                  <div className={styles.appName}>{_.startCase(app.name)}</div>
                  <div className={styles.appProvider}>{_.startCase(app.source_meta?.type)}</div>
                </div>
              </div>
              <div className={styles.appCardFooter}>
                <div className={cx(styles.appFeatures, app.status === 'disabled' && styles.cursorNotAllowed)}>
                  {Object.keys(app.configuration)
                    .sort()
                    .map((feature, index) => (
                      <div
                        key={index}
                        className={styles.featureContainer}
                        onClick={(e) => {
                          setSelectedApp(app)
                          setIsModalOpen(true)
                          setCatalogItemId(app.id)
                          setSelectedFeature(feature)
                          e.stopPropagation()
                        }}
                      >
                        <span className={styles.featureLabel}>{formatText(feature)}</span>
                        {app.configuration[feature] ? (
                          <TickIcon className={cx(styles.icon, styles.tickIcon)} />
                        ) : (
                          <CrossIcon className={cx(styles.icon, styles.crossIcon)} />
                        )}
                      </div>
                    ))}
                </div>
                {app && (app.status === 'active' || app.status === 'disabled') && (
                  <div variant='primary' className={styles.options}>
                    <Dropdown.Root>
                      <Dropdown.Trigger asChild aria-label='Options' onClick={(e) => e.stopPropagation()}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 18 18'>
                          <path
                            transform='translate(7.5 2.25)'
                            d='M1.5 0C0.67500001 0 0 0.67500001 0 1.5C0 2.325 0.67500001 3 1.5 3C2.325 3 3 2.325 3 1.5C3 0.67500001 2.325 0 1.5 0ZM1.5 10.5C0.67500001 10.5 0 11.175 0 12C0 12.825 0.67500001 13.5 1.5 13.5C2.325 13.5 3 12.825 3 12C3 11.175 2.325 10.5 1.5 10.5ZM1.5 5.25C0.67500001 5.25 0 5.9250002 0 6.75C0 7.5749998 0.67500001 8.25 1.5 8.25C2.325 8.25 3 7.5749998 3 6.75C3 5.9250002 2.325 5.25 1.5 5.25Z'
                          />
                        </svg>
                      </Dropdown.Trigger>
                      <Dropdown.Content className={styles.dropdownMenu}>
                        {app.status === 'active' && (
                          <Dropdown.Item className={styles.dropdownItem} onClick={(e) => handleDisableApp(e, app)}>
                            Disable
                          </Dropdown.Item>
                        )}
                        {app.status === 'disabled' && (
                          <Dropdown.Item className={styles.dropdownItem} onClick={(e) => handleEnableApp(e, app)}>
                            Enable
                          </Dropdown.Item>
                        )}
                      </Dropdown.Content>
                    </Dropdown.Root>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <ConfigurationModal />
    </div>
  )
}

const OtherApps = ({ apps, isLoading = false }) => {
  const { selectedApp, setSelectedApp, setCatalogItemId, setIsModalOpen } = useSoftwareContext()

  const [startConfig] = useStartAppConfigurationMutation()

  if (isLoading) {
    return (
      <div className={styles.appCardsContainer}>
        <div className={styles.appCardsGrid}>
          {Array(3)
            .fill(0)
            .map((_value, index) => (
              <div key={index} className={styles.appCard}>
                <div className={styles.appCardHeader}>
                  <div className={styles.skeletonContainer} />
                  <div className={styles.appInfo}>
                    <div className={styles.skeletonAppName} />
                    <div className={styles.skeletonAppProvider} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  const handleAppConfig = async (app) => {
    setSelectedApp(app)
    const promise = startConfig({ name: app.name, source_meta: app.source_meta }).unwrap()
    await promise
      .then((response) => {
        setIsModalOpen(true)
        setCatalogItemId(response.id)
      })
      .catch(() => {
        setIsModalOpen(false)
        setSelectedApp(null)
        toast.error('Failed to process the request. Please try again later.')
      })
  }

  return (
    <div className={styles.appCardsContainer}>
      {apps && apps.length > 0 && (
        <div className={styles.appCardsGrid}>
          {apps.map((app) => (
            <div
              key={app.source_meta.id}
              className={cx(
                styles.appCard,
                selectedApp && selectedApp.source_meta.id !== app.source_meta.id && styles.appDisabled
              )}
              onClick={() => handleAppConfig(app)}
            >
              <div className={styles.appCardHeader}>
                <div className={styles.appLogoContainer}>
                  <img src={app.source_meta.logo} className={styles.appLogo} />
                </div>
                <div className={styles.appInfo}>
                  <div className={styles.appName}>{_.startCase(app.name)}</div>
                  <div className={styles.appProvider}>{_.startCase(app.source_meta?.type)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <ConfigurationModal />
    </div>
  )
}

const SoftwareList = () => {
  const {
    data = {},
    isLoading,
    error
  } = useGetSoftwaresQuery(
    {},
    {
      refetchOnMountOrArgChange: true
    }
  )

  const { configured_apps = [], other_apps = [] } = data

  return (
    <Fragment>
      <SoftwareProvider>
        <Flex direction='column' className={styles.softwareListContainer} gap='20px'>
          {configured_apps.length > 0 && (
            <Box className={styles.configuredAppsContainer}>
              <Flex align='center' gap='5px'>
                <span className={styles.sectionHeader}>Configured Softwares ({configured_apps.length})</span>
                <span className={styles.sectionDivider} />
              </Flex>
              {isLoading || error ? (
                <ConfiguredApps isLoading={isLoading || error} />
              ) : (
                <ConfiguredApps apps={configured_apps} />
              )}
            </Box>
          )}
          <Box className={styles.otherAppsContainer}>
            <Flex align='center' gap='5px'>
              {configured_apps.length > 0 && (
                <div className={styles.sectionHeader}>Other Softwares ({other_apps.length})</div>
              )}
              {!isLoading && configured_apps.length === 0 && (
                <div className={styles.sectionHeader}>Select a software to configure</div>
              )}
              <span className={styles.sectionDivider} />
            </Flex>
            {isLoading || error ? <OtherApps isLoading={isLoading || error} /> : <OtherApps apps={other_apps} />}
          </Box>
        </Flex>
      </SoftwareProvider>
    </Fragment>
  )
}

export default SoftwareList
