import { providesList } from 'Src/utils/rtk-query'

import baseApi, { TAGS } from './baseApi'

export const responseGroupApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getResponseGroup: builder.query({
      query: ({ appName, params }) => ({
        url: `/v1/apps/${appName}/response-groups`,
        method: 'GET',
        params
      }),
      providesTags: (response) => providesList(response?.results, TAGS.RESPONSE_GROUP.RESPONSE_GROUPS)
    }),

    getResponseGroups: builder.query({
      query: ({ appName, responseGroupId, params }) => ({
        url: `/v1/apps/${appName}/response-groups/${responseGroupId}`,
        method: 'GET',
        params
      }),
      providesTags: (_result, _error, id) => [{ type: 'ResponseGroupItem', id }, TAGS.RESPONSE_GROUP.RESPONSE_GROUPS]
    }),

    getResponseGroupChildren: builder.query({
      query: ({ appName, responseGroupId, params }) => ({
        url: `/v1/apps/${appName}/response-groups/${responseGroupId}/children`,
        method: 'GET',
        params
      }),
      providesTags: (response) => providesList(response?.results, TAGS.RESPONSE_GROUP.RESPONSE_GROUPS_CHILDREN)
    }),

    updateResponseGroup: builder.mutation({
      query: ({ appName, responseGroupId, ...data }) => ({
        url: `/v1/apps/${appName}/response-groups/${responseGroupId}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: (_result, _error, data) => [
        { type: TAGS.RESPONSE_GROUP.RESPONSE_GROUP_ITEMS, id: data.id },
        { type: TAGS.RESPONSE_GROUP.RESPONSE_GROUPS, id: data.id },
        { type: TAGS.RESPONSE_GROUP.RESPONSE_GROUPS_CHILDREN, id: data.id },
        TAGS.RESPONSE_GROUP.RESPONSE_GROUPS_CHILDREN,
        TAGS.RESPONSE_GROUP.RESPONSE_GROUP_ITEMS
      ]
    }),

    checkResponseGroupVisibilityUpdate: builder.query({
      query: ({ appName, responseGroupId, ...params }) => ({
        url: `/v1/apps/${appName}/response-groups/${responseGroupId}/check-visibility-update`,
        method: 'GET',
        params
      })
    })
  })
})

export const {
  useGetResponseGroupQuery,
  useGetResponseGroupsQuery,
  useGetResponseGroupChildrenQuery,
  useUpdateResponseGroupMutation,
  useCheckResponseGroupVisibilityUpdateQuery
} = responseGroupApi
