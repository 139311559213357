import { Flex, Grid } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const TableSkeleton = ({ columns = 1, rows = 5 }) => (
  <div>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />

    <Flex justify='flex-end' className={styles.mb16}>
      <div className={styles.paginationContainer}>
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w80}`} />
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
      </div>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={styles.headerBg}>
        <Grid columns={`repeat(${columns}, 1fr)`}>
          {[...Array(columns)].map((_, i) => (
            <div key={i} className={`${styles.shimmerBg} ${styles.h16} ${styles.w120}`} />
          ))}
        </Grid>
      </div>

      <div>
        {[...Array(rows)].map((_, rowIndex) => (
          <div key={rowIndex} className={styles.tableRow}>
            <Grid columns={`repeat(${columns}, 1fr)`}>
              {[...Array(columns)].map((_, colIndex) => (
                <div key={colIndex} className={`${styles.shimmerBg} ${styles.h16} ${styles.w90p}`} />
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export const NotionAccountsSkeleton = () => <TableSkeleton columns={3} rows={5} />

export const NotionPagesSkeleton = () => <TableSkeleton columns={1} rows={5} />

export const SyncNotionPagesSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />

    <div className={`${styles.shimmerBg} ${styles.h36} ${styles.mb24}`} />

    <div className={styles.treeContainer}>
      <div className={styles.treeRoot}>
        <div className={`${styles.shimmerBg} ${styles.h20} ${styles.w200} ${styles.mb12}`} />
        <div className={styles.treeChildren}>
          {[...Array(4)].map((_, i) => (
            <div key={i} className={`${styles.treeItem} ${styles.mb8}`}>
              <Flex gap='8px' align='center'>
                <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} />
                <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w180}`} />
              </Flex>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className={styles.mt24}>
      <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
    </div>
  </div>
)

const NotionSkeleton = () => {
  return (
    <Flex gap='15px' direction='column' width='100%'>
      <div className={styles.cardSkeleton}>
        <Flex gap='12px' align='center'>
          <div className={styles.iconSkeleton} />
          <div>
            <div className={`${styles.shimmerBg} ${styles.h20} ${styles.w180} ${styles.mb8}`} />
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w240} ${styles.opacity70}`} />
          </div>
        </Flex>
      </div>
      <NotionAccountsSkeleton />
      <NotionPagesSkeleton />
      <SyncNotionPagesSkeleton />
      <div className={styles.footerSkeleton}>
        <div className={`${styles.shimmerBg} ${styles.h36}`} />
      </div>
    </Flex>
  )
}

export default NotionSkeleton
