import styles from './ContentList.module.css'

import ReadMore from 'Src/componentsv3/ReadMore'

const ContentList = ({ list }) => {
  return (
    <ul className={styles.listContainer}>
      {list?.map((item, i) => (
        <li key={item + i} className={styles.listItem}>
          <ReadMore content={item} />
        </li>
      ))}
    </ul>
  )
}

export default ContentList
