// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JFaf8qkFErCI8AVa_A00{width:100%}.imwcdxebPdgkWx05LTLq{font-size:var(--text-md);color:var(--color-text-default);font-weight:var(--font-weight-normal);margin:6px 0}.CWBm4AOTwK3okr3xSnaN{padding:16px;color:var(--color-text-muted);font-size:var(--text-md);text-align:center}._z2L0mMM9whxHWx6GYzP{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/GitBook/GitBook.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,wBAAA,CACA,+BAAA,CACA,qCAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.heading {\n  font-size: var(--text-md);\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-normal);\n  margin: 6px 0;\n}\n\n.emptyState {\n  padding: 16px;\n  color: var(--color-text-muted);\n  font-size: var(--text-md);\n  text-align: center;\n}\n\n.tableActionContainer {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "JFaf8qkFErCI8AVa_A00",
	"heading": "imwcdxebPdgkWx05LTLq",
	"emptyState": "CWBm4AOTwK3okr3xSnaN",
	"tableActionContainer": "_z2L0mMM9whxHWx6GYzP"
};
export default ___CSS_LOADER_EXPORT___;
