// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kGa7FnimMZ4BXh6u8BVZ{margin-bottom:20px;width:75%}.dyupdeTa2Tqk3LLalegS{display:grid;grid-template-columns:repeat(auto-fill, minmax(260px, 1fr));gap:1rem;padding:24px}.ENC0dpioox7bwJ0bxn6K{display:flex;gap:20px;flex:1}.jh5MDjCmLLdZtqRFuz4y{width:200px;flex-shrink:0}.dBziXjQih8ZaGdcKGNVp{flex:1}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppsList/AppsList.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,SAAA,CAGF,sBACE,YAAA,CACA,2DAAA,CACA,QAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,MAAA,CAGF,sBACE,WAAA,CACA,aAAA,CAGF,sBACE,MAAA","sourcesContent":[".header {\n  margin-bottom: 20px;\n  width: 75%;\n}\n\n.appListGrid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));\n  gap: 1rem;\n  padding: 24px;\n}\n\n.content {\n  display: flex;\n  gap: 20px;\n  flex: 1;\n}\n\n.sidebar {\n  width: 200px;\n  flex-shrink: 0;\n}\n\n.main {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "kGa7FnimMZ4BXh6u8BVZ",
	"appListGrid": "dyupdeTa2Tqk3LLalegS",
	"content": "ENC0dpioox7bwJ0bxn6K",
	"sidebar": "jh5MDjCmLLdZtqRFuz4y",
	"main": "dBziXjQih8ZaGdcKGNVp"
};
export default ___CSS_LOADER_EXPORT___;
