import { Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table/dist/react-table.development'

import styles from './AiActions.module.css'

import { useUpdateAppsAIActionsMutation } from 'Src/servicesV3/aiActionsApi'
import parseErrorMessage from 'Src/utils/error-message-parser'

import { useAppDetailContext } from '../../hooks/AppDetailContext'
import AiActionConfigureModal from './AiActionConfigureModal'

const AiActionsTable = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState(null)
  const [isDefaultEnabled, setIsDefaultEnabled] = useState(false)
  const { title: appTitle, id: appName } = useAppDetailContext()

  const [updateAIAction] = useUpdateAppsAIActionsMutation()
  const { results: aiActions = [] } = data

  const handleEnabledStatusToggle = useCallback(
    ({ status, id }) => {
      const newStatus = !status
      const payload = {
        is_enabled: newStatus
      }

      const promise = updateAIAction({ appName, id, payload }).unwrap()

      toast.promise(promise, {
        loading: `${newStatus ? 'Enabling' : 'Disabling'} AI actions for ${appTitle}...`,
        success: `AI actions for ${appTitle} ${newStatus ? 'enabled' : 'disabled'} successfully`,
        error: parseErrorMessage(
          `Unable to ${newStatus ? 'enable' : 'disable'} AI actions for ${appTitle}. Try again...`
        )
      })
    },
    [appName, appTitle, updateAIAction]
  )

  const onStatusSwitchToggle = useCallback(
    (data) => {
      const { id, is_enabled } = data
      if (is_enabled) {
        handleEnabledStatusToggle({ status: is_enabled, id })
      } else {
        setIsDefaultEnabled(true)
        setSelectedAction(data)
        setIsModalOpen(true)
      }
    },
    [handleEnabledStatusToggle]
  )

  const onCloseModal = () => {
    setIsModalOpen(false)
    setSelectedAction(null)
    setIsDefaultEnabled(false)
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Action Name',
        accessor: 'name'
      },
      {
        Header: 'Approval Required',
        accessor: 'settings.is_approval_needed',
        Cell: ({ value }) => {
          return value === true ? 'Yes' : value === false ? 'No' : '-'
        }
      },
      {
        Header: 'Ticket Creation',
        accessor: 'settings.is_ticketing_enabled',
        Cell: ({ value }) => {
          return value === true ? 'Yes' : value === false ? 'No' : '-'
        }
      },
      {
        Header: 'Status',
        accessor: 'is_enabled',
        Cell: ({ value, row }) => {
          return (
            <label className={styles.switch} onClick={(event) => event.stopPropagation()}>
              <input type='checkbox' checked={value} onChange={() => onStatusSwitchToggle(row.original)} />
              <span className={styles.slider} />
            </label>
          )
        }
      }
    ]
  }, [onStatusSwitchToggle])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: aiActions
  })

  return (
    <div>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell key={key} {...restCellProps}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                <TableOptions>
                  <TableOption onClick={() => onStatusSwitchToggle(row.original)}>Configure</TableOption>
                </TableOptions>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
      <AiActionConfigureModal
        data={selectedAction}
        isOpen={isModalOpen}
        onClose={onCloseModal}
        isDefaultEnabled={isDefaultEnabled}
      />
    </div>
  )
}

export default AiActionsTable
