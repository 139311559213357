// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DTYyCDl5O1DKlTTBlTbB{font-size:var(--text-h1);color:var(--color-text-default);font-weight:var(--font-weight-normal)}.RIhPSlx9iUcH58WqF7wx{padding:7px}.g70U1g7zROhDQYY3YNFS{margin-top:20px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/AIAssistant/AIAssistant.module.css"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,+BAAA,CACA,qCAAA,CAGF,sBACE,WAAA,CAGF,sBACE,eAAA","sourcesContent":[".heading {\n  font-size: var(--text-h1);\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-normal);\n}\n\n.addButton {\n  padding: 7px;\n}\n\n.listContainer {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "DTYyCDl5O1DKlTTBlTbB",
	"addButton": "RIhPSlx9iUcH58WqF7wx",
	"listContainer": "g70U1g7zROhDQYY3YNFS"
};
export default ___CSS_LOADER_EXPORT___;
