import { Box, Button, Dropdown, Flex } from '@happyfoxinc/web-components'
import { useState } from 'react'

import styles from './InsertMergeFields.module.scss'

import CloseIcon from 'Icons/close.svg'

import Badge from '../Badge'

const BadgeContainer = ({ fields, selectedFields, onClick }) => {
  return (
    <Flex wrap='wrap' gap='6px' className={styles.badgeContainer}>
      {fields.map((field) => {
        const isSelected = selectedFields.includes(field)
        return (
          <Badge
            key={field}
            text={field}
            isSelected={isSelected}
            variant={isSelected ? 'green' : 'brown'}
            onClick={() => onClick(field)}
          />
        )
      })}
    </Flex>
  )
}

const Content = ({ onAdd, fields, onClose }) => {
  const [selectedFields, setSelectedFields] = useState([])

  const handleClick = (field) => {
    setSelectedFields((prev) => {
      if (prev.includes(field)) {
        return prev.filter((item) => item !== field)
      }
      return [...prev, field]
    })
  }

  const handleAdd = () => {
    onAdd(selectedFields)
    onClose()
  }

  return (
    <Box className={styles.container}>
      <Flex className={styles.headingContainer} justify='between' align='center'>
        <div className='heading bold m-0'>Merge Fields</div>
        <CloseIcon className={styles.closeIcon} onClick={onClose} width='16' height='16' />
      </Flex>
      <div className={styles.divider} />
      <Box className={styles.body}>
        <BadgeContainer fields={fields} selectedFields={selectedFields} onClick={handleClick} />
        <Flex className={styles.buttonContainer}>
          <Button
            className={styles.button}
            disabled={!selectedFields.length}
            onClick={handleAdd}
            size='xs'
            variant='solid'
          >
            Add
          </Button>
          <Button className={styles.button} onClick={onClose} size='xs' variant='ghost'>
            Cancel
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}

const InsertMergeFields = ({ fields, onAdd }) => {
  const [open, setOpen] = useState(false)

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger asChild>
        <a role='button' className={styles.link}>
          Insert Merge Field
        </a>
      </Dropdown.Trigger>

      <Dropdown.Content
        className={styles.content}
        sideOffset={2}
        align='end'
        onEscapeKeyDown={() => setOpen(false)}
        onInteractOutside={() => setOpen(false)}
      >
        <Content fields={fields} onAdd={onAdd} onClose={() => setOpen(false)} />
      </Dropdown.Content>
    </Dropdown.Root>
  )
}

export default InsertMergeFields
