import { Box, Flex } from '@happyfoxinc/web-components'
import { Fragment } from 'react'

import DOMPurify from 'dompurify'

import styles from './Components.module.css'

import EditIcon from 'Src/assetsv3/icons/edit.svg'
import TickIcon from 'Src/assetsv3/icons/tick.svg'

const ReadOnlyFields = ({ step, stepLabel, index, updateStep }) => {
  if (!step.fields) {
    return null
  }

  return (
    <Fragment>
      {step.fields.map((field, fieldIndex) => {
        if (field.name === 'recipients') {
          const recipients = field.recipients?.map((value) => value.toString())
          const recipientNames = recipients
            ? field.values.map((item) => (recipients.includes(item.id.toString()) ? item.name : '')).filter(Boolean)
            : []

          return (
            <Flex
              align='flex-start'
              justify='space-between'
              wrap='wrap'
              gap='3px'
              key={`${field.name}-${index}-${fieldIndex}`}
              style={{ flex: '1' }}
            >
              <Flex align='flex-end' wrap='wrap' gap='3px'>
                <h3 className={styles.stepTitle}>
                  {stepLabel}
                  <span className={styles.stepType}>
                    &nbsp;to&nbsp;
                    {recipientNames.map((name, i) => (
                      <span key={`${field.name}-${i}-${name}`}>
                        @{name}
                        {i !== recipientNames.length - 1 && ', '}
                      </span>
                    ))}
                  </span>
                </h3>
              </Flex>
              <button
                type='button'
                className={styles.editButton}
                onClick={() => {
                  updateStep(index, { ...step, isEdit: true })
                }}
              >
                <EditIcon />
              </button>
            </Flex>
          )
        }

        if (field.name === 'instruction') {
          return (
            <Box key={`${field.name}-${index}-${fieldIndex}`}>
              <p
                className={styles.stepType}
                style={{
                  margin: '4px 0',
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: '4'
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(field.instruction.replace(/(?:\r\n|\r|\n)/g, '<br>'))
                }}
              />
            </Box>
          )
        }

        if (field.name === 'user_acknowledgement' && field.user_acknowledgement) {
          return (
            <Box width='90%' key={`${field.name}-${index}-${fieldIndex}`}>
              <p className={styles.acknowledgement}>
                <span className={styles.tickIconWrapper}>
                  <TickIcon />
                </span>
                User Acknowledgement Required
              </p>
            </Box>
          )
        }

        if (field.name === 'approvers') {
          const approvers = field?.approvers?.map((value) => value.toString()) || []
          const approverNames = field.values
            .map((item) => (approvers.includes(item.id.toString()) ? item.name : ''))
            .filter(Boolean)

          return (
            <Fragment key={`${field.name}-${index}-${fieldIndex}`}>
              <Flex align='flex-start' justify='space-between' wrap='wrap' gap='3px'>
                <Flex align='flex-end' wrap='wrap' gap='3px'>
                  <h3 className={styles.stepTitle}>{stepLabel}</h3>
                </Flex>
                <button
                  type='button'
                  className={styles.editButton}
                  onClick={() => {
                    updateStep(index, { ...step, isEdit: true })
                  }}
                >
                  <EditIcon />
                </button>
              </Flex>
              <span className={styles.stepType}>
                Approval required by&nbsp;
                {approverNames.map((name, i) => (
                  <span key={`${field.name}-${i}-${name}`}>
                    @{name}
                    {i !== approverNames.length - 1 && ', '}
                  </span>
                ))}
              </span>
            </Fragment>
          )
        }

        return null
      })}
    </Fragment>
  )
}

export default ReadOnlyFields
