import { Flex } from '@happyfoxinc/web-components'
import React from 'react'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const AppInstalledCardSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <Flex gap='12px' align='center'>
      <div className={styles.iconSkeleton} />
      <div>
        <div className={`${styles.shimmerBg} ${styles.h20} ${styles.w180} ${styles.mb8}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w240} ${styles.opacity70}`} />
      </div>
    </Flex>
  </div>
)

const QuickActionsSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <Flex gap='15px' align='center' justify='space-between' className={styles.mb16}>
      <Flex gap='6px' direction='column'>
        <div className={`${styles.shimmerBg} ${styles.h18} ${styles.w150}`} />
        <div className={`${styles.shimmerBg} ${styles.h14} ${styles.w300} ${styles.opacity70}`} />
      </Flex>
      <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w120}`} />
    </Flex>

    <div className={styles.configPanel}>
      <div className={`${styles.grid} ${styles.gridCols3} ${styles.headerBg}`}>
        <div className={`${styles.shimmerBg} ${styles.h14} ${styles.w60p}`} />
        <div className={`${styles.shimmerBg} ${styles.h14} ${styles.w20p}`} />
      </div>
      <div className={styles.p16}>
        {[1, 2, 3].map((item) => (
          <Flex key={item} align='center' justify='space-between' className={styles.configRow}>
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
            <div className={styles.switchPlaceholder} />
          </Flex>
        ))}
      </div>
    </div>
  </div>
)

const AiActionsSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <Flex gap='15px' align='center' justify='space-between' className={styles.mb16}>
      <Flex gap='6px' direction='column'>
        <div className={`${styles.shimmerBg} ${styles.h18} ${styles.w150}`} />
        <div className={`${styles.shimmerBg} ${styles.h14} ${styles.w300} ${styles.opacity70}`} />
      </Flex>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={`${styles.grid} ${styles.gridCols4} ${styles.headerBg}`}>
        {[1, 2, 3, 4].map((item) => (
          <div key={item} className={`${styles.shimmerBg} ${styles.h14} ${styles.w90p}`} />
        ))}
      </div>
      <div>
        {[1, 2, 3].map((row) => (
          <div key={row} className={`${styles.grid} ${styles.gridCols4} ${styles.tableRow}`}>
            {[1, 2, 3, 4].map((cell) => (
              <div key={cell} className={`${styles.shimmerBg} ${styles.h16} ${styles.w90p}`} />
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
)

const AppFooterSkeleton = () => (
  <div className={styles.footerSkeleton}>
    <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
  </div>
)

const OktaSkeleton = () => {
  return (
    <Flex width='100%' gap='25px' direction='column'>
      <AppInstalledCardSkeleton />
      <QuickActionsSkeleton />
      <AiActionsSkeleton />
      <AppFooterSkeleton />
    </Flex>
  )
}

export default OktaSkeleton
