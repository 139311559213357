import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import styles from './DateRangePicker.module.css'
import CalenderIcon from 'Icons/calender.svg'
import ClockIcon from 'Src/assetsv3/icons/clock.svg'

const DateRangePicker = ({
  fromDate,
  toDate,
  onChange,
  mode = 'daterange', // Can be 'daterange', 'datetime', or 'date'
  value,
  onDateTimeChange,
  disableFutureDates = false,
  disablePastDates = false
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [selecting, setSelecting] = useState(null)
  const pickerRef = useRef(null)
  const [selectedHour, setSelectedHour] = useState('12')
  const [selectedMinute, setSelectedMinute] = useState('00')
  const [selectedMeridiem, setSelectedMeridiem] = useState('AM')
  const [activeDropdown, setActiveDropdown] = useState(null)

  const hours = useMemo(() => Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0')), [])
  const minutes = useMemo(() => Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')), [])

  const getDaysInMonth = useCallback((date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const daysInMonth = new Date(year, month + 1, 0).getDate()
    const firstDayOfMonth = new Date(year, month, 1).getDay()
    return { daysInMonth, firstDayOfMonth }
  }, [])

  const isInRange = useCallback((date) => {
    if (!fromDate || !toDate) return false
    const checkDate = new Date(date).setHours(0, 0, 0, 0)
    return checkDate >= new Date(fromDate).setHours(0, 0, 0, 0) && checkDate <= new Date(toDate).setHours(0, 0, 0, 0)
  }, [fromDate, toDate])

  const isToday = useCallback((date) => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }, [])

  const isPastDate = useCallback((date) => {
    if (!disablePastDates) return false
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
  }, [disablePastDates])

  const isFutureDate = useCallback((date) => {
    if (!disableFutureDates) return false
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date > today
  }, [disableFutureDates])

  const isDateDisabled = useCallback((date) => {
    return (disablePastDates && isPastDate(date)) || (disableFutureDates && isFutureDate(date))
  }, [disablePastDates, disableFutureDates, isPastDate, isFutureDate])

  const handleDateClick = useCallback((date) => {
    const selectedDate = new Date(date)

    if (isDateDisabled(selectedDate)) return

    if (mode === 'datetime' || mode === 'date') {
      if (mode === 'datetime') {
        const existingDate = value ? new Date(value) : new Date()
        selectedDate.setHours(existingDate.getHours())
        selectedDate.setMinutes(existingDate.getMinutes())
      }
      onDateTimeChange(selectedDate.toISOString())
      if (mode === 'date') setIsOpen(false)
      return
    }

    // Original daterange logic
    const selectedISODate = selectedDate.toISOString()
    if (!fromDate || (fromDate && toDate) || selecting === 'start') {
      onChange(selectedISODate, null)
      setSelecting('end')
    } else {
      if (selectedDate < new Date(fromDate)) {
        onChange(selectedISODate, fromDate)
      } else {
        onChange(fromDate, selectedISODate)
      }
      setSelecting(null)
      setIsOpen(false)
    }
  }, [isDateDisabled, mode, value, onDateTimeChange, fromDate, toDate, selecting, onChange])

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (!pickerRef.current?.contains(event.target)) {
        setActiveDropdown(null)
      }
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleGlobalClick)
    return () => document.removeEventListener('mousedown', handleGlobalClick)
  }, [])

  const isSelectedDate = useCallback((date) => {
    if (!value || mode !== 'datetime') return false
    const valueDate = new Date(value)
    return (
      date.getDate() === valueDate.getDate() &&
      date.getMonth() === valueDate.getMonth() &&
      date.getFullYear() === valueDate.getFullYear()
    )
  }, [value, mode])

  const renderCalendar = () => {
    const { daysInMonth, firstDayOfMonth } = getDaysInMonth(currentMonth)
    const days = []
    const monthYear = currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })

    // Add empty cells for days before the first day of month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className={styles.dayCell} />)
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
      const isSelected = mode === 'datetime' ? isSelectedDate(date) : isInRange(date)
      const isStart = mode !== 'datetime' && fromDate && date.toISOString().split('T')[0] === fromDate.split('T')[0]
      const isEnd = mode !== 'datetime' && toDate && date.toISOString().split('T')[0] === toDate.split('T')[0]
      const isTodayDate = isToday(date)
      const isDisabled = isDateDisabled(date)

      days.push(
        <div
          key={day}
          className={`${styles.dayCell} 
                     ${isSelected ? (mode === 'datetime' ? styles.selected : styles.inRange) : ''} 
                     ${isStart ? styles.rangeStart : ''} 
                     ${isEnd ? styles.rangeEnd : ''}
                     ${isTodayDate ? styles.today : ''} 
                     ${isDisabled ? styles.disabled : ''}`}
          onClick={() => !isDisabled && handleDateClick(date)}
        >
          {day}
        </div>
      )
    }

    return (
      <div className={styles.calendar}>
        <div className={styles.calendarHeader}>
          <button onClick={() => setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)))}>
            ←
          </button>
          <span>{monthYear}</span>
          <button onClick={() => setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)))}>
            →
          </button>
        </div>
        <div className={styles.weekDays}>
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} className={styles.weekDay}>
              {day}
            </div>
          ))}
        </div>
        <div className={styles.daysGrid}>{days}</div>
      </div>
    )
  }

  const formatDateDisplay = (dateString) => {
    if (!dateString) return ''
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  }

  const formatDateTime = (date) => {
    if (!date) return ''
    return new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
  }

  const handleTimeSelection = (hour, minute, meridiem) => {
    const date = new Date(value || new Date())
    let hours24 = parseInt(hour)
    if (meridiem === 'PM' && hours24 !== 12) hours24 += 12
    if (meridiem === 'AM' && hours24 === 12) hours24 = 0

    date.setHours(hours24)
    date.setMinutes(parseInt(minute))
    onDateTimeChange(date.toISOString())

    setActiveDropdown(null)
  }

  const handleTimePartClick = (type) => {
    setActiveDropdown(activeDropdown === type ? null : type)
  }

  const renderTimeDropdown = (type) => {
    const values = type === 'hour' ? hours : minutes
    const currentValue = type === 'hour' ? selectedHour : selectedMinute

    return (
      <div className={styles.timeDropdown}>
        {values.map((value) => (
          <div
            key={value}
            className={`${styles.timeOption} ${value === currentValue ? styles.selected : ''}`}
            onClick={() => {
              if (type === 'hour') {
                setSelectedHour(value)
                handleTimeSelection(value, selectedMinute, selectedMeridiem)
              } else {
                setSelectedMinute(value)
                handleTimeSelection(selectedHour, value, selectedMeridiem)
              }
              setActiveDropdown(null)
            }}
          >
            {value}
          </div>
        ))}
      </div>
    )
  }

  const renderCompactTimePicker = () => (
    <div className={styles.timePickerCompact}>
      <ClockIcon className={styles.clockIcon} />
      <div className={styles.timeDisplay}>
        <div className={styles.timeDropdownContainer}>
          <span
            className={styles.timeValue}
            onClick={(e) => {
              e.stopPropagation()
              handleTimePartClick('hour')
            }}
          >
            {selectedHour}
          </span>
          {activeDropdown === 'hour' && renderTimeDropdown('hour')}
        </div>
        :
        <div className={styles.timeDropdownContainer}>
          <span
            className={styles.timeValue}
            onClick={(e) => {
              e.stopPropagation()
              handleTimePartClick('minute')
            }}
          >
            {selectedMinute}
          </span>
          {activeDropdown === 'minute' && renderTimeDropdown('minute')}
        </div>
        <div className={styles.meridiemOptions}>
          {['AM', 'PM'].map((meridiem) => (
            <span
              key={meridiem}
              className={`${styles.meridiemOption} ${selectedMeridiem === meridiem ? styles.selected : ''}`}
              onClick={() => {
                setSelectedMeridiem(meridiem)
                handleTimeSelection(selectedHour, selectedMinute, meridiem)
              }}
            >
              {meridiem}
            </span>
          ))}
        </div>
      </div>
    </div>
  )

  const handleClear = (e) => {
    e.stopPropagation()
    if (mode === 'daterange') {
      onChange(null, null)
    } else {
      onDateTimeChange(null)
    }
    setSelectedHour('12')
    setSelectedMinute('00')
    setSelectedMeridiem('AM')
    setIsOpen(false)
  }

  return (
    <div className={styles.container} ref={pickerRef}>
      <button className={styles.trigger} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.triggerContent}>
          {mode === 'daterange'
            ? fromDate && toDate
              ? `${formatDateDisplay(fromDate)} - ${formatDateDisplay(toDate)}`
              : 'Select date range'
            : value
            ? mode === 'datetime'
              ? formatDateTime(value)
              : formatDateDisplay(value)
            : `Select ${mode === 'datetime' ? 'date and time' : 'date'}`}
        </div>
        <CalenderIcon className={styles.calendarIcon} />
      </button>
      {isOpen && (
        <div className={styles.dropdown}>
          {renderCalendar()}
          {mode === 'datetime' && renderCompactTimePicker()}
          {((mode === 'daterange' && (fromDate || toDate)) || (mode !== 'daterange' && value)) && (
            <div className={styles.calendarFooter}>
              <button className={styles.clearBtn} onClick={handleClear} type='button'>
                Clear
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DateRangePicker
