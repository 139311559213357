import { TextField } from '@happyfoxinc/web-components'
import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './MergeInput.module.css'

import { getConcatenatedMergeFieldText } from 'Utils/mergeFields'

import InsertMergeFields from '../InsertMergeFields'

const MergeInput = ({ name, mergeFields, showTextArea = false }) => {
  const fieldRef = useRef(null)

  const formMethods = useFormContext()
  const { control, getValues, setValue } = formMethods

  const handleMergeFieldInsertion = (selectedMergeFields) => {
    const presentFieldValue = getValues(name)
    const cursorIndex = fieldRef.current.selectionStart
    const presentValueLength = presentFieldValue.length

    const isTheCursorAtTheStart = cursorIndex === 0
    const isTheCursorAtTheEnd = cursorIndex === presentValueLength

    const canAddSpacerAtStart = !isTheCursorAtTheStart
    const canAddSpacerAtEnd = !isTheCursorAtTheEnd

    const concatenatedMergeFieldText = getConcatenatedMergeFieldText(
      selectedMergeFields,
      canAddSpacerAtStart,
      canAddSpacerAtEnd
    )

    const updatedFieldValue =
      presentFieldValue.slice(0, cursorIndex) + concatenatedMergeFieldText + presentFieldValue.slice(cursorIndex)

    setValue(name, updatedFieldValue, { shouldDirty: true })
  }

  return (
    <div className={styles.FieldContainer}>
      <div className={styles.MergeFieldContainer}>
        <InsertMergeFields fields={mergeFields} onAdd={handleMergeFieldInsertion} />
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...renderProps } }) =>
          showTextArea ? (
            <textarea
              {...renderProps}
              className={styles.textarea}
              ref={(refValue) => {
                ref(refValue)
                fieldRef.current = refValue
              }}
            />
          ) : (
            <TextField.Root
              {...renderProps}
              ref={(refValue) => {
                ref(refValue)
                fieldRef.current = refValue
              }}
            />
          )
        }
      />
    </div>
  )
}

export default MergeInput
