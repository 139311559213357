import { Box, Flex } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

export const TableSkeleton = () => (
  <Box>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />

    <Flex justify='flex-end' className={styles.mb16}>
      <div className={styles.paginationContainer}>
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w80}`} />
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
      </div>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={styles.headerBg}>
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w180}`} />
      </div>
      {[1, 2, 3, 4, 5].map((row) => (
        <div key={row} className={styles.tableRow}>
          <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
        </div>
      ))}
    </div>
  </Box>
)

export const CollectionsConfigSkeleton = () => (
  <Box>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />
    <Box width='450px' className={styles.mb24}>
      <Flex gap='12px' align='center'>
        <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w300}`} />
        <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w80}`} />
      </Flex>
    </Box>
    <TableSkeleton />
  </Box>
)

export const AddCollectionsSkeleton = () => (
  <Box>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />
    <div className={styles.tableContainer}>
      <div className={styles.headerBg}>
        <Flex gap='12px'>
          <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} />
          <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w180}`} />
        </Flex>
      </div>
      {[1, 2, 3, 4, 5].map((row) => (
        <div key={row} className={styles.tableRow}>
          <Flex gap='12px'>
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} />
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
          </Flex>
        </div>
      ))}
    </div>
    <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120} ${styles.mt24}`} />
  </Box>
)

const OutlineSkeleton = () => {
  return (
    <Flex width='100%' gap='25px' direction='column'>
      <div className={styles.cardSkeleton}>
        <Flex gap='12px' align='center'>
          <div className={styles.iconSkeleton} />
          <div>
            <div className={`${styles.shimmerBg} ${styles.h20} ${styles.w180} ${styles.mb8}`} />
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w240} ${styles.opacity70}`} />
          </div>
        </Flex>
      </div>

      <CollectionsConfigSkeleton />

      <div className={styles.footerSkeleton}>
        <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
      </div>
    </Flex>
  )
}

export default OutlineSkeleton
