import AzureADLogo from 'Images/brands/azure-ad.svg'
import ConfluenceLogo from 'Images/brands/confluence.svg'
import GDriveLogo from 'Images/brands/g-drive.svg'
import GitbookLogo from 'Images/brands/gitbook.svg'
import GuruLogo from 'Images/brands/guru.svg'
import HappyFoxLogo from 'Images/brands/happyfox.svg'
import HfServiceDeskLogo from 'Images/brands/hf-servicedesk.svg'
import SharePointLogo from 'Images/brands/microsoft-sharepoint.svg'
import NotionLogo from 'Images/brands/notion.svg'
import OktaLogo from 'Images/brands/okta.svg'
import OutlineLogo from 'Images/brands/outline.svg'
import SimpplrLogo from 'Images/brands/simpplr.svg'
import WebsiteLogo from 'Images/brands/website.svg'
import OtsImage from 'Images/ots.svg'

export const logos = {
  azureAd: AzureADLogo,
  confluence: ConfluenceLogo,
  gDrive: GDriveLogo,
  gitbook: GitbookLogo,
  guru: GuruLogo,
  happyfox: HappyFoxLogo,
  hfServiceDesk: HfServiceDeskLogo,
  sharepoint: SharePointLogo,
  notion: NotionLogo,
  okta: OktaLogo,
  outline: OutlineLogo,
  simpplr: SimpplrLogo,
  website: WebsiteLogo,
  otherTicketingSystem: OtsImage
}

export default logos
