import baseApi from './baseApi'

export const aiActionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAppsAIActions: builder.mutation({
      query: ({ appName, appId, payload }) => ({
        url: `/v1/apps/${appName}/ai-actions/${appId}`,
        method: 'PATCH',
        body: payload
      })
    })
  })
})

export const { useUpdateAppsAIActionsMutation } = aiActionsApi
