import { Flex } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const ResponseGroupRowSkeleton = ({ depth = 0 }) => (
  <div className={styles.tableRow} style={{ paddingLeft: `${(depth + 1) * 24}px` }}>
    <div className={styles.accordionHeaderGrid}>
      <Flex gap='8px' align='center'>
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
      </Flex>
      <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w80} ${styles.badgeRadius}`} />
      <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w24}`} />
    </div>
  </div>
)

export const ResponseGroupChildrenSkeleton = ({ depth = 0, rowCount = 2 }) => (
  <div className={styles.accordionContent}>
    {Array(rowCount)
      .fill(0)
      .map((_, index) => (
        <ResponseGroupRowSkeleton key={index} depth={depth} />
      ))}
  </div>
)

const KnowledgeConfigurationSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w200} ${styles.mb16}`} />

    <Flex justify='flex-end' className={styles.mb16}>
      <div className={styles.paginationContainer}>
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w80}`} />
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
      </div>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={`${styles.grid} ${styles.gridCols3} ${styles.headerBg}`}>
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w120}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w100}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w60}`} />
      </div>

      {[1, 2, 3, 4].map((row) => (
        <div key={row} className={`${styles.grid} ${styles.gridCols3} ${styles.tableRow}`}>
          <Flex gap='8px' align='center'>
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} /> {/* Arrow icon */}
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} /> {/* Title */}
          </Flex>
          <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w80} ${styles.badgeRadius}`} /> {/* Badge */}
          <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w24}`} /> {/* Options */}
        </div>
      ))}
    </div>
  </div>
)

export default KnowledgeConfigurationSkeleton
