import { Box, Flex } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const TabsSkeleton = () => (
  <Flex align='center' gap='70px' className={styles.mb24}>
    {[1, 2].map((tab) => (
      <div key={tab} className={`${styles.shimmerBg} ${styles.h32} ${styles.w150}`} />
    ))}
  </Flex>
)

const FormFieldSkeleton = ({ label, width = '400px', inputType = 'input' }) => (
  <div className={styles.mb24}>
    <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w120} ${styles.mb8}`} />
    <Box width={width}>
      <div className={`${styles.shimmerBg} ${styles.h36} ${inputType === 'select' ? styles.selectSkeleton : ''}`} />
    </Box>
  </div>
)

const SwitchFieldSkeleton = () => (
  <div className={styles.mb16}>
    <Flex direction='row' gap='6px' align='center'>
      <div className={styles.switchPlaceholder} />
      <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
    </Flex>
  </div>
)

const HelpdeskLoadingScreen = () => {
  return (
    <Flex direction='column'>
      <TabsSkeleton />

      <div className={styles.mb24}>
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w120} ${styles.mb8}`} />
        <Box width='400px'>
          <div className={`${styles.shimmerBg} ${styles.h36}`} />
        </Box>
        <div className={`${styles.shimmerBg} ${styles.h12} ${styles.w240} ${styles.mt4} ${styles.opacity70}`} />
      </div>

      <FormFieldSkeleton />

      <FormFieldSkeleton inputType='select' />

      <FormFieldSkeleton width='300px' inputType='select' />

      <SwitchFieldSkeleton />
      <SwitchFieldSkeleton />

      <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120} ${styles.mt8}`} />
    </Flex>
  )
}

export default HelpdeskLoadingScreen
