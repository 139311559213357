// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wV0f1FZi_LfyPbiiLIdl{background:none;border:none;cursor:pointer;padding:8px;display:flex;align-items:center;transition:all .3s ease}.xwTl9hSc8JSVwmhBRsDh{display:flex;align-items:center;position:relative}", "",{"version":3,"sources":["webpack://./../src/componentsv3/BackButton/BackButton.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,uBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".backButton {\n  background: none;\n  border: none;\n  cursor: pointer;\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  transition: all 0.3s ease;\n}\n\n.backArrow {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "wV0f1FZi_LfyPbiiLIdl",
	"backArrow": "xwTl9hSc8JSVwmhBRsDh"
};
export default ___CSS_LOADER_EXPORT___;
