// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cr6EQQxBlIApyU2ZIewl{display:flex;align-items:center;justify-content:space-between}.cr6EQQxBlIApyU2ZIewl .KWwGNCF778c6GSUxGxYV{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.cr6EQQxBlIApyU2ZIewl.xidqSwAyJuZzVs5zvy3c{flex-direction:column;justify-content:flex-start;align-items:flex-start}.cr6EQQxBlIApyU2ZIewl.xidqSwAyJuZzVs5zvy3c .KWwGNCF778c6GSUxGxYV{white-space:normal}.cr6EQQxBlIApyU2ZIewl.xidqSwAyJuZzVs5zvy3c .LohpNgDRIZ_mTDeC0KHp{align-self:flex-end}", "",{"version":3,"sources":["webpack://./../src/componentsv3/ReadMore/ReadMore.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,4CACE,kBAAA,CACA,sBAAA,CACA,eAAA,CAGF,2CACE,qBAAA,CACA,0BAAA,CACA,sBAAA,CAGF,iEACE,kBAAA,CAGF,iEACE,mBAAA","sourcesContent":[".readMoreContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.readMoreContainer .readMoreContent {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.readMoreContainer.showMore {\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n\n.readMoreContainer.showMore .readMoreContent {\n  white-space: normal;\n}\n\n.readMoreContainer.showMore .readMoreToggleButton {\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readMoreContainer": "cr6EQQxBlIApyU2ZIewl",
	"readMoreContent": "KWwGNCF778c6GSUxGxYV",
	"showMore": "xidqSwAyJuZzVs5zvy3c",
	"readMoreToggleButton": "LohpNgDRIZ_mTDeC0KHp"
};
export default ___CSS_LOADER_EXPORT___;
