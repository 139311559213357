import { createContext, useContext } from 'react'
import { Navigate } from 'react-router-dom'

import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'

const NoRender = () => null

const FeaturesContext = createContext([])

export const useFeaturesContext = () => useContext(FeaturesContext)

export const canAllowFeature = (features, requiredFeatures) => {
  return requiredFeatures.every((feature) => features.includes(feature))
}

const KNOWN_FEATURES = [
  FEATURE_FLAGS.USER_GROUPS,
  FEATURE_FLAGS.CONVERSATIONAL_TICKETING,
  FEATURE_FLAGS.PREMIUM_INTEGRATIONS,
  FEATURE_FLAGS.REPORTS,

  // custom client only feature names
  CLIENT_FEATURE_FLAGS.ENABLE_TICKETING,
  CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE,
  CLIENT_FEATURE_FLAGS.SLACK_ACCOUNT,
  CLIENT_FEATURE_FLAGS.MS_TEAMS_ACCOUNT,
  CLIENT_FEATURE_FLAGS.SETTINGS,
  CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS,
  CLIENT_FEATURE_FLAGS.ACTIONS,
  CLIENT_FEATURE_FLAGS.WORKSPACES
]

const FeaturesProvider = ({ features, children }) => {
  return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>
}

const getAllFeatures = (allFeatures) => {
  return allFeatures.filter((featureName) => KNOWN_FEATURES.includes(featureName))
}

export const useFeature = (requiredFeatures = []) => {
  const allFeatures = useFeaturesContext()
  const allKnownFeatures = getAllFeatures(allFeatures)

  return canAllowFeature(allKnownFeatures, requiredFeatures)
}

export const Feature = (props) => {
  const { fallback = <NoRender />, children, features: requiredFeatures, negate = false } = props
  const allFeatures = useFeaturesContext()

  if (!requiredFeatures || requiredFeatures.length === 0) {
    return children
  }

  const allKnownFeatures = getAllFeatures(allFeatures)

  const isFeatureAllowed = canAllowFeature(allKnownFeatures, requiredFeatures)
  const isAllowed = negate ? !isFeatureAllowed : isFeatureAllowed

  if (!isAllowed) {
    return fallback
  }

  return children
}

export const ROUTE_FEATURE_GROUP = {
  user_groups: 'user_groups',
  settings: 'settings',
  reports: 'reports',
  dm_workflow_mode: 'dm_workflow_mode',
  happyfox_helpdesk_employee_notifications: 'happyfox_helpdesk_employee_notifications',
  channels: 'channels',
  actions: 'actions',
  workspaces: 'workspaces'
}

const ROUTE_FEATURES = {
  [ROUTE_FEATURE_GROUP.user_groups]: [FEATURE_FLAGS.USER_GROUPS],
  [ROUTE_FEATURE_GROUP.reports]: [FEATURE_FLAGS.REPORTS],
  [ROUTE_FEATURE_GROUP.dm_workflow_mode]: [CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE],
  [ROUTE_FEATURE_GROUP.settings]: [CLIENT_FEATURE_FLAGS.SETTINGS],
  [ROUTE_FEATURE_GROUP.channels]: [CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS],
  [ROUTE_FEATURE_GROUP.actions]: [CLIENT_FEATURE_FLAGS.ACTIONS],
  [ROUTE_FEATURE_GROUP.workspaces]: [CLIENT_FEATURE_FLAGS.WORKSPACES]
}

export const FeatureProtected = ({ group, fallback = <Navigate to='/dashboard' replace />, ...rest }) => {
  return <Feature features={ROUTE_FEATURES[group]} fallback={fallback} {...rest} />
}

export default FeaturesProvider
