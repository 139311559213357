// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FpUB2Eaxs4f9Yh8O4M1y{background-color:#fff3cd;border:1px solid #ffeeba;border-radius:4px;padding:6px 15px;font-size:10px;width:100%;box-shadow:rgba(0,0,0,.1) 0px 10px 15px -3px,rgba(0,0,0,.05) 0px 4px 6px -2px}.nFYsnlrdCt8Oms_cgHAN{display:flex;align-items:center;justify-content:space-between;gap:16px}.oX1qYmWf6JyvtY2p9sNk{color:#856404;line-height:1.5;flex:1;font-size:13px;font-family:inherit}.hgL2xoqRbnMjzX_GUynq{display:flex;gap:8px;flex-shrink:0}.GFAX4F9N2SzoCnVZyUml{min-width:100px;height:32px;font-size:12px;border-radius:4px;border:none;cursor:pointer;font-family:inherit}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/components/SyncDisabledAlert/SyncDisabledAlert.module.css"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,wBAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CACA,UAAA,CACA,6EAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAGF,sBACE,aAAA,CACA,eAAA,CACA,MAAA,CACA,cAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,WAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":[".warningBanner {\n  background-color: #fff3cd;\n  border: 1px solid #ffeeba;\n  border-radius: 4px;\n  padding: 6px 15px;\n  font-size: 10px;\n  width: 100%;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;\n}\n\n.warningContent {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 16px;\n}\n\n.warningMessage {\n  color: #856404;\n  line-height: 1.5;\n  flex: 1;\n  font-size: 13px;\n  font-family: inherit;\n}\n\n.warningActions {\n  display: flex;\n  gap: 8px;\n  flex-shrink: 0;\n}\n\n.warningButton {\n  min-width: 100px;\n  height: 32px;\n  font-size: 12px;\n  border-radius: 4px;\n  border: none;\n  cursor: pointer;\n  font-family: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningBanner": "FpUB2Eaxs4f9Yh8O4M1y",
	"warningContent": "nFYsnlrdCt8Oms_cgHAN",
	"warningMessage": "oX1qYmWf6JyvtY2p9sNk",
	"warningActions": "hgL2xoqRbnMjzX_GUynq",
	"warningButton": "GFAX4F9N2SzoCnVZyUml"
};
export default ___CSS_LOADER_EXPORT___;
