// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uy4S2A20H5ch8pmEhrOj{margin-bottom:20px}.y32S0s7SientPNn08Ofd{margin-bottom:20px}.y32S0s7SientPNn08Ofd:last-child{margin-bottom:0}.u_5XQry6sAiLWSOhYvGb{font-size:var(--text-sm);color:var(--color-text-muted);margin-bottom:8px}.c3AvoEspyMhEfYHwKeS0{color:var(--error-color);font-size:var(--text-xs);margin-top:4px;display:block}.u_5XQry6sAiLWSOhYvGb.L4NEdpUWUWplwzWcpl9J::after{content:\" *\";font-weight:bold;color:var(--asterisk-color);opacity:1}", "",{"version":3,"sources":["webpack://./../src/componentsv3/FormField/FormField.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,iCACE,eAAA,CAGF,sBACE,wBAAA,CACA,6BAAA,CACA,iBAAA,CAGF,sBACE,wBAAA,CACA,wBAAA,CACA,cAAA,CACA,aAAA,CAGF,kDACE,YAAA,CACA,gBAAA,CACA,2BAAA,CACA,SAAA","sourcesContent":[".formField {\n  margin-bottom: 20px;\n}\n\n.field {\n  margin-bottom: 20px;\n}\n\n.field:last-child {\n  margin-bottom: 0;\n}\n\n.label {\n  font-size: var(--text-sm);\n  color: var(--color-text-muted);\n  margin-bottom: 8px;\n}\n\n.error {\n  color: var(--error-color);\n  font-size: var(--text-xs);\n  margin-top: 4px;\n  display: block;\n}\n\n.label.required::after {\n  content: \" *\";\n  font-weight: bold;\n  color: var(--asterisk-color);\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": "Uy4S2A20H5ch8pmEhrOj",
	"field": "y32S0s7SientPNn08Ofd",
	"label": "u_5XQry6sAiLWSOhYvGb",
	"error": "c3AvoEspyMhEfYHwKeS0",
	"required": "L4NEdpUWUWplwzWcpl9J"
};
export default ___CSS_LOADER_EXPORT___;
