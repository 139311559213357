import baseApi, { TAGS } from './baseApi'

export const notionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotionApp: builder.query({
      query: (params) => ({
        url: 'v1/apps/notion',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.NOTION.NOTION]
    }),

    getNotionUserAccounts: builder.query({
      query: (params) => ({
        url: 'v1/apps/notion/accounts',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.NOTION.NOTION_ACCOUNTS]
    }),

    getAllNotionPages: builder.query({
      query: (params) => ({
        url: 'v1/apps/notion/pages',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.NOTION.NOTION_PAGES]
    }),

    searchNotionPages: builder.query({
      query: (params) => ({
        url: 'v1/apps/notion/pages/search',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.NOTION.NOTION_PAGES]
    }),

    syncNotionPages: builder.mutation({
      query: (data) => ({
        url: 'v1/apps/notion/pages/sync',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.NOTION.NOTION_PAGES]
    }),

    getNotionSyncedPages: builder.query({
      query: (params) => ({
        url: 'v1/apps/notion/pages/sync',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.NOTION.NOTION_SYNCED_PAGES]
    }),

    removeNotionPage: builder.mutation({
      query: ({ page_id, workspace_id }) => ({
        url: `v1/apps/notion/pages/${page_id}`,
        method: 'DELETE',
        params: { workspace_id }
      }),
      invalidatesTags: [TAGS.NOTION.NOTION_PAGES, TAGS.NOTION.NOTION_SYNCED_PAGES]
    }),

    enableSyncNotion: builder.mutation({
      query: (params) => ({
        url: 'v1/apps/notion/sync/enable',
        method: 'POST',
        body: params
      }),
      invalidatesTags: [TAGS.NOTION.NOTION, TAGS.NOTION.NOTION_PAGES, TAGS.NOTION.NOTION_SYNCED_PAGES]
    }),

    uninstallNotion: builder.mutation({
      query: (params) => ({
        url: 'v1/apps/notion',
        method: 'DELETE',
        params
      }),
      invalidatesTags: [TAGS.NOTION.NOTION, TAGS.NOTION.NOTION_PAGES, TAGS.NOTION.NOTION_SYNCED_PAGES]
    })
  })
})

export const {
  useGetNotionAppQuery,
  useGetNotionUserAccountsQuery,
  useGetAllNotionPagesQuery,
  useSearchNotionPagesQuery,
  useSyncNotionPagesMutation,
  useGetNotionSyncedPagesQuery,
  useRemoveNotionPageMutation,
  useEnableSyncNotionMutation,
  useUninstallNotionMutation
} = notionApi
