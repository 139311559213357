// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rJyz30jRF1SfXrczZKk2{font-size:var(--text-sm);font-weight:var(--font-weight-medium);margin-bottom:10px}.prfLoqIVkuLWv9ndKIA9{padding:1rem 1.2rem}.dPgLIrYh1nuULzT3IP4c{color:var(--color-text-default);font-weight:var(--font-weight-medium)}.SwGcy3q2xkcashLIdUiB{opacity:58%}.CVsG2iCoWWHYokX5dcc2{height:1px;background-color:var(--color-black);opacity:10%;width:95%;display:inline-block}.MGJQ56bCaanO4uam_rBn{color:var(--color-accent-default);text-decoration:none}.ZxBaQyxCJ5wyXy4RIV3_{font-size:var(--text-xs);color:var(--color-text-default)}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Channels/TipToAddInChannels/TipToAddInChannels.module.css"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,qCAAA,CACA,kBAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,+BAAA,CACA,qCAAA,CAGF,sBACE,WAAA,CAGF,sBACE,UAAA,CACA,mCAAA,CACA,WAAA,CACA,SAAA,CACA,oBAAA,CAGF,sBACE,iCAAA,CACA,oBAAA,CAGF,sBACE,wBAAA,CACA,+BAAA","sourcesContent":[".heading {\n  font-size: var(--text-sm);\n  font-weight: var(--font-weight-medium);\n  margin-bottom: 10px;\n}\n\n.tooltipContent {\n  padding: 1rem 1.2rem;\n}\n\n.strongText {\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-medium);\n}\n\n.dividerContainer {\n  opacity: 58%;\n}\n\n.divider {\n  height: 1px;\n  background-color: var(--color-black);\n  opacity: 10%;\n  width: 95%;\n  display: inline-block;\n}\n\n.link {\n  color: var(--color-accent-default);\n  text-decoration: none;\n}\n\n.textContent {\n  font-size: var(--text-xs);\n  color: var(--color-text-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "rJyz30jRF1SfXrczZKk2",
	"tooltipContent": "prfLoqIVkuLWv9ndKIA9",
	"strongText": "dPgLIrYh1nuULzT3IP4c",
	"dividerContainer": "SwGcy3q2xkcashLIdUiB",
	"divider": "CVsG2iCoWWHYokX5dcc2",
	"link": "MGJQ56bCaanO4uam_rBn",
	"textContent": "ZxBaQyxCJ5wyXy4RIV3_"
};
export default ___CSS_LOADER_EXPORT___;
