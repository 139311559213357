// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JVysTWpjJv2gqcUpf2Vx{border-radius:0px 0px 8px 8px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/Okta/Okta.module.css"],"names":[],"mappings":"AAAA,sBACE,6BAAA","sourcesContent":[".modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "JVysTWpjJv2gqcUpf2Vx"
};
export default ___CSS_LOADER_EXPORT___;
