import { Flex } from '@happyfoxinc/web-components'
import { Fragment } from 'react'

import styles from './AiActions.module.css'

import AiActionsTable from './AiActionsTable'

const AiActions = ({ data }) => {
  return (
    <Fragment>
      <Flex gap='15px' align='center' justify='space-between' className={styles.wrapper}>
        <Flex gap='6px' direction='column'>
          <h2 className={styles.heading}>AI Actions</h2>
          <p className={styles.helpText}>
            AI actions utilize Assist AI's capabilities to swiftly execute tasks, simplifying and expediting users'
            actions without the need for manual navigation through conventional processes.
          </p>
        </Flex>
      </Flex>
      <AiActionsTable data={data} />
    </Fragment>
  )
}

export default AiActions
