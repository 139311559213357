import { createApi } from '@reduxjs/toolkit/query/react'

import { customBaseQuery } from 'Src/customBaseQuery'

export const TAGS = {
  ACCOUNT: 'Account',
  PROFILE: 'Profile',
  USERS: 'Users',
  USER_GROUPS: 'UserGroups',
  WORKSPACES: 'Workspaces',
  APPS: 'Apps',
  MODULES: 'Modules',
  COMPANY_POLICIES: 'CompanyPolicies',
  COMPANY_POLICY_FILES: 'CompanyPolicyFiles',
  SOFTWARE_ACCESS_MODULE: {
    APP_PROVIDER: 'AppProvider',
    SOFTWARES: 'Softwares',
    PROVISION_STEPS: 'ProvisionSteps',
    STEP_DETAILS: 'StepDetails',
    ACCESS_CONTROL: 'AccessControl',
    TICKET_CONFIGURATION: 'TicketConfiguration'
  },
  SURVEYS: {
    LIST: 'SurveysList',
    DETAILS: 'SurveyDetails',
    QUESTIONS: 'SurveyQuestions',
    REPORTS: 'SurveyReports'
  },
  CAMPAIGNS: {
    LIST: 'CampaignsList',
    DETAILS: 'CampaignDetails'
  },
  ANSWERS: 'Answers',
  SLACK_CHANNELS: 'SlackChannels',
  ADMINS: 'Admins',
  HELPDESK_APP: 'HelpdeskApp',
  HELPDESK_APP_EMPLOYEE_NOTIFICATIONS: 'HelpdeskAppEmployeeNotifications',
  SERVICDESK_APP: 'ServicedeskApp',
  NOTION: {
    NOTION: 'Notion',
    NOTION_ACCOUNTS: 'NotionAccounts',
    NOTION_PAGES: 'NotionPages',
    NOTION_SYNCED_PAGES: 'NotionSyncedPages'
  },
  SHAREPOINT: {
    SHAREPOINT: 'Sharepoint',
    SHAREPOINT_SITES: 'SharepointSites'
  },
  RESPONSE_GROUP: {
    RESPONSE_GROUPS: 'ResponseGroups',
    RESPONSE_GROUP_ITEMS: 'ResponseGroupItems',
    RESPONSE_GROUPS_CHILDREN: 'ResponseGroupsChildren'
  },
  OKTA: {
    OKTA: 'Okta',
    OKTA_AI_ACTIONS: 'OktaAIActions'
  },
  AZURE_AD: {
    AZURE_AD: 'AzureAD',
    AZURE_AD_AI_ACTIONS: 'AzureADAIActions'
  },
  CONFLUENCE: 'Confluence',
  GURU: 'Guru',
  SIMPPLR: {
    SIMPPLR: 'Simpplr',
    SIMPPLR_SITES: 'SimpplrSites'
  },
  GITBOOK: {
    GITBOOK: 'Gitbook',
    GITBOOK_SPACES: 'GitbookSpaces'
  },
  OUTLINE: 'Outline',
  NOTIFICATIONS: 'Notifications',
  TIME_ZONES: 'TimeZones',
  REPORTS: 'Reports'
}

const flattenTags = (obj) => {
  return Object.values(obj).reduce((acc, val) => {
    if (typeof val === 'string') {
      return [...acc, val]
    }
    if (typeof val === 'object' && val !== null) {
      return [...acc, ...flattenTags(val)]
    }
    return acc
  }, [])
}

export const baseApi = createApi({
  reducerPath: 'apiV3',
  baseQuery: customBaseQuery,
  tagTypes: flattenTags(TAGS),
  endpoints: () => ({})
})

export default baseApi
