import { Box, Flex } from '@happyfoxinc/web-components'
import { Fragment, useState } from 'react'

// import styles from './SoftwareAccessModule.module.css'

import BackButton from 'Src/componentsv3/BackButton'
// import Tab from 'Src/componentsv3/Tab'

import SoftwareList from './SoftwareList'

const SoftwareAccessModule = () => {
  const [view] = useState('configuration')

  // const handleTabChange = (tab) => {
  //   setView(tab)
  // }

  return (
    <Fragment>
      <Box className='page-content' style={{ height: '100%' }}>
        <div className='heading-container'>
          <Flex align='center' gap='4px'>
            <BackButton className='back-btn' />
            <h1 className='heading'>Software Access Request Management</h1>
          </Flex>
          <p className='sub-heading'>
            Choose the software to quickly get started with the Software Access Request Management tool. Once selected,
            you can configure and customize access control, provisioning, and ticketing configuration all in one place.
          </p>
        </div>
        {/* TODO: Uncomment this code if you've more than one tab in the software request management. For example, "Reports" */}
        {/* <Flex className={styles.tabContainer} align='center' gap='15px'>
          <Tab
            label='Configuration'
            isActive={view === 'configuration'}
            onClick={() => handleTabChange('configuration')}
          />
        </Flex> */}
        {view === 'configuration' ? <SoftwareList /> : view === 'reports' ? <Fragment /> : null}
      </Box>
    </Fragment>
  )
}

export default SoftwareAccessModule
