import { Button, Flex, TextField } from '@happyfoxinc/web-components'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './SurveyConfiguration.module.css'

import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import InfoIcon from 'Src/assetsv3/icons/info.svg'
import ChevronRight from 'Src/assetsv3/icons/white-chevron-right.svg'
import ListIcon from 'Src/assetsv3/logos/surveys-logo.svg'
import FormField from 'Src/componentsv3/FormField'
import { usePreviewSurveyMutation } from 'Src/servicesV3/surveyApi'

import CommonContentLoader from './components/CommonContentLoader/CommonContentLoader'

const SurveyConfiguration = ({ isLoading, currentScreen, surveyId }) => {
  const [previewSurvey] = usePreviewSurveyMutation()

  const handlePreviewClick = useCallback(async () => {
    if (!surveyId) {
      toast.error('Please save the survey first')
      return
    }

    const promise = previewSurvey(surveyId)
    await toast.promise(promise, {
      loading: 'Sending preview...',
      success: 'Preview sent! Check your DM.',
      error: 'Failed to send preview'
    })
  }, [surveyId, previewSurvey])

  const {
    register,
    formState: { errors }
  } = useFormContext()

  if (isLoading) {
    return <CommonContentLoader fields={2} />
  }

  const renderBasicConfig = useMemo(
    () => (
      <div className={styles.tabContent}>
        <FormField>
          <FormField.Field error={errors?.surveyName?.message} isRequired label='Survey Name'>
            <TextField.Root size='1' radius='small' placeholder='Enter survey name' {...register('surveyName')} />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field label='Description'>
            <textarea {...register('description')} placeholder='Enter survey description' className={styles.textarea} />
          </FormField.Field>
        </FormField>
      </div>
    ),
    []
  )

  const renderNotificationConfig = useMemo(
    () => (
      <div className={styles.tabContent}>
        <div className={styles.headerSection}>
          <Flex align='center' style={{ width: '100%', justifyContent: 'space-between' }}>
            <h3 className={styles.configTitle}>Message Configuration</h3>
            <Tooltip delayDuration={150}>
              <TooltipTrigger style={{ display: 'flex', border: 'none', background: 'none', padding: 0 }}>
                <Button
                  variant='outline'
                  onClick={handlePreviewClick}
                  className={styles.previewButton}
                  disabled={!surveyId}
                >
                  Preview Survey
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <span>Click to receive a sample survey in your DM through Slack/MS Teams</span>
              </TooltipContent>
            </Tooltip>
          </Flex>
        </div>

        <FormField>
          <FormField.Field error={errors?.notificationMessage?.message} label='Survey Notification Message *'>
            <textarea
              {...register('notificationMessage')}
              placeholder='Enter notification message'
              className={styles.textarea}
            />
          </FormField.Field>
        </FormField>

        <FormField>
          <FormField.Field error={errors?.thankYouMessage?.message} isRequired label='Thank You Message'>
            <textarea
              {...register('thankYouMessage')}
              placeholder='Enter thank you message'
              className={styles.textarea}
            />
          </FormField.Field>
        </FormField>

        <div className={styles.scheduleTooltip}>
          <InfoIcon className={styles.infoIcon} />
          <div className={styles.tooltipText}>
            After finalizing your survey, <strong>create a campaign</strong> to schedule and distribute the survey to
            your desired audience. You can find the campaigns section next to the surveys tab.
          </div>
        </div>
      </div>
    ),
    [handlePreviewClick, surveyId, errors?.notificationMessage?.message, errors?.thankYouMessage?.message, register]
  )

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <h2 className={styles.title}>Survey Configuration</h2>
        <div className={styles.buttonGroup}>
          <button className={`${styles.configButton} ${styles.activeButton}`}>
            <div className={styles.buttonContent}>
              <div className={styles.buttonText}>
                <h3>{currentScreen === 0 ? 'Basic Settings' : 'Notification Settings'}</h3>
                <p>
                  {currentScreen === 0
                    ? 'Set up the basic details of your survey'
                    : 'Customize survey notification and thank you messages'}
                </p>
              </div>
              <div className={styles.selectedArrowContainer}>
                <ChevronRight />
              </div>
            </div>
          </button>
        </div>
      </div>

      <div className={styles.rightSection}>
        <Flex>
          <ListIcon className={styles.propertiesIcon} />
          <h3 className={styles.propertiesTitle}>{currentScreen === 0 ? 'Basic Details' : 'Messaging'}</h3>
        </Flex>
        {currentScreen === 0 ? renderBasicConfig : renderNotificationConfig}
      </div>
    </div>
  )
}

export default SurveyConfiguration
