import { Box, Button, Flex, Modal, TextField } from '@happyfoxinc/web-components'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './AnswerForm.module.css'

import FormField from 'Src/componentsv3/FormField'
import MergeTrixEditor from 'Src/componentsv3/MergeTrixEditor'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { ANSWER_CONTENT_MERGE_FIELDS } from 'Src/constants/mergeFields'
import { ANSWER_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Src/constants/user-groups'
import { useDeleteAnswerMutation } from 'Src/servicesV3/answersApi'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'linear-gradient(270deg, rgba(245, 245, 247, 1) 0%, rgba(248, 248, 248, 1) 100%);',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-md)',
    padding: '2px',
    margin: '2px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: '0',
    color: 'var(--color-text-default)',
    fontSize: '12px'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: 'transparent',
      color: '#dc2626'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px',
    backgroundColor: '#fff',
    border: state?.isFocused
      ? '#3b82f6'
      : state?.selectProps?.error
      ? '#dc2626'
      : '1px solid var(--color-border-default)',
    boxShadow: state?.isFocused ? '0 0 0 1px #3b82f6' : 'none',
    '&:hover': {
      borderColor: state?.isFocused ? '#3b82f6' : '#cbd5e1'
    }
  })
}

const DeleteModal = (props) => {
  const { id, data, ...restProps } = props
  const [deleteAnswer, deleteAnswerApiResults] = useDeleteAnswerMutation()

  const handleDelete = useCallback(() => {
    const promise = deleteAnswer(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting answer',
      success: `Deleted answer '${data.name}'. Successfully...`,
      error: `Error while deleting answer '${data.name}'. Try again.`
    })
    props.onClose()
  }, [id, data, deleteAnswer, props])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this answer?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteAnswerApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteAnswerApiResults.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const AnswerForm = ({ isEdit = false, isLoading = false, onSubmit, onSaveAndAdd, showSaveAndAdd = true }) => {
  const navigate = useNavigate()
  const { currentWorkspaceId, workspaces } = useWorkspace()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { data: account } = useGetAccountQuery()
  const [getUserGroups, getUserGroupsResult] = userGroupsApi.useLazyGetUserGroupsQuery()

  const accountType = account?.account_type
  const showWorkspaceDropdown = account?.is_workspaces_enabled

  const formMethods = useFormContext()

  const {
    register,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue
  } = formMethods

  const answer = getValues()

  const selectedVisibility = watch('visibility')

  useEffect(() => {
    if (!watch('workspace_id')) {
      setValue('workspace_id', currentWorkspaceId)
    }
  }, [currentWorkspaceId, setValue, watch])

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const handleFormSubmit = (e) => {
    onSubmit(e)
  }

  const handleSaveAndAdd = (e) => {
    onSaveAndAdd(e)
  }

  return (
    <Fragment>
      <form onSubmit={handleFormSubmit}>
        <FormField>
          <FormField.Field label='Answer Title' error={errors?.name?.message}>
            <Box width='330px'>
              <TextField.Root {...register('name')} size='2' radius='small' placeholder='Enter title' />
            </Box>
          </FormField.Field>
        </FormField>
        <FormField>
          <FormField.Field error={errors?.content?.message}>
            <Box width='710px'>
              <MergeTrixEditor name='content' mergeFields={ANSWER_CONTENT_MERGE_FIELDS} />
            </Box>
          </FormField.Field>
        </FormField>
        <FormField>
          <Flex gap='24px'>
            {!isEdit && showWorkspaceDropdown && (
              <FormField.Field label='Workspace' error={errors?.workspace_id?.message}>
                <Controller
                  name='workspace_id'
                  control={control}
                  render={({ field }) => (
                    <Box width='330px'>
                      <ReactSelect
                        {...field}
                        value={workspaces.find((opt) => opt.id === field.value)}
                        onChange={(opt) => field.onChange(opt.id)}
                        isClearable={false}
                        options={workspaces}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder='Select Workspace'
                      />
                    </Box>
                  )}
                />
              </FormField.Field>
            )}
            <FormField.Field label='Show answer to' error={errors?.visibility?.message}>
              <Controller
                name='visibility'
                control={control}
                render={({ field }) => (
                  <Box width='330px'>
                    <ReactSelect
                      {...field}
                      placeholder='Select'
                      value={ANSWER_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                      onChange={(opt) => field.onChange(opt.value)}
                      isClearable={false}
                      options={ANSWER_VISIBILITY_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </Flex>
        </FormField>
        {selectedVisibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
          <FormField>
            <FormField.Field
              label='Select user group(s) for which the answer will be recommended'
              error={errors?.visibleToGroups?.message}
            >
              <Controller
                name='visibleToGroups'
                control={control}
                render={({ field }) => (
                  <Box width='720px'>
                    <ReactSelect
                      {...field}
                      placeholder='Select Groups'
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isMulti
                      options={[]}
                      isClearable={false}
                      loadOptions={loadOptions}
                      isLoading={getUserGroupsResult.isLoading}
                      loadingMessage={({ inputValue }) => {
                        return `Searching for sites matching "${inputValue}"...`
                      }}
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length < 3) {
                          return 'Type atleast 3 characters to start searching'
                        }
                        return `No sites found for input "${inputValue}"`
                      }}
                      styles={customSelectStyles}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </FormField>
        )}
        <Flex justify='space-between' mt='24px' width='720px'>
          <Flex gap='12px'>
            <Button type='submit' disabled={isLoading}>
              Save
            </Button>
            {showSaveAndAdd && (
              <Button type='button' variant='outline' disabled={isLoading} onClick={handleSaveAndAdd}>
                Save and Add
              </Button>
            )}
            <Button type='button' variant={showSaveAndAdd ? 'ghost' : 'outline'} onClick={() => navigate('../')}>
              Cancel
            </Button>
          </Flex>
          <Button type='button' className={styles.deleteButton} onClick={() => setIsDeleteModalOpen(true)}>
            Delete
          </Button>
        </Flex>
      </form>
      <DeleteModal
        open={isDeleteModalOpen}
        onOpenChange={() => {
          setIsDeleteModalOpen(false)
        }}
        onClose={() => {
          setIsDeleteModalOpen(false)
        }}
        id={answer?.id}
        data={answer}
        title='Delete Answer'
        showFooter={false}
        bodyClassName={styles.modalBody}
      />
    </Fragment>
  )
}

export default AnswerForm
