import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import toast from 'react-hot-toast'

import styles from './Simpplr.module.css'

import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import KnowledgeConfiguration from 'Src/pagesv3/Apps/components/KnowledgeConfiguration'
import SyncDisabledAlert from 'Src/pagesv3/Apps/components/SyncDisabledAlert'
import {
  useEnableSyncSimpplrMutation,
  useGetSimpplrAppQuery,
  useUninstallSimpplrMutation
} from 'Src/servicesV3/simpplrApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import SimpplrSites from './SimpplrSites'
import SimpplrSkeleton from './SimpplrSkeleton'

const Simpplr = () => {
  const { currentWorkspaceId } = useWorkspace()

  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { data: app = {}, isLoading } = useGetSimpplrAppQuery({ workspace_id: currentWorkspaceId })
  const [enableSync, { isLoading: isEnablingSync }] = useEnableSyncSimpplrMutation()
  const [uninstallApp, { isLoading: isUninstalling }] = useUninstallSimpplrMutation()

  const handleEnableSync = async () => {
    const promise = enableSync({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, {
      loading: 'Enabling sync...',
      success: 'Simpplr sync enabled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || 'Failed to enable sync for Simpplr. Try again...'
        setIsEnableSyncModalOpen(false)
        return errorMessage
      }
    })
  }

  const handleUninstall = async () => {
    const promise = uninstallApp({ workspace_id: currentWorkspaceId })
    promise.then(() => {
      setIsUninstallModalOpen(false)
    })

    toast.promise(promise, {
      loading: 'Uninstalling...',
      success: 'Simpplr uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || `Failed to uninstall Simpplr. Try again...`
        return errorMessage
      }
    })
  }

  if (isLoading) {
    return <SimpplrSkeleton />
  }

  const isSyncDisabled = !app.sync_info.is_sync_enabled

  return (
    <Flex gap='15px' direction='column' className={styles.wrapper}>
      {isSyncDisabled && <SyncDisabledAlert title='simpplr' onEnableSync={() => setIsEnableSyncModalOpen(true)} />}
      <AppInstalledCard title='Simpplr' accountUrl={app.account_url} />
      <SimpplrSites />
      <KnowledgeConfiguration app={app} />

      <AppFooter app={app} isUninstalling={isUninstalling} onUninstall={() => setIsUninstallModalOpen(true)} />
      <Modal
        size='small'
        title='Uninstall Simpplr'
        open={isUninstallModalOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        onClose={() => setIsUninstallModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isUninstalling} onClick={handleUninstall}>
              Uninstall
            </Button>
            <Button variant='outline' disabled={isUninstalling} onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        size='default'
        title='Enable Sync for Confluence'
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsEnableSyncModalOpen(false)
        }}
        open={isEnableSyncModalOpen}
        onClose={() => setIsEnableSyncModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.ENABLE_APP_SYNC('Simpplr')}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isEnablingSync} onClick={handleEnableSync}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isEnablingSync} onClick={() => setIsEnableSyncModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Simpplr
