import baseApi, { TAGS } from './baseApi'

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportMetrics: builder.query({
      query: (params) => ({
        url: 'v1/report-metrics',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.REPORTS]
    }),
    exportReportMetrics: builder.mutation({
      query: (params) => ({
        url: 'v1/report-metrics/export',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.REPORTS]
    }),
    getAllTopics: builder.query({
      query: () => '/v1/topics',
      providesTags: [TAGS.REPORTS]
    })
  })
})

export const { useGetReportMetricsQuery, useExportReportMetricsMutation, useGetAllTopicsQuery } = reportsApi
