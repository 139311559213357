import { Box, Button, Flex, Grid, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import styles from './Workspaces.module.css'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { KNOWLEDGE_GROUP_VISIBILITY_STATES, WORKSPACE_VISIBILITY_OPTIONS } from 'Src/constants/user-groups'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'

const CirclePlusIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#030303'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-plus'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M8 12h8' />
    <path d='M12 8v8' />
  </svg>
)

const CircleMinusIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#030303'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
    className='lucide lucide-circle-minus'
  >
    <circle cx='12' cy='12' r='10' />
    <path d='M8 12h8' />
  </svg>
)

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'linear-gradient(270deg, rgba(245, 245, 247, 1) 0%, rgba(248, 248, 248, 1) 100%);',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-md)',
    padding: '2px',
    margin: '2px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: '0',
    color: 'var(--color-text-default)',
    fontSize: '12px'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: 'transparent',
      color: '#dc2626'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px',
    // minWidth: '720px',
    backgroundColor: '#fff',
    border: state?.isFocused
      ? '#3b82f6'
      : state?.selectProps?.error
      ? '#dc2626'
      : '1px solid var(--color-border-default)',
    boxShadow: state?.isFocused ? '0 0 0 1px #3b82f6' : 'none',
    '&:hover': {
      borderColor: state?.isFocused ? '#3b82f6' : '#cbd5e1'
    }
  })
}

const workspaceValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  visibility: yup.string().oneOf(Object.values(KNOWLEDGE_GROUP_VISIBILITY_STATES)).required(),
  visibleToGroups: yup.array().when('visibility', {
    is: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC,
    then: (schema) => schema.min(1, 'Please select at least 1 User group').required()
  }),
  example_queries: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().trim().required('Query cannot be empty')
      })
    )
    .min(3, 'Minimum 3 queries required')
})

const WorkspaceForm = ({ workspace, onSubmit, onCancel, isEdit }) => {
  const { data: account } = useGetAccountQuery()
  const [getUserGroups, getUserGroupsResult] = userGroupsApi.useLazyGetUserGroupsQuery()

  const accountType = account?.account_type

  const transformQueriesToFormData = (queries = []) => {
    const result = queries.map((query) => ({ value: query }))
    while (result.length < 3) {
      result.push({ value: '' })
    }
    return result
  }

  const getInitialVisibleGroups = () => {
    if (!workspace?.mapped_user_groups) return []
    return workspace.mapped_user_groups.map((group) => ({
      id: group.id,
      name: group.name
    }))
  }

  const defaultValues = {
    name: workspace?.name || '',
    description: workspace?.description || '',
    visibility: workspace?.visibility || KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL,
    visibleToGroups: getInitialVisibleGroups(),
    example_queries: transformQueriesToFormData(workspace?.example_queries || [])
  }

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit
  } = useForm({
    defaultValues,
    resolver: yupResolver(workspaceValidationSchema)
  })

  const {
    fields: queryFields,
    append: appendQuery,
    remove: removeQuery
  } = useFieldArray({
    control,
    name: 'example_queries',
    rules: {
      minLength: 3
    }
  })

  const handleRemove = (index) => {
    if (queryFields.length > 3) {
      removeQuery(index)
    }
  }

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const handleFormSubmit = (formData) => {
    const transformedData = {
      ...formData,
      example_queries: formData.example_queries.map((query) => query.value)
    }
    onSubmit(transformedData)
  }

  const selectedVisibility = watch('visibility')

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid columns='repeat(2, 1fr)' gap='24px'>
        <Box>
          <FormField>
            <Box width='330px'>
              <FormField.Field error={errors?.name?.message} label='Name'>
                <TextField.Root {...register('name')} size='2' radius='small' placeholder='Enter email' />
              </FormField.Field>
            </Box>
          </FormField>

          <FormField>
            <Box width='330px'>
              <FormField.Field error={errors?.description?.message} label='Description'>
                <textarea
                  {...register('description')}
                  placeholder='Enter description'
                  className={styles.descriptionTextarea}
                  rows={4}
                />
              </FormField.Field>
            </Box>
          </FormField>

          <FormField>
            <FormField.Field label='Visibility' error={errors?.visibility?.message}>
              <Box width='330px'>
                <Controller
                  name='visibility'
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      placeholder='Select'
                      value={WORKSPACE_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                      onChange={(opt) => field.onChange(opt.value)}
                      isClearable={false}
                      options={WORKSPACE_VISIBILITY_OPTIONS}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  )}
                />
              </Box>
            </FormField.Field>
          </FormField>

          {selectedVisibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
            <FormField>
              <FormField.Field label='Select Groups(s)' error={errors?.visibleToGroups?.message}>
                <Box width='330px'>
                  <Controller
                    name='visibleToGroups'
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        placeholder='Select Groups'
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isMulti
                        options={[]}
                        isClearable={false}
                        loadOptions={loadOptions}
                        isLoading={getUserGroupsResult.isLoading}
                        loadingMessage={({ inputValue }) => `Searching for sites matching "${inputValue}"...`}
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 3) {
                            return 'Type alteast 3 characters to start searching'
                          }
                          return `No sites found for input "${inputValue}"`
                        }}
                        styles={customSelectStyles}
                      />
                    )}
                  />
                </Box>
              </FormField.Field>
            </FormField>
          )}
        </Box>

        <Box>
          <FormField>
            <FormField.Field label='Example Queries'>
              {queryFields.map((query, index) => (
                <Flex key={query.id} align='center' className={styles.queriesWrapper}>
                  <Box width='250px'>
                    <TextField.Root
                      {...register(`example_queries.${index}.value`)}
                      size='2'
                      radius='small'
                      placeholder='Enter query'
                    />
                    {errors?.example_queries?.[index]?.value && (
                      <p className={styles.error}>{errors.example_queries[index].value.message}</p>
                    )}
                  </Box>
                  <Box className={styles.queryButtons}>
                    {index === queryFields.length - 1 && queryFields.length >= 3 && (
                      <Button variant='ghost' onClick={() => appendQuery({ value: '' })}>
                        <CirclePlusIcon />
                      </Button>
                    )}
                    {queryFields.length > 3 && (
                      <Button variant='ghost' onClick={() => handleRemove(index)}>
                        <CircleMinusIcon />
                      </Button>
                    )}
                  </Box>
                </Flex>
              ))}
            </FormField.Field>
          </FormField>
        </Box>
      </Grid>

      <Box className={styles.submitButton}>
        <Flex gap='15px'>
          <Button variant='solid' type='submit' disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Create'}
          </Button>
          <Button variant='outline' type='button' onClick={() => onCancel()}>
            Cancel
          </Button>
        </Flex>
      </Box>
    </form>
  )
}

export default WorkspaceForm
