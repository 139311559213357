import { Box, Flex } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'

import styles from './Answers.module.css'

import PageLoader from 'Src/components/PageLoader'
import BackButton from 'Src/componentsv3/BackButton'
import { useGetAnswerQuery, useUpdateAnswerMutation } from 'Src/servicesV3/answersApi'
import parseErrorMessage from 'Src/utils/error-message-parser'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import AnswerForm from './AnswerForm'
import answerFormValidationSchema from './AnswerForm/answer-form-validation-schema'

const AnswerFormHandler = ({ answer }) => {
  const navigate = useNavigate()
  const { currentWorkspaceId } = useWorkspace()
  const [updateAnswer, updateAnswerResults] = useUpdateAnswerMutation()

  const formMethods = useForm({
    defaultValues: {
      id: answer.id,
      name: answer.name,
      content: answer.content,
      workspace_id: currentWorkspaceId,
      visibility: answer.visibility,
      visibleToGroups: answer.mapped_user_groups
    },
    resolver: yupResolver(answerFormValidationSchema)
  })

  const { handleSubmit } = formMethods

  const update = useCallback(
    (payload) => {
      const promise = updateAnswer(payload).unwrap()
      toast.promise(promise, {
        loading: 'Updating answer...',
        success: 'Answer updated successfully.',
        error: parseErrorMessage('Unable to update answer. Try again...')
      })
      return promise
    },
    [updateAnswer]
  )

  const getPayloadFromFormData = (data) => {
    const payload = {
      name: data.name,
      content: data.content,
      workspace_id: data.workspace_id
    }

    if (data.visibility) {
      payload.visibility = data.visibility
    }

    if (data.visibleToGroups) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    return payload
  }

  const processAnswer = useCallback(
    async (data, addMore = false) => {
      const payload = {
        answerId: answer.id,
        ...getPayloadFromFormData(data)
      }

      try {
        await update(payload)
        navigate('../' + `${addMore ? 'create' : ''}`)
      } catch {}
    },
    [answer.id, update, navigate]
  )

  const onSubmit = useCallback(
    (e) => {
      handleSubmit((data) => processAnswer(data))(e)
    },
    [handleSubmit, processAnswer]
  )

  const onSaveAndAdd = useCallback(
    (e) => {
      handleSubmit((data) => processAnswer(data, true))(e)
    },
    [handleSubmit, processAnswer]
  )

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Edit Answer</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <AnswerForm
            onSubmit={onSubmit}
            onSaveAndAdd={onSaveAndAdd}
            submitButtonText='Update'
            showSaveAndAdd={false}
            isLoading={updateAnswerResults.isLoading}
          />
        </FormProvider>
      </Box>
    </Box>
  )
}

const AnswerEdit = () => {
  const { id } = useParams()
  const { data: answer, isLoading } = useGetAnswerQuery(id)
  if (isLoading) {
    return <PageLoader />
  }
  return <AnswerFormHandler answer={answer} />
}

export default AnswerEdit
