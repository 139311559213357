import { Box, Button, Flex, Modal, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import styles from './Servicedesk.module.css'

import FormField from 'Src/componentsv3/FormField'
import MergeInput from 'Src/componentsv3/MergeInput/MergeInput'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import Tab from 'Src/componentsv3/Tab'
import { DEFAULT_TICKET_SUBJECT_MERGE_FIELD, TICKET_SUBJECT_MERGE_FIELDS } from 'Src/constants/mergeFields'
import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import { SYNC_STATUS } from 'Src/constants/syncStatus'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import KnowledgeConfiguration from 'Src/pagesv3/Apps/components/KnowledgeConfiguration'
import SyncDisabledAlert from 'Src/pagesv3/Apps/components/SyncDisabledAlert'
import {
  useEnableServicedeskSyncMutation,
  useGetServicedeskAppQuery,
  useUninstallServicedeskAppMutation,
  useUpdateServicedeskAppMutation
} from 'Src/servicesV3/servicedeskAppApi'
import noHtmlTagTypeEnclosedText from 'Src/utils/inputValidation/noHtmlTagTypeEnclosedText'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import ServicedeskSkeleton from './ServicedeskSkeleton'

const validationSchema = yup
  .object()
  .shape({
    ticketSubject: yup.string().trim().required('Ticket subject is required').test(noHtmlTagTypeEnclosedText),
    allowedTeams: yup.array().min(1, 'Allowed teams is required')
  })
  .required()

const TicketConfiguration = ({ app }) => {
  const title = 'Servicedesk'
  const { currentWorkspaceId } = useWorkspace()

  const [updateSettings, updateSettingsResult] = useUpdateServicedeskAppMutation()

  const formMethods = useForm({
    defaultValues: {
      ticketSubject: app?.subject ?? `{{${DEFAULT_TICKET_SUBJECT_MERGE_FIELD}}}`,
      allowedTeams: app?.allowed_teams.map((teamId) => app?.all_teams.find((team) => team.id === teamId))
    },
    resolver: yupResolver(validationSchema)
  })

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = formMethods

  const handleFormSubmit = (data) => {
    const payload = {
      ticket_subject: data.ticketSubject,
      allowed_teams: data.allowedTeams.map((team) => team.id),
      workspace_id: currentWorkspaceId
    }

    const promise = updateSettings(payload).unwrap()

    toast.promise(promise, {
      loading: `Updating settings for ${title}`,
      success: `Successfully updated settings for ${title}`,
      error: (err) => {
        const message = err?.data?.message || `Unable to update ${title} settings. Try again`
        return message
      }
    })
  }

  return (
    <Flex direction='column'>
      <FormField>
        <FormField.Field label='You have successfully connected your HappyFox Service Desk account'>
          <Box width='400px'>
            <TextField.Root value={app.account_url} readOnly size='2' radius='small' />
          </Box>
          <p className={styles.helpText}>Example: https://myaccount.servicedesk.com</p>
        </FormField.Field>
      </FormField>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormField>
            <FormField.Field
              label={
                <Fragment>
                  Ticket Subject <sup>*</sup>
                </Fragment>
              }
              error={errors?.ticketSubject?.message}
            >
              <Box width='400px'>
                <MergeInput showTextArea name='ticketSubject' mergeFields={TICKET_SUBJECT_MERGE_FIELDS} />
              </Box>
            </FormField.Field>
          </FormField>

          <FormField>
            <FormField.Field
              label={
                <Fragment>
                  Allowed teams <sup>*</sup>
                </Fragment>
              }
              error={errors?.allowedTeams?.message}
            >
              <Controller
                control={control}
                name='allowedTeams'
                render={({ field }) => (
                  <Box width='400px'>
                    <ReactSelect
                      {...field}
                      isMulti
                      isClearable={false}
                      options={app.all_teams}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />
                  </Box>
                )}
              />
            </FormField.Field>
          </FormField>

          <Button
            type='submit'
            radius='medium'
            disabled={updateSettingsResult.isLoading || !isDirty}
            loading={updateSettingsResult.isLoading}
          >
            Save
          </Button>
        </form>
      </FormProvider>
    </Flex>
  )
}

const Servicedesk = () => {
  const [view, setView] = useState('ticket_configuration')
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { currentWorkspaceId } = useWorkspace()
  const { data: app = {}, isLoading } = useGetServicedeskAppQuery({ workspace_id: currentWorkspaceId })
  const [enableSync, { isLoading: isEnablingSync }] = useEnableServicedeskSyncMutation()
  const [uninstallApp, { isLoading: isUninstalling }] = useUninstallServicedeskAppMutation()

  const handleTabChange = (tab) => {
    setView(tab)
  }

  const handleEnableSync = () => {
    const promise = enableSync({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false))

    toast.promise(promise, {
      loading: 'Enabling sync for Service Desk...',
      success: 'Service Desk sync enabled successfully',
      error: (err) => {
        const message = err?.data?.message || 'Failed to enable sync for Service Desk. Try again...'
        setIsEnableSyncModalOpen(false)
        return message
      }
    })
  }

  const handleUninstall = () => {
    const promise = uninstallApp({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: 'Uninstalling Service Desk...',
      success: 'Service Desk uninstalled successfully',
      error: (err) => {
        const message = err?.data?.message || 'Failed to uninstall Service Desk'
        return message
      }
    })
  }

  if (isLoading) return <ServicedeskSkeleton />

  const isHFServiceDeskSyncEnabled = app.sync_info.is_sync_enabled
  const isHFServiceDeskExpired = app.is_service_desk_account_expired

  const syncDisabledForAccountExpiryMessage = SYNC_STATUS.DISABLED_FOR_EXPIRY.replace(
    '{{title}}',
    'service desk'
  ).replace('{{account_type}}', 'service desk')

  return (
    <Flex direction='column' gap='24px' width='100%'>
      {!isHFServiceDeskSyncEnabled && !isHFServiceDeskExpired && (
        <SyncDisabledAlert title='servicedesk' onEnableSync={() => setIsEnableSyncModalOpen(true)} />
      )}
      {!isHFServiceDeskSyncEnabled && isHFServiceDeskExpired && (
        <SyncDisabledAlert
          title='servicedesk'
          message={syncDisabledForAccountExpiryMessage}
          onEnableSync={() => setIsEnableSyncModalOpen(true)}
        />
      )}
      <Flex align='center' gap='8px'>
        <Tab
          isLoading={isLoading}
          label='Ticket configuration'
          isActive={view === 'ticket_configuration'}
          onClick={() => handleTabChange('ticket_configuration')}
        />
        <Tab
          isLoading={isLoading}
          label='Knowledge configuration'
          isActive={view === 'knowledge_configuration'}
          onClick={() => handleTabChange('knowledge_configuration')}
        />
      </Flex>
      {view && view === 'ticket_configuration' && <TicketConfiguration app={app} />}
      {view && view === 'knowledge_configuration' && <KnowledgeConfiguration app={app} />}
      <AppFooter app={app} onUninstall={() => setIsUninstallModalOpen(true)} isUninstalling={isUninstalling} />
      <Modal
        size='small'
        title='Uninstall Service Desk'
        open={isUninstallModalOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        onClose={() => setIsUninstallModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isUninstalling} onClick={handleUninstall}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isUninstalling} onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        size='default'
        title='Enable Sync for Service Desk'
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsEnableSyncModalOpen(false)
        }}
        open={isEnableSyncModalOpen}
        onClose={() => setIsEnableSyncModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {isHFServiceDeskExpired && (
            <Text variant='muted' className={styles.SyncAlert}>
              {CONFIRM_MESSAGES.HF_ACCOUNT_EXPIRED_ALERT('Service Desk')}
            </Text>
          )}
          {CONFIRM_MESSAGES.ENABLE_APP_SYNC('Service Desk')}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isEnablingSync} onClick={handleEnableSync}>
              Confirm
            </Button>
            <Button variant='outline' disabled={isEnablingSync} onClick={() => setIsEnableSyncModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Servicedesk
