import baseApi from './baseApi'

export const agentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAgents: builder.query({
      query: (params) => ({
        url: 'v1/agents',
        method: 'GET',
        params
      })
    })
  })
})

export const { useGetAgentsQuery } = agentsApi
