import { Modal } from '@happyfoxinc/web-components'
import toast from 'react-hot-toast'

import styles from './Workspaces.module.css'

import { useCreateWorkspaceMutation } from 'Src/servicesV3/workspaceApi'

import WorkspaceForm from './WorkspaceForm'

const CreateWorkspace = ({ isOpen, onClose }) => {
  const [createWorkspace] = useCreateWorkspaceMutation()

  const handleCreateWorkspace = async (data) => {
    const payload = {
      name: data.name,
      description: data.description,
      visibility: data.visibility,
      example_queries: data.example_queries
    }

    if (data.visibleToGroups?.length) {
      payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
    }

    try {
      const promise = createWorkspace(payload).unwrap()

      toast.promise(promise, {
        loading: 'Creating workspace',
        success: 'Workspace created successfully',
        error: (err) => {
          const errorMessage = err?.data?.error || 'Unable to create workspace'
          return errorMessage
        }
      })

      await promise
      onClose()
    } catch {}
  }

  return (
    <Modal
      size='large'
      open={isOpen}
      onOpenChange={onClose}
      onCancel={onClose}
      title='Create Workspace'
      showFooter={false}
      bodyClassName={styles.modalBody}
    >
      <WorkspaceForm onSubmit={handleCreateWorkspace} onCancel={onClose} isEdit={false} />
    </Modal>
  )
}

export default CreateWorkspace
