import { Box, Button, Dropdown, Flex, Table, TableOption, TableOptions, TextField } from '@happyfoxinc/web-components'
import cx from 'classnames'
import { Fragment, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import styles from './ConfigModule.module.css'

import ChevronDown from 'Src/assetsv3/icons/chevron-down.svg'
import EditIcon from 'Src/assetsv3/icons/edit-pencil.svg'
import UpArrowIcon from 'Src/assetsv3/icons/up-arrow.svg'
import ChevronRight from 'Src/assetsv3/icons/white-chevron-right.svg'
// import TickIcon from 'Src/assetsv3/icons/white-tick.svg'
import CompanyPolicyIcon from 'Src/assetsv3/logos/policy-logo.svg'
// import ReactSelect from 'Src/componentsv3/ReactSelect'
import {
  useAddPolicyTypeMutation,
  useGetPoliciesQuery,
  useUpdatePolicyMutation,
  useUploadFilesMutation
} from 'Src/servicesV3/companyPolicyModuleApi'
import { useGetModuleMetaInfoQuery } from 'Src/servicesV3/softwareAccessModuleApi'

import AccessControl from '../AccessControl'
import ConfigLoadingScreen from './ConfigLoadingScreen'

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const LoadingRowSingleColumn = () => (
  <Table.Row>
    <Table.Cell>
      <div className={styles.loadingCell} />
    </Table.Cell>
  </Table.Row>
)

const LoadingRowDoubleColumn = () => (
  <Table.Row>
    <Table.Cell>
      <div className={styles.loadingCell} />
    </Table.Cell>
    <Table.Cell>
      <div className={styles.loadingCell} />
    </Table.Cell>
  </Table.Row>
)

const LoadingSpinner = () => (
  <div className={styles.spinnerWrapper}>
    <div className={styles.spinner} />
  </div>
)

const preparePolicyList = (policies) => {
  const policyList = []
  if (!policies) {
    return policyList
  }

  for (const policy of policies) {
    if (policy.settings.file_id) {
      policyList.push({
        file_id: policy.settings?.file_id,
        file_name: policy.settings?.file_name,
        type: policy.settings?.access_type,
        policy_name: policy.name,
        policy_id: policy.id,
        file_path: null,
        source: 'file',
        accessConfig: {
          isFullAccess: policy.settings?.access_control?.isFullAccess || false,
          conditions: policy.settings?.access_control?.conditions || [],
          configured: policy.settings?.configured || false
        }
      })
    }
  }
  return policyList
}

const ConfigModule = ({
  currentScreen,
  onConfigUpdate,
  showValidation,
  setValidationError,
  dropdownErrors,
  setDropdownErrors
}) => {
  const [selectedType, setSelectedType] = useState('manual')
  const [policyName, setPolicyName] = useState('')

  const { data = {}, isLoading: isPolicyLoading } = useGetPoliciesQuery()
  const { data: policiesList = [] } = data || {}

  // const [selectedAnswer, setSelectedAnswer] = useState('')
  // const [url, setUrl] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [policyList, setPolicyList] = useState(preparePolicyList(policiesList))
  const [policyTypeOptions, setPolicyTypeOptions] = useState([])
  const fileInputRef = useRef(null)

  const [editingPolicyId, setEditingPolicyId] = useState(null)
  const [editingName, setEditingName] = useState('')

  const [error, setError] = useState('')
  const [editingError, setEditingError] = useState('')

  const { data: modulesMetaData = {}, isLoading: isMetaLoading } = useGetModuleMetaInfoQuery()
  const { fields: accessControlData, create_ticket_configuration: createTicketData } = modulesMetaData

  const [addPolicyType, addPolicyTypeResult] = useAddPolicyTypeMutation()
  const [updatePolicy, updatePolicyResult] = useUpdatePolicyMutation()
  const [uploadFiles, uploadFilesResult] = useUploadFilesMutation()

  const [selectedPolicy, setSelectedPolicy] = useState(policyList.length === 1 ? policyList[0].policy_name : null)

  useEffect(() => {
    if (policiesList.length > 0) {
      const options = policiesList.map((policy) => ({
        id: policy.id,
        name: policy.name,
        hidden: policyList.some((p) => p.policy_name === policy.name)
      }))

      return setPolicyTypeOptions(options)
    }
  }, [policiesList, policyList, setPolicyTypeOptions])

  useEffect(() => {
    if (currentScreen === 2 && policyList.length > 0) {
      setSelectedPolicy(policyList[0].policy_name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreen])

  const handleTypeSelect = (type) => {
    setSelectedType(type)
  }

  const handleAddPolicy = async () => {
    if (policyName.trim()) {
      const policyExists = policiesList.some((policy) => policy.name.toLowerCase() === policyName.trim().toLowerCase())

      if (policyExists) {
        setError('A policy type with this name already exists')
        return
      }

      try {
        const promise = addPolicyType({ policy_name: policyName }).unwrap()

        toast.promise(promise, {
          loading: 'Adding policy type...',
          success: 'Policy type added successfully',
          error: (err) => err?.data?.error || 'Failed to add policy type'
        })

        await promise
        setPolicyName('')
        setError('')
        setValidationError(false)
      } catch {
        // Error is handled by toast.promise
      }
    }
  }

  const handlePolicyNameChange = (e) => {
    const newValue = e.target.value
    setPolicyName(newValue)

    if (!newValue.trim()) {
      setError('')
      return
    }

    const policyExists = policiesList.some((policy) => policy.name.toLowerCase() === newValue.trim().toLowerCase())

    if (policyExists) {
      setError('A policy type with this name already exists')
    } else {
      setError('')
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddPolicy()
    }
  }

  // const handleAnswerSelect = (value) => {
  //   setSelectedAnswer(value)
  // }

  // const handleUrlChange = (e) => {
  //   setUrl(e.target.value)
  // }

  // const handleUrlKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     handleUrlAdd()
  //   }
  // }

  const getUniqueFileName = (originalName) => {
    const lastDotIndex = originalName.lastIndexOf('.')
    const name = originalName.substring(0, lastDotIndex)
    const ext = originalName.substring(lastDotIndex)

    let counter = 1
    let newName = originalName

    const existingNames = [...uploadedFiles.map((f) => f.name), ...policyList.map((p) => p.file_name)]

    while (existingNames.includes(newName)) {
      newName = `${name}(${counter})${ext}`
      counter++
    }

    return newName
  }

  const handleChooseFile = () => {
    fileInputRef.current?.click()
  }

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files)
    const validFiles = files.filter((file) => ALLOWED_FILE_TYPES.includes(file.type))

    if (validFiles.length !== files.length) {
      alert('Please upload only PDF or Word documents')
      event.target.value = ''
      return
    }

    const newFiles = validFiles.reduce((acc, file) => {
      const fileName = file.name
      const uniqueName = getUniqueFileName(fileName)

      const renamedFile = new File([file], uniqueName, { type: file.type })
      return [...acc, renamedFile]
    }, [])

    setUploadedFiles([...uploadedFiles, ...newFiles])
    event.target.value = ''
  }

  const handleAddUploadedFiles = async () => {
    if (uploadedFiles.length === 0) return
    try {
      const formData = new FormData()
      uploadedFiles.forEach((file) => {
        formData.append('files', file)
      })

      const promise = uploadFiles(formData).unwrap()

      toast.promise(promise, {
        loading: 'Uploading files...',
        success: 'Files uploaded successfully',
        error: (err) => err?.data?.error || 'Failed to upload files'
      })

      const response = await promise

      const newPolicies = response.data.map((file) => ({
        file_id: file.file_id,
        file_name: file.file_name,
        type: null,
        file_path: file.file_path,
        source: 'file',
        accessConfig: {
          isFullAccess: false,
          conditions: [],
          configured: false
        }
      }))

      setPolicyList((prev) => [...prev, ...newPolicies])
      setUploadedFiles([])
    } catch {
      // Error is handled by toast.promise
    }
  }

  // const handleAnswerAdd = () => {
  //   if (selectedAnswer) {
  //     const newPolicy = {
  //       id: Date.now(),
  //       name: selectedAnswer.label,
  //       type: null,
  //       source: 'answer',
  //       accessConfig: {
  //         isFullAccess: false,
  //         conditions: [],
  //         configured: false
  //       }
  //     }
  //     setPolicyList((prev) => [...prev, newPolicy])
  //     setSelectedAnswer(null)
  //   }
  // }

  // const handleUrlAdd = () => {
  //   if (url.trim()) {
  //     const newPolicy = {
  //       id: Date.now(),
  //       name: url,
  //       type: null,
  //       source: 'url',
  //       accessConfig: {
  //         isFullAccess: false,
  //         conditions: [],
  //         configured: false
  //       }
  //     }
  //     setPolicyList((prev) => [...prev, newPolicy])
  //     setUrl('')
  //   }
  // }

  const handlePolicyTypeChange = async (fileId, selectedType) => {
    setPolicyList((prevPolicies) =>
      prevPolicies.map((policy) =>
        policy.file_id === fileId ? { ...policy, policy_id: selectedType.id, policy_name: selectedType.name } : policy
      )
    )

    setPolicyTypeOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === selectedType.id) {
          return { ...option, hidden: true }
        }
        return option
      })
      return updatedOptions
    })
  }

  const handleRemovePolicy = (fileId, policyName) => {
    setPolicyList((prevPolicies) => prevPolicies.filter((p) => p.file_id !== fileId))
    if (selectedPolicy === policyName) {
      setSelectedPolicy(null)
    }
    toast.success('Policy removed successfully')
  }

  const handleRemoveFile = (index) => {
    setUploadedFiles(uploadedFiles.filter((_, i) => i !== index))
  }

  const handlePolicySelect = (policy) => {
    setSelectedPolicy(policy === selectedPolicy ? null : policy)
  }

  const handleStartEdit = (policy) => {
    setEditingPolicyId(policy.id)
    setEditingName(policy.name)
  }

  const handleNameChange = (e) => {
    const newValue = e.target.value
    setEditingName(newValue)

    validatePolicyName(newValue)
  }

  const validatePolicyName = (name) => {
    if (!name.trim()) {
      setEditingError('Policy type name cannot be empty')
      return false
    }

    const policyExists = policiesList.some(
      (policy) => policy.name.toLowerCase() === name.trim().toLowerCase() && policy.id !== editingPolicyId
    )

    if (policyExists) {
      setEditingError('A policy type with this name already exists')
      return false
    }

    setEditingError('')
    return true
  }

  const handleUpdatePolicyName = async () => {
    if (editingPolicyId && editingName.trim() && !editingError) {
      try {
        const promise = updatePolicy({
          policyId: editingPolicyId,
          name: editingName.trim()
        }).unwrap()

        toast.promise(promise, {
          loading: 'Updating policy name...',
          success: 'Policy name updated successfully',
          error: (err) => err?.data?.error || 'Failed to update policy name'
        })

        await promise
        setEditingPolicyId(null)
        setEditingName('')
        setEditingError('')
      } catch {
        // Error is handled by toast.promise
      }
    }
  }

  const FileList = ({ files, onRemoveFile }) => {
    const visibleFiles = files.slice(0, 2)
    const remainingCount = files.length - 2

    return (
      <Flex align='center' p='10px' gap='8px'>
        {visibleFiles.map((file, index) => (
          <div key={index} className={styles.fileItem}>
            <p className={styles.fileName}>{file.name}</p>
            <button className={styles.removeFileBtn} onClick={() => onRemoveFile(index)}>
              <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='14' height='13'>
                <path
                  fill='none'
                  stroke='black'
                  strokeWidth='1'
                  strokeLinecap='round'
                  strokeLinejoin='bevel'
                  transform='matrix(0.707107 0.707107 -0.707107 0.707107 9.37375 3.15153)'
                  d='M0.31426969 0.31426969L0.31426969 8.485281'
                  fillRule='evenodd'
                />
                <path
                  fill='none'
                  stroke='black'
                  strokeWidth='1'
                  strokeLinecap='round'
                  strokeLinejoin='bevel'
                  transform='matrix(0.707107 0.707107 -0.707107 0.707107 3.8182 3.37375)'
                  d='M0 0.31426969L8.1710119 0.31426969'
                  fillRule='evenodd'
                />
              </svg>
            </button>
          </div>
        ))}
        {remainingCount > 0 && (
          <div className={styles.fileItem}>
            <span className={styles.fileName}>+{remainingCount}</span>
          </div>
        )}
        <Button
          className={styles.addButton}
          onClick={handleAddUploadedFiles}
          disabled={uploadFilesResult.isLoading}
          variant='outline'
          size='small'
        >
          Add +
        </Button>
      </Flex>
    )
  }

  useEffect(() => {
    onConfigUpdate(policyList)
  }, [policyList, onConfigUpdate])

  if (isPolicyLoading || isMetaLoading) {
    return <ConfigLoadingScreen />
  }

  return (
    <Flex gap='24px' height='calc(100vh - 200px)' overflow='hidden'>
      <Box width='50%' overflowY='auto' p='3rem 3rem 3rem 4rem'>
        {currentScreen === 0 && (
          <Fragment>
            <Flex align='baseline' gap='20px' className={styles.sectionHeadContainer}>
              <h2 className={styles.sectionTitle}>Policy Type</h2>
              {/* <p className={styles.sectionDescription}>Choose how you'd like to define the policy type</p> */}
            </Flex>

            {/* <Box
              p='10px 15px'
              width='100%'
              className={cx(styles.sectionCard, { [styles.sectionCardActive]: selectedType === 'auto' })}
              onClick={() => handleTypeSelect('auto')}
            >
              <Flex width='90%' direction='column' gap='6px'>
                <h3 className={styles.sectionContentTitle}>Auto Generate Policy Type</h3>
                <p className={styles.sectionContentDescription}>
                  AI will automatically identify the documents and assign the appropriate policy type.
                </p>
              </Flex>
              <div className={cx(styles.radio, { [styles.radioActive]: selectedType === 'auto' })}>
                <TickIcon />
              </div>
            </Box> */}

            <Box
              p='10px 15px'
              width='100%'
              className={cx(styles.sectionCard, { [styles.sectionCardActive]: selectedType === 'manual' })}
              onClick={() => handleTypeSelect('manual')}
            >
              <Flex width='90%' direction='column' gap='6px'>
                <h3 className={styles.sectionContentTitle}>Manually Add Policy Type</h3>
                <p className={styles.sectionContentDescription}>
                  Add policy type, upload relevant documents, and assign the appropriate policy type.
                </p>
              </Flex>
              {/* <div className={cx(styles.radio, { [styles.radioActive]: selectedType === 'manual' })}>
                <TickIcon />
              </div> */}

              {selectedType === 'manual' && (
                <Fragment>
                  <hr className={styles.divider} />
                  <Box p='5px 0 15px'>
                    <p className={styles.label}>Enter policy type name and add</p>
                    <Flex gap='20px' align='baseline'>
                      <TextField.Root
                        size='1'
                        radius='small'
                        placeholder='Enter policy type name'
                        value={policyName}
                        onChange={handlePolicyNameChange}
                        onKeyPress={handleKeyPress}
                      />
                      <Button
                        className={styles.addButton}
                        variant='outline'
                        size='small'
                        onClick={handleAddPolicy}
                        disabled={!policyName.trim() || addPolicyTypeResult.isLoading}
                      >
                        {addPolicyTypeResult.isLoading ? 'Adding...' : 'Add +'}
                      </Button>
                    </Flex>
                    {error && <p className={styles.errorText}>{error}</p>}
                  </Box>
                </Fragment>
              )}
            </Box>
          </Fragment>
        )}
        {currentScreen === 1 && (
          <Fragment>
            <Flex align='baseline' className={styles.sectionHeadContainer}>
              <h2 className={styles.sectionTitle}>Add Policies</h2>
            </Flex>

            {/* Uncomment the answers section once the feature is implemented */}
            {/* Answers Section */}
            {/* <Flex gap='10px' align='center' className={styles.contentHeadWrapper}>
              <h3 className={styles.contentTitle}>Answers</h3>
              <div className={styles.verticalLine} />
            </Flex>
            <h4 className={styles.label}>Add answers that are related to company policy</h4>
            <Flex gap='10px' align='center' className={styles.sectionHeadContainer}>
              <Box width='410px'>
                <ReactSelect
                  value={selectedAnswer}
                  onChange={handleAnswerSelect}
                  placeholder='Select answers you want to configure'
                  options={[
                    { value: 'categories', label: 'What are Categories?' },
                    { value: 'plan', label: 'How can I change my plan?' },
                    { value: 'helpdesk', label: 'Multilingual Help Desk' }
                  ]}
                />
              </Box>
              <Button
                className={styles.addButton}
                variant='outline'
                size='small'
                onClick={handleAnswerAdd}
                disabled={!selectedAnswer}
              >
                Add +
              </Button>
            </Flex> */}

            {/* Uncomment the URL section once the feature is implemented */}
            {/* URL Section */}
            {/* <Flex gap='10px' align='center' className={styles.contentHeadWrapper}>
              <h3 className={styles.contentTitle}>Webpage URL</h3>
              <div className={styles.verticalLine} />
            </Flex>
            <h4 className={styles.label}>Add policy page URLs</h4>
            <Flex gap='10px' align='center' className={styles.sectionHeadContainer}>
              <Box width='410px'>
                <TextField.Root
                  size='1'
                  radius='small'
                  placeholder='Enter policy page URL'
                  value={url}
                  onChange={handleUrlChange}
                  onKeyPress={handleUrlKeyPress}
                />
              </Box>
              <Button
                className={styles.addButton}
                variant='outline'
                size='small'
                onClick={handleUrlAdd}
                disabled={!url.trim()}
              >
                Add +
              </Button>
            </Flex> */}

            {/* Upload Section */}
            {/* <Flex gap='10px' align='center' className={styles.contentHeadWrapper}>
              <h3 className={styles.contentTitle}>Upload</h3>
              <div className={styles.verticalLine} />
            </Flex> */}
            <h4 className={styles.label}>Upload policy documents</h4>

            <div className={styles.uploadContainer}>
              <div
                className={cx(styles.uploadArea, { [styles.borderBottom]: uploadedFiles.length > 0 })}
                onClick={handleChooseFile}
              >
                <span className={styles.arrowWrapper}>
                  <UpArrowIcon />
                </span>
                <p className={styles.uploadText}>
                  Drag & Drop Documents here or
                  <Button variant='ghost' className={styles.chooseFileBtn}>
                    Choose file
                  </Button>
                </p>
                <input
                  type='file'
                  multiple
                  accept='.pdf,.doc,.docx'
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  className={styles.hiddenInput}
                />
              </div>

              {uploadedFiles.length > 0 && <FileList files={uploadedFiles} onRemoveFile={handleRemoveFile} />}
            </div>
          </Fragment>
        )}
        {currentScreen === 2 && (
          <Fragment>
            <Flex align='baseline' className={styles.sectionHeadContainer}>
              <h2 className={styles.sectionTitle}>Access Control</h2>
            </Flex>

            <div className={styles.policyListContainer}>
              <div className={styles.policyHeader}>
                <h3 className={styles.policyName}>Name</h3>
              </div>

              <Flex direction='column'>
                {policyList.map((policy) => {
                  const isUnconfigured = !policy.accessConfig?.configured
                  return (
                    <div
                      key={policy.policy_id}
                      className={cx(styles.policyItem, {
                        [styles.selectedPolicy]: policy.policy_name === selectedPolicy
                      })}
                      onClick={() => handlePolicySelect(policy.policy_name)}
                    >
                      <div className={styles.policyItemContent}>
                        <h3 className={styles.policyName}>{policy.file_name}</h3>
                        {isUnconfigured && showValidation && (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#DC2626'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className={styles.alertIcon}
                          >
                            <circle cx='12' cy='12' r='10' />
                            <line x1='12' x2='12' y1='8' y2='12' />
                            <line x1='12' x2='12.01' y1='16' y2='16' />
                          </svg>
                        )}
                      </div>
                      {selectedPolicy === policy.policy_name && (
                        <div className={styles.selectedArrowContainer}>
                          <ChevronRight />
                        </div>
                      )}
                    </div>
                  )
                })}
              </Flex>
            </div>
          </Fragment>
        )}
      </Box>
      <div className={styles.configurationPanel}>
        {currentScreen === 0 && (
          <Fragment>
            <div className={styles.titleContainer}>
              <CompanyPolicyIcon />
              <h2 className={styles.typeHeader}>Policy Type</h2>
            </div>
            <div className={styles.tableWrapper}>
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {policiesList.length === 0 ? (
                    <Fragment>
                      <LoadingRowSingleColumn />
                      <LoadingRowSingleColumn />
                      <LoadingRowSingleColumn />
                      <LoadingRowSingleColumn />
                    </Fragment>
                  ) : (
                    policiesList.map((policy) => (
                      <Table.Row key={policy.id}>
                        <Table.Cell>
                          {editingPolicyId === policy.id ? (
                            <Flex direction='column' gap='4px'>
                              <Flex align='center' gap='8px'>
                                <TextField.Root
                                  size='1'
                                  className={styles.editableCell}
                                  value={editingName}
                                  onChange={handleNameChange}
                                  onBlur={() => {
                                    if (!validatePolicyName(editingName.trim())) {
                                      return
                                    }
                                    handleUpdatePolicyName()
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                      if (!validatePolicyName(editingName.trim())) {
                                        return
                                      }
                                      handleUpdatePolicyName()
                                    }
                                  }}
                                  autoFocus
                                />
                                {updatePolicyResult.isLoading && policy.id === editingPolicyId && <LoadingSpinner />}
                              </Flex>
                              {editingError && <p className={styles.errorText}>{editingError}</p>}
                            </Flex>
                          ) : (
                            <Flex
                              className={styles.editCell}
                              align='center'
                              gap='6px'
                              onClick={() => handleStartEdit(policy)}
                            >
                              {policy.name}
                              <EditIcon className={styles.editIcon} />
                            </Flex>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table.Root>
            </div>
          </Fragment>
        )}
        {currentScreen === 1 && (
          <Fragment>
            <div className={styles.titleContainer}>
              <CompanyPolicyIcon />
              <h2 className={styles.typeHeader}>Policy List</h2>
            </div>
            <div className={styles.tableWrapper}>
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Policy Type</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell isOption />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {policyList.length === 0 ? (
                    <Fragment>
                      <LoadingRowDoubleColumn />
                      <LoadingRowDoubleColumn />
                      <LoadingRowDoubleColumn />
                      <LoadingRowDoubleColumn />
                    </Fragment>
                  ) : (
                    policyList.map((policy) => (
                      <Table.Row key={policy.file_id}>
                        <Table.Cell>{policy.file_name}</Table.Cell>
                        <Table.Cell>
                          <div className={styles.policyTypeCell}>
                            <Dropdown.Root>
                              <Dropdown.Trigger asChild>
                                <p
                                  className={cx(styles.tableDropdown, {
                                    [styles.tableDropdownError]: dropdownErrors[policy.file_id]
                                  })}
                                >
                                  {policy.policy_name || 'Select Type'} <ChevronDown />
                                </p>
                              </Dropdown.Trigger>
                              <Dropdown.Content className={styles.dropdownMenu}>
                                {policyTypeOptions.every((p) => p.hidden) && (
                                  <Dropdown.Item disabled>No policy types available</Dropdown.Item>
                                )}
                                {policyTypeOptions.map((type) => (
                                  <Dropdown.Item
                                    key={type.id}
                                    onClick={() => {
                                      handlePolicyTypeChange(policy.file_id, type)
                                      setDropdownErrors((prev) => ({
                                        ...prev,
                                        [policy.file_id]: false
                                      }))
                                    }}
                                    style={{ display: type.hidden ? 'none' : 'block' }}
                                  >
                                    {type.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Content>
                            </Dropdown.Root>
                            {dropdownErrors[policy.file_id] && (
                              <p className={styles.dropdownError}>Please select a policy type</p>
                            )}
                          </div>
                        </Table.Cell>
                        <TableOptions>
                          <TableOption onClick={() => handleRemovePolicy(policy.file_id, policy.policy_name)}>
                            Delete
                          </TableOption>
                        </TableOptions>
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table.Root>
            </div>
          </Fragment>
        )}
        {currentScreen === 2 && (
          <Fragment>
            {policyList.map((policy) => {
              if (policy.policy_name !== selectedPolicy) return null

              return (
                <div key={policy.policy_id} className={styles.accessWrapper}>
                  <div className={styles.titleContainer}>
                    <CompanyPolicyIcon />
                    <h2 className={styles.typeHeader}>Who can Access?</h2>
                  </div>
                  <AccessControl
                    showTicketCreation={false}
                    app={{
                      id: policy.policy_id,
                      name: policy.policy_name,
                      accessConfig: policy.accessConfig
                    }}
                    appName='Policy'
                    onAccessConfigChange={(config) => {
                      setValidationError(false)
                      setPolicyList((prevList) =>
                        prevList.map((p) =>
                          p.policy_id === policy.policy_id
                            ? {
                                ...p,
                                accessConfig: {
                                  isFullAccess: config.isFullAccess,
                                  conditions: config.conditions,
                                  configured: config.isFullAccess || config.conditions.length > 0
                                }
                              }
                            : p
                        )
                      )
                    }}
                    accessControlData={accessControlData}
                    ticketCreationData={createTicketData}
                  />
                </div>
              )
            })}
          </Fragment>
        )}
      </div>
    </Flex>
  )
}

export default ConfigModule
