import { Flex, Pagination, Table } from '@happyfoxinc/web-components'
import { useEffect, useMemo, useState } from 'react'
import { usePagination, useTable } from 'react-table'

import styles from './Notion.module.css'

import { useGetNotionSyncedPagesQuery } from 'Src/servicesV3/notionApi'
import debounce from 'Src/utils/debounce'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { NotionPagesSkeleton } from './NotionSkeleton'

const PAGE_SIZE = 15
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const getInitialApiQueryParams = (currentWorkspaceId) => {
  return {
    ...defaultApiParams,
    workspace_id: currentWorkspaceId
  }
}

const NotionPages = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [queryParams, setQueryParams] = useState(getInitialApiQueryParams(currentWorkspaceId))

  const { data = {}, isLoading } = useGetNotionSyncedPagesQuery(queryParams)
  const { results: pages = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Pages',
        accessor: 'title',
        disableSortBy: true
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: pages,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {
      workspace_id: currentWorkspaceId
    }

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams, currentWorkspaceId])

  if (isLoading) {
    return <NotionPagesSkeleton />
  }

  return (
    <div>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <h2 className={styles.heading}>Pages currently syncing from</h2>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Header>
        {pages?.length === 0 ? (
          <div className={styles.emptyState}>Currently not syncing any pages. Select pages to start syncing</div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.Cell key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table.Root>
    </div>
  )
}

export default NotionPages
