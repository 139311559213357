import { Button } from '@happyfoxinc/web-components'

import styles from './SyncDisabledAlert.module.css'

const SyncDisabledAlert = ({ title = '', message = '', onEnableSync }) => {
  const messageForDisplay = message || `${title} sync is disabled`

  return (
    <div className={styles.warningBanner}>
      <div className={styles.warningContent}>
        <div className={styles.warningMessage}>{messageForDisplay}</div>
        <div className={styles.warningActions}>
          <Button type='submit' variant='primary' onClick={onEnableSync} className={styles.warningButton}>
            Enable sync
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SyncDisabledAlert
