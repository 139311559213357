import { Button } from '@happyfoxinc/web-components'
import { memo, useId, useState } from 'react'

import styles from './Components.module.css'

import ApprovalProcessIcon from 'Src/assetsv3/icons/approval-process.svg'
import SendInstructionIcon from 'Src/assetsv3/icons/send-instruction.svg'

const STEP_TYPE_ICONS = {
  send_instruction: <SendInstructionIcon />,
  approval_process: <ApprovalProcessIcon />
}

const AddStepButton = memo(({ position, metadata, onAddStep }) => {
  const buttonId = useId()

  const [showDropdown, setShowDropdown] = useState({ top: false, bottom: false })

  const dropdownOptions = metadata
    .filter((step) => step.name !== 'access_provision')
    .map((step) => ({
      ...step,
      icon: STEP_TYPE_ICONS[step.name]
    }))

  const handleAddStep = (e, step, position) => {
    if (!step) return
    onAddStep({
      stepName: step.name,
      position
    })
    setShowDropdown({ [position]: false })
    e.stopPropagation()
  }

  return (
    <div className={styles.addStepWrapper} id={buttonId}>
      {showDropdown[position] && (
        <div className={styles.stepDropdown}>
          {dropdownOptions.map((step) => (
            <Button
              type='button'
              key={step.name}
              variant='outline'
              className={styles.dropdownItem}
              onClick={(e) => {
                handleAddStep(e, step, position)
              }}
            >
              <div className={styles.dropdownItemContent}>
                {step.icon}
                <span className={styles.dropdownItemText}>{step.label}</span>
              </div>
              <span className={styles.dropdownItemArrow}>›</span>
            </Button>
          ))}
        </div>
      )}

      <Button
        type='button'
        variant='outline'
        radius='full'
        className={styles.addStepButton}
        onClick={() => setShowDropdown((prev) => ({ ...prev, [position]: true }))}
        onBlur={() =>
          setTimeout(() => {
            setShowDropdown((prev) => ({ ...prev, [position]: false }))
          }, 100)
        }
      >
        +
      </Button>
    </div>
  )
})

AddStepButton.displayName = 'AddStepButton'

export default AddStepButton
