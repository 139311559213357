import { Box, Button, Flex, TextField } from '@happyfoxinc/web-components'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import styles from './UserGroupForm.module.css'

import { ACCOUNT_TYPE } from 'Constants/account'
import { APP_NOT_INSTALLED_MSG, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { userGroupsApi } from 'Src/servicesV3/userGroupsApi'
import parseErrorMessage from 'Utils/error-message-parser'

import AccessConditionForm from '../AccessControl/AccessControl'
import DeleteModal from '../DeleteUserGroupModal'
import SyncedUsersStatus from '../SyncedUsersStatus'
import { getUserIdKey } from '../usergroups-helper'
import AddedFromField from './AddedFromField'

const customSelectStyles = {
  dropdownIndicator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'linear-gradient(270deg, rgba(245, 245, 247, 1) 0%, rgba(248, 248, 248, 1) 100%);',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-md)',
    padding: '2px',
    margin: '2px'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: '0',
    color: 'var(--color-text-default)',
    fontSize: 'var(--font-size-xs)'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: 'transparent',
      color: '#dc2626'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    alignItems: 'baseline',
    paddingTop: '6px',
    minHeight: '60px',
    backgroundColor: 'var(--color-surface-default)',
    border: state?.isFocused
      ? '#3b82f6'
      : state?.selectProps?.error
      ? '#dc2626'
      : '1px solid var(--color-border-default)',
    boxShadow: state?.isFocused ? '0 0 0 1px #3b82f6' : 'none',
    '&:hover': {
      borderColor: state?.isFocused ? '#3b82f6' : '#cbd5e1'
    }
  })
}

const UserGroupForm = ({ userGroup = {}, isEdit = false, isLoading = false, onSubmit, isRefreshing, onRefresh }) => {
  const { sync_from_group: syncedFromGroup } = userGroup
  const { MANUAL, CONDITIONAL, SYNCED_FROM_APP } = USER_MANAGEMENT_TYPE
  const { data: account } = useGetAccountQuery()
  const accountType = account.account_type
  const isMsTeamsAccountType = accountType === ACCOUNT_TYPE.MS_TEAMS

  const navigate = useNavigate()

  const [getUsers, getUsersResult] = userGroupsApi.useLazyGetUsersQuery()

  const {
    register,
    control,
    formState: {
      errors,
      dirtyFields: { addedFrom: isAddedFromEdited, syncedFromGroup: isSyncedFromGroupEdited }
    },
    watch,
    setValue
  } = useFormContext()
  const addedFromField = watch('addedFrom')
  const isUserManuallyAdded = addedFromField?.value === MANUAL
  const isUserAddedConditionally = addedFromField?.value === CONDITIONAL

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const loadOptions = (inputValue, cb) => {
    const query = {
      name: inputValue,
      accountType
    }
    getUsers(query)
      .unwrap()
      .then((data) => {
        const options = data.map(({ is_app_installed, ...rest }) => {
          const disabled = isMsTeamsAccountType ? !is_app_installed : false
          return {
            disabled,
            ...rest
          }
        })
        cb(options)
      })
  }

  const [getAppUserGroups, getAppUserGroupsResult] = userGroupsApi.useLazyGetAppUserGroupsQuery()
  const loadAppUserGroupOptions = (inputValue, cb) => {
    getAppUserGroups({ search: inputValue, appName: addedFromField.value })
      .unwrap()
      .then((data) => {
        cb(data)
      })
  }

  const [reSyncUserGroup] = userGroupsApi.useReSyncUserGroupMutation()
  const handleReSync = () => {
    const { id } = userGroup
    const payload = { id }
    const promise = reSyncUserGroup(payload).unwrap()

    toast.promise(promise, {
      loading: `Starting the User Group Re-sync`,
      success: `Started the User Group Re-sync`,
      error: parseErrorMessage(`Unable to Re-sync the User Group. Try again...`)
    })
  }
  const isSyncedFromApp = userGroup.user_management_type === SYNCED_FROM_APP

  const appUserGroupDefaultOptions = syncedFromGroup ? [syncedFromGroup] : []
  const handleAddedFromChange = () => {
    setValue('syncedFromGroup', null, { shouldDirty: true })
    setValue('users', [])
    setValue('conditions', [])
  }

  const getUsersFieldOptionValue = (opt) => {
    const idKey = getUserIdKey(accountType)
    return opt[idKey]
  }

  const getUsersFieldOptionLabel = (opt) => {
    if (opt.disabled) {
      return `${opt.name} (${APP_NOT_INSTALLED_MSG})`
    }
    return opt.name
  }

  const onCancel = () => {
    setIsDeleteModalOpen(false)
  }

  return (
    <form onSubmit={onSubmit}>
      <FormField>
        <FormField.Field label='Group Name' error={errors?.name?.message}>
          <Box width='330px'>
            <TextField.Root {...register('name')} size='2' radius='small' placeholder='Enter Group Name' />
          </Box>
        </FormField.Field>
      </FormField>
      <FormField>
        <Box width='330px'>
          <AddedFromField onChange={handleAddedFromChange} />
        </Box>
      </FormField>
      {addedFromField && (
        <FormField>
          <Box width='600px'>
            {!isUserManuallyAdded && !isUserAddedConditionally && (
              <FormField.Field label='Select Group' error={errors?.syncedFromGroup?.message}>
                <Controller
                  name='syncedFromGroup'
                  control={control}
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder='Search groups'
                        defaultOptions={appUserGroupDefaultOptions}
                        minCharsBeforeLoadingOptions={3}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        loadOptions={loadAppUserGroupOptions}
                        isLoading={getAppUserGroupsResult.isLoading}
                        loadingMessage={() => 'Searching for groups...'}
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 3) {
                            return 'Type alteast 3 characters to start searching'
                          }
                          return `No groups found for input "${inputValue}"`
                        }}
                      />
                    )
                  }}
                />
              </FormField.Field>
            )}
            {isUserAddedConditionally && (
              <div className={styles.conditionField}>
                <AccessConditionForm />
              </div>
            )}
            {isUserManuallyAdded && (
              <FormField.Field label='Users' error={errors?.users?.message}>
                <Controller
                  control={control}
                  name='users'
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder='Search users'
                        isClearable={false}
                        isMulti
                        isInvalid={Boolean(errors.users)}
                        options={[]}
                        getOptionLabel={getUsersFieldOptionLabel}
                        getOptionValue={getUsersFieldOptionValue}
                        loadOptions={loadOptions}
                        isOptionDisabled={(opt) => opt.disabled}
                        isLoading={getUsersResult.isLoading}
                        loadingMessage={() => 'Searching for users...'}
                        minCharsBeforeLoadingOptions={2}
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 2) {
                            return 'Type alteast 2 characters to start searching'
                          }
                          return `No users found for input "${inputValue}"`
                        }}
                        styles={customSelectStyles}
                      />
                    )
                  }}
                />
              </FormField.Field>
            )}
          </Box>
        </FormField>
      )}
      {isSyncedFromApp && !isAddedFromEdited && !isSyncedFromGroupEdited && (
        <SyncedUsersStatus
          userGroup={userGroup}
          onReSync={handleReSync}
          isRefreshing={isRefreshing}
          onRefresh={onRefresh}
        />
      )}
      <Flex gap='12px' mt='24px' className={styles.buttonContainer}>
        <Flex gap='12px' mt='24px'>
          <Button type='submit' disabled={isLoading}>
            Save
          </Button>
          <Button type='button' variant='outline' onClick={() => navigate('../')}>
            Cancel
          </Button>
        </Flex>
        {isEdit && (
          <Button type='button' className={styles.deleteButton} onClick={() => setIsDeleteModalOpen(true)}>
            Delete
          </Button>
        )}
      </Flex>
      <DeleteModal
        open={isDeleteModalOpen}
        onCancel={onCancel}
        onSuccess={() => {
          setIsDeleteModalOpen(false)
          navigate('../')
        }}
        onOpenChange={onCancel}
        id={userGroup?.id}
        data={userGroup}
        title='Delete User Group'
        showFooter={false}
        bodyClassName={styles.modalBody}
      />
    </form>
  )
}

export default UserGroupForm
