import { Button, Flex, Pagination, Table } from '@happyfoxinc/web-components'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { usePagination, useTable } from 'react-table'

import styles from './Simpplr.module.css'

import ExternalLinkIcon from 'Icons/external-link.svg'

import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { simpplrApi, useAddSimpplrSiteMutation, useGetSimpplrSitesQuery } from 'Src/servicesV3/simpplrApi'
import debounce from 'Src/utils/debounce'

import { SimpplrSitesSkeleton } from './SimpplrSkeleton'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const SitesList = () => {
  const [queryParams, setQueryParams] = useState(defaultApiParams)

  const { data = {}, isLoading } = useGetSimpplrSitesQuery(queryParams)
  const { results: sites = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Site Name',
        accessor: (originalRow) => originalRow,
        disableSortBy: true,
        Cell: ({ cell: { value: site } }) => {
          return (
            <a href={site.site_url} target='_blank' className={styles.SiteLink} rel='noreferrer'>
              {site.site_name} <ExternalLinkIcon width='1em' height='1em' />
            </a>
          )
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: sites,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {}

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  if (isLoading) {
    return <SimpplrSitesSkeleton />
  }

  return (
    <Fragment>
      <h2 className={styles.heading}>Sites currently syncing from</h2>
      <Flex justify='flex-end' className={styles.tableActionContainer}>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={PAGE_SIZE}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Header>
        {sites.length === 0 ? (
          <div className={styles.emptyState}>Currently not syncing any sites. Add sites to start syncing</div>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              const { key, ...restRowProps } = row.getRowProps()
              return (
                <Table.Row key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps()
                    return (
                      <Table.BodyCell key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </Table.BodyCell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        )}
      </Table.Root>
    </Fragment>
  )
}

const SimpplrSites = () => {
  const [searchSites, { isLoading: searchSitesLoading }] = simpplrApi.useLazySearchSimpplrSitesQuery()
  const [addSite, { isLoading: addSiteLoading }] = useAddSimpplrSiteMutation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      site: null
    }
  })

  const handleSiteAddition = async (data) => {
    const { site } = data
    try {
      await addSite(site.site_id)
      reset()
    } catch (error) {}
  }

  const loadOptions = async (inputValue, cb) => {
    return searchSites({ site_name: inputValue }).unwrap().then(cb)
  }

  return (
    <div>
      <h2 className={styles.heading}>Add a Simpplr site to sync from</h2>
      <form onSubmit={handleSubmit(handleSiteAddition)} className={styles.AddSiteForm}>
        <FormField>
          <FormField.Field error={errors.site?.message}>
            <Controller
              control={control}
              name='site'
              rules={{ required: 'Search and select a site to add' }}
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    className={styles.SearchSite}
                    minCharsBeforeLoadingOptions={3}
                    loadOptions={loadOptions}
                    getOptionLabel={(option) => option.Title}
                    getOptionValue={(option) => option.Path}
                    isLoading={searchSitesLoading}
                    loadingMessage={({ inputValue }) => {
                      return `Searching for sites matching "${inputValue}"...`
                    }}
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue.length < 3) {
                        return 'Type alteast 3 characters to start searching'
                      }

                      return `No sites found for input "${inputValue}"`
                    }}
                  />
                )
              }}
            />
            <Button variant='outline' type='submit' disabled={addSiteLoading}>
              + Add
            </Button>
          </FormField.Field>
        </FormField>
      </form>
      <SitesList />
    </div>
  )
}

export default SimpplrSites
