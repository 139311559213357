import React from 'react';
import styles from './ReportsLoader.module.css';

const ReportsLoader = () => {
  return (
    <div className={styles.reportsLoader}>
      <div className={styles.titleLoader}>
        <div className={styles.skeletonLoading} style={{ width: '300px', height: '24px' }} />
      </div>
      
      <div className={styles.metricsContainer}>
        {Array(3).fill(null).map((_, index) => (
          <div key={`metric-${index}`} className={styles.metricBox}>
            <div className={styles.skeletonLoading} style={{ width: '200px', height: '60px' }} />
          </div>
        ))}
      </div>

      <div className={styles.questionsContainer}>
        {Array(3).fill(null).map((_, index) => (
          <div key={`question-${index}`} className={styles.questionBlock}>
            <div className={styles.skeletonLoading} style={{ width: '100%', height: '200px' }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsLoader;
