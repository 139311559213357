import { Fragment } from 'react'

import styles from './ContentOutline.module.css'

const ContentOutline = ({ title, children }) => {
  return (
    <Fragment>
      <p className={styles.title}>{title}</p>
      <div className={styles.outline}>{children}</div>
    </Fragment>
  )
}

export default ContentOutline
