import { useLocation, useNavigate } from 'react-router-dom'

import styles from './CategoryTabs.module.css'

import { URLS } from 'Src/constants/urls'
import { openUrl } from 'Src/utils/url'
import { usePlanContext } from 'Src/utilsV3/hooks/usePlanContext'

import { APP_CATEGORY } from '../../constants/appTypes'
import { useAppsData } from '../../hooks/useAppsData'

const CategoryLabels = {
  [APP_CATEGORY.ALL]: 'All Apps',
  [APP_CATEGORY.KNOWLEDGE_SOURCES]: 'Knowledge Sources',
  [APP_CATEGORY.TICKETING_SYSTEM]: 'Ticketing Systems',
  [APP_CATEGORY.IDENTITY_MANAGEMENT]: 'Identity Management'
}

export const CategoryTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathSegments = location.pathname.split('/')
  const currentCategory = pathSegments[pathSegments.length - 1] || APP_CATEGORY.ALL

  const handleCategoryClick = (category) => {
    navigate(`/apps/category/${category}`)
  }

  return (
    <div className={styles.tabContainer}>
      <nav className={styles.categoryNav}>
        {Object.values(APP_CATEGORY).map((category) => (
          <button
            key={category}
            className={`${styles.categoryTab} ${currentCategory === category ? styles.activeTab : ''}`}
            onClick={() => handleCategoryClick(category)}
          >
            <span>{CategoryLabels[category]}</span>
            {currentCategory === category && <span className={styles.arrow}>›</span>}
          </button>
        ))}
      </nav>

      <div className={styles.planInfoContainer}>
        <PlanInfo />
      </div>
    </div>
  )
}

const PlanInfo = () => {
  const { planName, integrationsLimit, isFreePlan, isProPlan, isEnterprisePlan } = usePlanContext()
  const { apps, isLoading } = useAppsData()

  const handleUpgradeClick = () => {
    openUrl(URLS.ASSIST_AI)
  }

  if (isEnterprisePlan || isLoading) {
    return null
  }

  const connectedApps = apps
    .filter((app) => app.status === 'linked')
    .filter((app) => app.key !== 'other_ticketing_system')

  if (connectedApps.length >= integrationsLimit) {
    return (
      <div className={styles.planInfo}>
        <p className={styles.planText}>
          You have reached the integrations limit on your {planName} plan.
          <span className={styles.subText}>
            {isFreePlan &&
              'Upgrade now to connect to additional integrations. Premium integrations are available on the Pro and Enterprise plans'}
            {isProPlan && 'Upgrade now to enjoy unlimited integrations with Assist AI'}
          </span>
        </p>
        <button className={styles.upgradeButton} onClick={handleUpgradeClick}>
          Upgrade Plan
        </button>
      </div>
    )
  }

  return (
    <div className={styles.planInfo}>
      <p className={styles.planText}>
        You are currently connected to {connectedApps.length} out of {integrationsLimit} available integrations in your{' '}
        {planName} plan.
        <span className={styles.subText}>You can connect more apps for the complete experience.</span>
      </p>
      <button className={styles.upgradeButton} onClick={handleUpgradeClick}>
        Upgrade Plan
      </button>
    </div>
  )
}
