import baseApi, { TAGS } from './baseApi'

export const oktaApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOktaApp: builder.query({
      query: () => ({
        url: '/v1/apps/okta',
        method: 'GET'
      }),
      providesTags: [TAGS.OKTA.OKTA]
    }),

    installOkta: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/okta',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.OKTA.OKTA, TAGS.APPS]
    }),

    uninstallOkta: builder.mutation({
      query: () => ({
        url: '/v1/apps/okta',
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.OKTA.OKTA, TAGS.APPS]
    }),

    updateQuickActions: builder.mutation({
      query: ({ app_id, ...data }) => ({
        url: `/v1/apps/${app_id}/quick-actions`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.OKTA.OKTA]
    }),

    getAllOktaAIActions: builder.query({
      query: () => ({
        url: '/v1/apps/okta/ai-actions',
        method: 'GET'
      }),
      providesTags: [TAGS.OKTA.OKTA_AI_ACTIONS]
    })
  })
})

export const {
  useGetOktaAppQuery,
  useInstallOktaMutation,
  useUninstallOktaMutation,
  useUpdateQuickActionsMutation,
  useGetAllOktaAIActionsQuery
} = oktaApi
