import { Flex } from '@happyfoxinc/web-components'

import styles from './Components.module.css'

const ProvisioningSkeleton = () => {
  return (
    <div className={styles.workflowContainer}>
      <div className={styles.startIconWrapper} />
      <div className={`${styles.stepLine} ${styles.firstLine}`} />
      <div className={`${styles.skeleton} ${styles.addStepSkeleton}`} />
      <div className={`${styles.stepLine} ${styles.secondLine}`} />

      {[1, 2].map((_, index) => (
        <div key={`skeleton-${index}`} className={styles.stepWrapper}>
          <Flex gap='16px' align='flex-start'>
            <div className={styles.stepNumber} />
            <div className={`${styles.skeleton} ${styles.stepSkeleton}`}>
              <div className={`${styles.skeleton} ${styles.stepHeaderSkeleton}`} />
              <div className={`${styles.skeleton} ${styles.stepContentSkeleton}`} />
            </div>
          </Flex>
        </div>
      ))}

      <div className={`${styles.skeleton} ${styles.addStepSkeleton}`} />
    </div>
  )
}

export default ProvisioningSkeleton
