import cx from 'classnames'

import styles from './MetricCards.module.css'

import DownloadIcon from 'Icons/download.svg'

import ProgressBar from 'Src/componentsv3/ProgressBar'

import ContentList from '../ContentList'
import ContentListWithCount from '../ContentListWithCount'
import ContentOutline from '../ContentOutline'
import Tile from '../Tile'
import MetricTable from './MetricTable'

const BigNumber = ({ value, isPercent = false }) => {
  return (
    <div className={styles.bigTextContainer}>
      <div className={styles.bigNumber}>
        {value}
        {isPercent && `%`}
      </div>
      {isPercent && (
        <div className={styles.progressBar}>
          <ProgressBar percent={value} />
        </div>
      )}
    </div>
  )
}

const DownloadButton = ({ downloadUrl }) => (
  <div className={styles.actionRow}>
    <a className={styles.downloadBtn} href={downloadUrl} download>
      Download CSV
      <DownloadIcon className={styles.downloadIcon} />
    </a>
  </div>
)

const ContentListContainer = ({ list, withCount, downloadUrl, classNames }) => (
  <div className={cx(styles.contentListContainer, classNames?.contentListContainer)}>
    {withCount ? <ContentListWithCount list={list} /> : <ContentList list={list} />}
    {downloadUrl && <DownloadButton downloadUrl={downloadUrl} />}
  </div>
)

const MetricTile = ({
  title,
  info,
  icon,
  summary,
  primaryCount,
  othersCount,
  totalCount,
  othersDisabled,
  othersNotApplicable,
  list,
  downloadUrl,
  isPercent,
  classNames,
  withCount = false
}) => (
  <div className={cx(styles.metricTile, classNames?.metricTileContainer)}>
    <Tile title={title} info={info} icon={icon}>
      {list ? (
        <ContentListContainer list={list} withCount={withCount} downloadUrl={downloadUrl} classNames={classNames} />
      ) : (
        <ContentOutline title={summary}>
          {totalCount !== undefined ? (
            <MetricTable
              primaryCount={primaryCount}
              othersCount={othersCount}
              totalCount={totalCount}
              othersDisabled={othersDisabled}
              othersNotApplicable={othersNotApplicable}
            />
          ) : (
            <BigNumber value={primaryCount} isPercent={isPercent} />
          )}
        </ContentOutline>
      )}
    </Tile>
  </div>
)

export default MetricTile
