import { Box, Button, TextField } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import styles from './InstallScreen.module.css'

import FormField from 'Src/componentsv3/FormField'

const InstallForm = ({ appId, schema, fields, onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field) => (
        <FormField key={field.name}>
          <FormField.Field error={errors[field?.name]?.message} label={field.label}>
            <Box width='450px'>
              <TextField.Root
                {...register(field.name)}
                type={field.type}
                size='2'
                radius='small'
                placeholder={field.placeholder}
              />
            </Box>
          </FormField.Field>
        </FormField>
      ))}

      <Button type='submit' size='small' radius='medium' className={styles.submitButton} disabled={isLoading}>
        {isLoading ? 'Installing...' : 'Install'}
      </Button>
    </form>
  )
}

export default InstallForm
