import { baseApi, TAGS } from './baseApi'

export const campaignApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query({
      query: (params) => ({
        url: 'v1/campaigns',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.CAMPAIGNS.LIST]
    }),

    getCampaign: builder.query({
      query: (id) => `v1/campaigns/${id}`,
      providesTags: (id) => [{ type: TAGS.CAMPAIGNS.DETAILS, id }]
    }),

    createCampaign: builder.mutation({
      query: (data) => ({
        url: 'v1/campaigns',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.CAMPAIGNS.LIST]
    }),

    updateCampaign: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/campaigns/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ({ id }) => [TAGS.CAMPAIGNS.LIST, { type: TAGS.CAMPAIGNS.DETAILS, id }]
    }),

    updateCampaignStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `v1/campaigns/${id}/update-status`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ({ id }) => [TAGS.CAMPAIGNS.LIST, { type: TAGS.CAMPAIGNS.DETAILS, id }]
    }),

    deleteCampaign: builder.mutation({
      query: (id) => ({
        url: `v1/campaigns/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.CAMPAIGNS.LIST]
    })
  })
})

export const {
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  useUpdateCampaignStatusMutation,
  useDeleteCampaignMutation
} = campaignApi

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyReports: builder.query({
      query: ({ survey_id, ...params }) => ({
        url: `v1/surveys/${survey_id}/responses`,
        method: 'GET',
        params
      })
    }),

    exportSurveyReports: builder.mutation({
      query: ({ survey_id, ...params }) => ({
        url: `v1/surveys/${survey_id}/responses/export`,
        method: 'GET',
        params
      })
    }),

    exportQuestionResponses: builder.mutation({
      query: ({ survey_id, question_id, ...params }) => ({
        url: `v1/surveys/${survey_id}/questions/${question_id}/export`,
        method: 'GET',
        params
      })
    })
  })
})

export const { useGetSurveyReportsQuery, useExportSurveyReportsMutation, useExportQuestionResponsesMutation } =
  reportsApi
