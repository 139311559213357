import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import toast from 'react-hot-toast'

import styles from './Outline.module.css'

import { CONFIRM_MESSAGES } from 'Src/constants/messages'
import AppFooter from 'Src/pagesv3/Apps/components/AppFooter'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import {
  useGetOutlineAppQuery,
  useGetSyncedCollectionsQuery,
  useUninstallOutlineMutation
} from 'Src/servicesV3/outlineApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import AddCollections from './AddCollections'
import CollectionsConfig from './CollectionsConfig'
import OutlineSkeleton from './OutlineSkeleton'

const Outline = () => {
  const { currentWorkspaceId } = useWorkspace()

  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { data: app, isLoading } = useGetOutlineAppQuery({ workspace_id: currentWorkspaceId })
  const { data: syncedCollectionsApiResponse = {} } = useGetSyncedCollectionsQuery({ workspace_id: currentWorkspaceId })
  const { results: syncedCollections = [] } = syncedCollectionsApiResponse
  const [uninstallApp, { isLoading: isUninstalling }] = useUninstallOutlineMutation()

  const handleUninstall = async () => {
    const promise = uninstallApp({ workspace_id: currentWorkspaceId })
    promise.then(() => {
      setIsUninstallModalOpen(false)
    })

    toast.promise(promise, {
      loading: 'Uninstalling...',
      success: 'Outline uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.error || `Failed to uninstall Helpdesk. Try again...`
        return errorMessage
      }
    })
  }

  if (isLoading) {
    return <OutlineSkeleton />
  }

  const hasSyncedCollections = syncedCollections?.length > 0

  return (
    <Flex width='100%' gap='25px' direction='column' className={styles.wrapper}>
      <AppInstalledCard title='Outline workspace' accountUrl={app.account_url} />
      {hasSyncedCollections ? <CollectionsConfig /> : <AddCollections />}
      <AppFooter app={app} isUninstalling={isUninstalling} onUninstall={() => setIsUninstallModalOpen(true)} />

      <Modal
        size='small'
        title='Uninstall Outline'
        open={isUninstallModalOpen}
        onClose={() => setIsUninstallModalOpen(false)}
        onOpenChange={(isOpen) => {
          if (!isOpen) setIsUninstallModalOpen(false)
        }}
        showFooter={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          {CONFIRM_MESSAGES.UNINSTALL_APP}
          <Flex gap='15px'>
            <Button variant='solid' disabled={isUninstalling} onClick={handleUninstall}>
              Uninstall
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Outline
