import { Box, Flex } from '@happyfoxinc/web-components'
import cx from 'classnames'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './Notifications.module.css'

import { Feature } from 'Components/Features'
import { useGetNotificationsQuery, useUpdateNotificationsMutation } from 'Src/servicesV3/settingsApi'

import notificationsConfig from './notifications-config'

const SkeletonLoader = () => {
  return (
    <div className={styles.skeleton}>
      {[1, 2, 3].map((key) => (
        <Flex key={key} align='center' justify='space-between' gap='2rem' className={styles.skeletonItem}>
          <div className={styles.content}>
            <div className={styles.skeletonTitle} />
            <div className={styles.skeletonDescription} />
          </div>
          <div className={styles.skeletonToggle} />
        </Flex>
      ))}
    </div>
  )
}

const NotificationConfig = () => {
  const { data = {}, isLoading } = useGetNotificationsQuery()
  const [updateNotifications, updateNotificationsResult] = useUpdateNotificationsMutation()

  const formMethods = useForm({
    defaultValues: {}
  })

  const { register, reset } = formMethods

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      reset(data)
    }
  }, [data, reset])

  const updateNotificationSettings = useCallback(
    (toggle_name, value) => {
      const promise = updateNotifications({
        ...formMethods.getValues(),
        [toggle_name]: value
      }).unwrap()

      toast.promise(promise, {
        loading: 'Updating notification settings',
        success: 'Successfully updated notification settings',
        error: 'Unable to update notification settings'
      })
    },
    [formMethods, updateNotifications]
  )

  if (isLoading) {
    return <SkeletonLoader />
  }

  return (
    <FormProvider {...formMethods}>
      <div className={cx(styles.configContainer, { [styles.loading]: updateNotificationsResult.isLoading })}>
        {notificationsConfig.map((config) => {
          return (
            <Feature features={config.requiredFeatures} key={`Notification_Config__${config.key}`}>
              <Flex align='center' justify='space-between' gap='2rem' className={styles.config}>
                <div className={styles.content}>
                  <p>{config.title}</p>
                  <p className={styles.muted}>{config.description}</p>
                </div>
                <div className={styles.controls}>
                  {config.type === 'boolean' && (
                    <label className={styles.switch}>
                      <input
                        type='checkbox'
                        {...register(config.key)}
                        onChange={(e) => {
                          updateNotificationSettings(config.key, e.target.checked)
                        }}
                      />
                      <span className={styles.slider} />
                    </label>
                  )}
                </div>
              </Flex>
            </Feature>
          )
        })}
      </div>
    </FormProvider>
  )
}

const Notifications = () => {
  return (
    <Box className={styles.container}>
      <div className={styles.configContainer}>
        <NotificationConfig />
      </div>
    </Box>
  )
}

export default Notifications
