// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ivp5SoSj6z4GG37ba3Uf{display:flex;align-items:center}.ofGXUKFMhtqx54jaXhLh{margin-left:5px}.oDuesrFJVnlauIIeF7Hy{position:absolute;left:8px;top:50%;transform:translateY(-50%);width:16px;height:16px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Reports/Filters/Filters.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,0BAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".dropdownLabel {\n  display: flex;\n  align-items: center;\n}\n\n.optionText {\n  margin-left: 5px;\n}\n\n.icon {\n  position: absolute;\n  left: 8px;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownLabel": "Ivp5SoSj6z4GG37ba3Uf",
	"optionText": "ofGXUKFMhtqx54jaXhLh",
	"icon": "oDuesrFJVnlauIIeF7Hy"
};
export default ___CSS_LOADER_EXPORT___;
