import { Fragment } from 'react'

import styles from './QuestionsLoader.module.css'

const QuestionsLoader = ({ questions = 3 }) => {
  return (
    <Fragment>
      <div className={styles.stepLineTop} />
      <div className={styles.questionsLoader}>
        {Array(questions)
          .fill(null)
          .map((_, index) => (
            <Fragment key={`question-${index}`}>
              <div className={styles.questionBlock}>
                <div className={styles.stepNumber}>
                  <div className={styles.skeletonLoading} style={{ width: '20px', height: '20px' }} />
                </div>
                <div className={styles.questionContent}>
                  <div className={styles.questionHeader}>
                    <div className={styles.headerLeft}>
                      <div className={styles.skeletonLoading} style={{ width: '120px', height: '16px' }} />
                    </div>
                    <div className={styles.headerRight}>
                      <div className={styles.skeletonLoading} style={{ width: '24px', height: '24px' }} />
                    </div>
                  </div>
                  <div className={styles.questionBody}>
                    <div className={styles.skeletonLoading} style={{ width: '80%', height: '20px' }} />
                    <div className={styles.optionsContainer}>
                      {Array(3)
                        .fill(null)
                        .map((_, optionIndex) => (
                          <div key={`option-${optionIndex}`} className={styles.option}>
                            <div className={styles.skeletonLoading} style={{ width: '60%', height: '16px' }} />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {index < questions - 1 && <div className={styles.stepLineBetween} />}
            </Fragment>
          ))}
      </div>
    </Fragment>
  )
}

export default QuestionsLoader
