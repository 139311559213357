import { Flex } from '@happyfoxinc/web-components'
import Select, { components } from 'react-select'

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: '29px',
    height: '29px',
    border: '1px solid var(--color-border-default)',
    borderRadius: 'var(--border-radius-sm)',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid var(--color-border-default)'
    }
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 4px',
    minHeight: '29px',
    height: '29px',
    fontSize: 'var(--text-sm)',
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden'
  }),
  multiValue: (base, { index }) => ({
    ...base,
    margin: '2px',
    backgroundColor: 'var(--color-surface-default)',
    borderRadius: 'var(--border-radius-sm)',
    height: '20px',
    alignItems: 'center',
    border: '1px solid var(--color-border-default)',
    maxWidth: index > 1 ? '50%' : '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: index >= 2 ? 'none' : 'flex'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0 8px',
    height: '22px'
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '0',
    height: '22px'
  }),
  menu: (base) => ({
    ...base,
    marginTop: '2px',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.06)',
    border: '1px solid var(--color-border-default)',
    borderRadius: '6px'
  }),
  indicatorContainer: (base) => ({
    ...base,
    height: '22px'
  })
}

const CustomOption = (props) => {
  const { data, isSelected, isFocused, innerRef, innerProps } = props
  return (
    <Flex
      align='center'
      ref={innerRef}
      {...innerProps}
      style={{
        fontFamily: 'inherit',
        fontSize: 'var(--text-sm)',
        padding: '5px 10px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#f9f9f9' : isFocused ? '#f9f9f9' : 'transparent'
      }}
    >
      <input
        type='checkbox'
        checked={isSelected}
        onChange={() => null}
        style={{ marginRight: '10px', fontFamily: 'inherit', fontSize: '12px' }}
      />
      {data.label}
    </Flex>
  )
}

const CustomMultiValue = ({ index, getValue, ...props }) => {
  const values = getValue()
  if (index >= 2) {
    if (index === 2) {
      return (
        <div
          style={{
            fontSize: '10px',
            padding: '2px 6px',
            background: '#f5f5f5',
            borderRadius: 'var(--border-radius-md)',
            marginLeft: '10px'
          }}
        >
          +{values.length - 2}
        </div>
      )
    }
    return null
  }
  return <components.MultiValue {...props} />
}

const MultiSelect = ({ options, field, placeholder, inDialog = false }) => {
  const selectedOptions = field.value ? options.filter((option) => field.value.includes(option.value)) : []

  const handleChange = (selected) => {
    field.onChange(selected?.map((option) => option.value) || [])
  }

  return (
    <Select
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      value={selectedOptions}
      onChange={handleChange}
      placeholder={placeholder}
      components={{
        Option: CustomOption,
        MultiValue: CustomMultiValue
      }}
      styles={{
        ...customStyles,
        menu: (base) => (inDialog ? { ...base, zIndex: 10 } : base)
      }}
    />
  )
}

export default MultiSelect
