import { Button, CheckboxTree, useCheckboxTree } from '@happyfoxinc/web-components'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import styles from './Notion.module.css'

import ReactSelect from 'Src/componentsv3/ReactSelect'
import { notionApi, useGetAllNotionPagesQuery, useSyncNotionPagesMutation } from 'Src/servicesV3/notionApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import { SyncNotionPagesSkeleton } from './NotionSkeleton'

const SyncNotionPages = () => {
  const { currentWorkspaceId } = useWorkspace()

  const { data: pages = [], isLoading } = useGetAllNotionPagesQuery({ workspace_id: currentWorkspaceId })
  const [searchPage, searchPageResult] = notionApi.useLazySearchNotionPagesQuery()
  const [syncPages, syncPagesResult] = useSyncNotionPagesMutation()

  const [customPages, setCustomPagesPages] = useState([])
  const [selectedSearchPage, setSelectedSearchPage] = useState(null)

  const allPages = useMemo(() => {
    const mergedPages = [...pages, ...customPages]

    return Array.from(
      mergedPages
        .reduce((map, page) => {
          map.set(page.id, page)
          return map
        }, new Map())
        .values()
    )
  }, [pages, customPages])

  const tree = useMemo(() => {
    if (!allPages.length) {
      return []
    }

    return [
      {
        id: 0,
        name: 'Pages',
        children: allPages.map((page) => {
          return {
            ...page,
            name: page.title,
            parentId: 0,
            children: []
          }
        })
      }
    ]
  }, [allPages])

  const { checked: selectedPages, selectNode, state } = useCheckboxTree(tree)

  const clearSelectedPage = () => {
    setSelectedSearchPage(null)
  }

  const handleSearchSelection = (selected) => {
    const selectedValueExists = pages.find((page) => page.id === selected.id)

    if (!selectedValueExists) {
      setCustomPagesPages((previousState) => [...previousState, selected])
    }

    setSelectedSearchPage(selected)
    selectNode(selected.id, true)
    clearSelectedPage()
  }

  const loadOptions = (inputValue, cb) => {
    searchPage({ title: inputValue, workspaceId: currentWorkspaceId }).unwrap().then(cb)
  }

  const handleSync = async () => {
    const pageIdsToSync = selectedPages.filter((pageId) => pageId !== 0)

    const payload = {
      notion_page_ids: pageIdsToSync,
      workspace_id: currentWorkspaceId
    }

    try {
      const promise = syncPages(payload).unwrap()

      toast.promise(promise, {
        loading: 'Syncing selected notion pages',
        success: 'Successfully synced pages',
        error: 'Unable to sync pages'
      })
      await promise
    } catch {}
  }

  if (isLoading) {
    return <SyncNotionPagesSkeleton />
  }

  const disableSyncButton = selectedPages.length === 0 || syncPagesResult.isLoading

  return (
    <div>
      <h2 className={styles.heading}>Select Notion page(s) to sync from</h2>
      <ReactSelect
        value={selectedSearchPage}
        onChange={handleSearchSelection}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.title}
        placeholder='Search for pages in your notion'
        minCharsBeforeLoadingOptions={3}
        isLoading={searchPageResult.isLoading}
        loadingMessage={({ inputValue }) => {
          return `Searching for pages matching "${inputValue}"`
        }}
        noOptionsMessage={({ inputValue }) => {
          if (inputValue.length < 3) {
            return 'Type alteast 3 characters to start searching'
          }

          return `No pages found for input "${inputValue}"`
        }}
      />
      <div>
        <CheckboxTree nodes={tree} checked={selectedPages} selectNode={selectNode} state={state} />
        <div className={styles.SyncButtonContainer}>
          <Button onClick={handleSync} disabled={disableSyncButton}>
            Sync now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SyncNotionPages
