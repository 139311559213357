import { Navigate, Route, Routes } from 'react-router-dom'

import AppDetails from './pages/AppDetails'
import AppsList from './pages/AppsList'

export default function Apps() {
  return (
    <Routes>
      <Route path=':appId/*' element={<AppDetails />} />
      <Route path='category' element={<Navigate to='all' replace />} />
      <Route path='category/:category' element={<AppsList />} />
      <Route path='' element={<Navigate to='category/all' replace />} />
      <Route path='*' element={<Navigate to='category/all' replace />} />
    </Routes>
  )
}
