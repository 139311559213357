import { Box, Flex } from '@happyfoxinc/web-components'

import Skeleton from 'Src/componentsv3/Skeleton'

import styles from './Reports.module.css'

const MetricSkeleton = ({ type = 'default' }) => {
  const width = type === 'wider' ? '100%' : 'calc(50% - 12px)'
  const height = type === 'taller' ? '300px' : '200px'

  return (
    <Box
      style={{
        width,
        height,
        padding: '1.5rem',
        background: 'var(--white)',
        borderRadius: 'var(--border-radius-lg)',
        animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite'
      }}
    >
      <Skeleton width='60%' height='24px' styles={{ marginBottom: '1rem', borderRadius: 'var(--border-radius-lg)' }} />
      <Skeleton width='80%' height='24px' styles={{ marginBottom: '1rem', borderRadius: 'var(--border-radius-lg)' }} />
      <Skeleton width='40%' height='24px' styles={{ borderRadius: 'var(--border-radius-lg)' }} />
    </Box>
  )
}

export const FilterSkeletonLoader = () => {
  return (
    <Flex direction='row' align='flex-start' className={styles.filtersWrapper}>
      <div className={styles.filterContainer}>
        <Skeleton width='230px' height='30px' />
        <Skeleton width='230px' height='30px' />
      </div>
      <div className={styles.exportBtnContainer}>
        <Skeleton width='94px' height='30px' />
      </div>
    </Flex>
  )
}

const SkeletonLoader = () => {
  return (
    <Flex direction='column' align='flex-start' gap='1rem' style={{ width: '100%' }}>
      <div className={styles.tileContainer} style={{ width: '100%' }}>
        <MetricSkeleton type='wider' />
        <MetricSkeleton />
        <MetricSkeleton />
        <MetricSkeleton />
        <MetricSkeleton />
      </div>
    </Flex>
  )
}

export default SkeletonLoader
