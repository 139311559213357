// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dI7vTfB6FEpgtDTZPuvp{width:100%;height:5px;background-color:#f1f1f1;border-radius:var(--border-radius)}.dI7vTfB6FEpgtDTZPuvp .X4kNVIcR4fXdllJnQWlH{background-color:var(--color-accent-default);height:100%;border-radius:var(--border-radius) 0 0 var(--border-radius);width:0;animation:fCTjQdhXZQr7kaImcl5s 1s ease-in}@keyframes fCTjQdhXZQr7kaImcl5s{from{width:0}}", "",{"version":3,"sources":["webpack://./../src/componentsv3/ProgressBar/ProgressBar.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,UAAA,CACA,wBAAA,CACA,kCAAA,CAGF,4CACE,4CAAA,CACA,WAAA,CACA,2DAAA,CACA,OAAA,CACA,yCAAA,CAGF,gCACE,KACE,OAAA,CAAA","sourcesContent":[".progressBar {\n  width: 100%;\n  height: 5px;\n  background-color: #f1f1f1;\n  border-radius: var(--border-radius);\n}\n\n.progressBar .fill {\n  background-color: var(--color-accent-default);\n  height: 100%;\n  border-radius: var(--border-radius) 0 0 var(--border-radius);\n  width: 0;\n  animation: AnimateProgressFill 1s ease-in;\n}\n\n@keyframes AnimateProgressFill {\n  from {\n    width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "dI7vTfB6FEpgtDTZPuvp",
	"fill": "X4kNVIcR4fXdllJnQWlH",
	"AnimateProgressFill": "fCTjQdhXZQr7kaImcl5s"
};
export default ___CSS_LOADER_EXPORT___;
