import { Box } from '@happyfoxinc/web-components'
import cx from 'classnames'

import styles from './FormField.module.css'

const Field = ({ label, error, children, isRequired = false, className = '' }) => {
  return (
    <Box className={` ${className}`}>
      {label && <p className={cx([styles.label], isRequired ? styles.required : '')}>{label}</p>}
      {children}
      {error && <span className={styles.error}>{error}</span>}
    </Box>
  )
}

export default Field
