import { baseApi, TAGS } from './baseApi'

export const guruApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGuru: build.query({
      query: () => ({
        url: '/v1/apps/guru',
        method: 'GET'
      }),
      providesTags: [TAGS.GURU]
    }),

    installGuru: build.mutation({
      query: (data) => ({
        url: '/v1/apps/guru',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.GURU, TAGS.APPS, TAGS.ACCOUNT]
    }),

    uninstallGuru: build.mutation({
      query: () => ({
        url: '/v1/apps/guru',
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.GURU, TAGS.APPS, TAGS.ACCOUNT]
    }),

    enableSyncGuru: build.mutation({
      query: () => ({
        url: '/v1/apps/guru/sync/enable',
        method: 'POST'
      }),
      invalidatesTags: [TAGS.GURU]
    })
  })
})

export const { useGetGuruQuery, useInstallGuruMutation, useUninstallGuruMutation, useEnableSyncGuruMutation } = guruApi
