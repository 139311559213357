import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import styles from './Components.module.css'

import InfoIcon from 'Src/assetsv3/icons/info.svg'

const InfoTooltip = ({ message }) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={150}>
        <TooltipPrimitive.Trigger type='button' onClick={(e) => e.stopPropagation()} className={styles.infoToolTip}>
          <InfoIcon className={styles.info} />
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content className={styles.tooltipContent} sideOffset={5}>
            <span variant='muted'>{message}</span>
            <TooltipPrimitive.Arrow className={styles.tooltipArrow} />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default InfoTooltip
