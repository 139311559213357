import { Box, Flex, Grid } from '@happyfoxinc/web-components'
import _ from 'lodash'
import { Controller, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import styles from './TicketCreation.module.css'

import ChevronRight from 'Src/assetsv3/icons/chevron-full-right.svg'
import TicketIcon from 'Src/assetsv3/icons/ticket.svg'
import FormField from 'Src/componentsv3/FormField'
import MergeInput from 'Src/componentsv3/MergeInput'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import { SOFTWARE_ACCESS_MERGE_FIELDS } from 'Src/constants/mergeFields'
import { useGetHelpdeskMetaInfoQuery } from 'Src/servicesV3/helpdeskAppApi'
import { useGetModulesQuery } from 'Src/servicesV3/modulesApi'

const SkeletonLoading = () => {
  return (
    <div className={styles.ticketCreationContainer}>
      <Flex align='center' justify='space-between' p='8px 12px'>
        <div className={styles.ticketHeaderLeft}>
          <div className={styles.skeletonIcon} />
          <div className={styles.skeletonText} style={{ width: '80px' }} />
          <div className={styles.skeletonText} style={{ width: '120px' }} />
          <div className={styles.skeletonIcon} />
        </div>
        <div className={styles.skeletonToggle} />
      </Flex>
    </div>
  )
}

const TicketCreation = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext()

  const navigate = useNavigate()

  const isTicketCreationEnabled = watch('isTicketCreationEnabled')

  const { data: modulesResponse = [], isLoading: isModulesLoading } = useGetModulesQuery()
  const { data: ticketingAppMeta = {}, isLoading: isHelpdeskLoading } = useGetHelpdeskMetaInfoQuery(
    { workspace_id: modulesResponse.find((m) => m.name === 'software-access')?.workspace?.id },
    {
      skip: isModulesLoading || !modulesResponse.length
    }
  )

  if (isModulesLoading || isHelpdeskLoading) {
    return <SkeletonLoading />
  }

  const categoryOptions =
    ticketingAppMeta?.all_categories?.map((cat) => ({
      value: cat.id.toString(),
      label: cat.name
    })) || []

  const solvedStatusOptions =
    ticketingAppMeta?.solved_statuses?.map((status) => ({
      value: status.id.toString(),
      label: status.name
    })) || []

  return (
    <>
      <div className={styles.ticketCreationContainer}>
        <Flex
          align='center'
          justify='space-between'
          p='8px 12px'
          onClick={() => setValue('isTicketCreationEnabled', !isTicketCreationEnabled)}
        >
          <div className={styles.ticketHeaderLeft}>
            <ChevronRight className={`${styles.chevron} ${isTicketCreationEnabled ? styles.expanded : ''}`} />
            <TicketIcon />
            <p className={styles.ticketHeading}>Create Ticket</p>
            <p className={styles.ticketSubHeading}>for this software request</p>
          </div>
          <div className={styles.ticketHeaderRight}>
            <label className={styles.switch} htmlFor='isTicketCreationEnabled'>
              <Controller
                name='isTicketCreationEnabled'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <input type='checkbox' checked={value} value={value} onChange={onChange} />
                )}
              />
              <span className={styles.slider} />
            </label>
          </div>
        </Flex>
        {!_.isEmpty(ticketingAppMeta) && isTicketCreationEnabled && (
          <div className={`${styles.ticketContent} ${isTicketCreationEnabled ? styles.expanded : ''}`}>
            <Grid columns='1fr 1fr' gap='16px'>
              <Flex direction='column' gap='6px'>
                <label className={styles.formLabel}>Select Category</label>
                <Box width='100%'>
                  <Controller
                    name='category'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ReactSelect
                          isModalOpen
                          value={categoryOptions.find((option) => option.value === value) || null}
                          options={categoryOptions}
                          onChange={(option) => onChange(option.value)}
                          placeholder='Please select category'
                          className={styles.reactSelect}
                        />
                      )
                    }}
                  />
                  {errors?.category && <span className={styles.errorText}>{errors.category.message}</span>}
                </Box>
              </Flex>
              <Flex direction='column' gap='6px'>
                <label className={styles.formLabel}>Ticket Status</label>
                <Box width='100%'>
                  <Controller
                    name='solvedStatus'
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ReactSelect
                          value={solvedStatusOptions.find((option) => option.value === value) || null}
                          options={solvedStatusOptions}
                          onChange={(option) => onChange(option.value)}
                          placeholder='Please select'
                          className={styles.reactSelect}
                        />
                      )
                    }}
                  />
                  {errors?.solvedStatus && <span className={styles.errorText}>{errors.solvedStatus.message}</span>}
                </Box>
              </Flex>
            </Grid>
            <Flex direction='column' gap='6px' className={styles.ticketSubjectContainer}>
              <label className={styles.formLabel}>Ticket Subject</label>
              <FormField>
                <FormField.Field label=''>
                  <Box width='100%'>
                    <MergeInput showTextArea name='subject' mergeFields={SOFTWARE_ACCESS_MERGE_FIELDS} />
                  </Box>
                </FormField.Field>
                {errors?.subject && <span className={styles.errorText}>{errors.subject.message}</span>}
              </FormField>
            </Flex>
          </div>
        )}
      </div>
      {_.isEmpty(ticketingAppMeta) && isTicketCreationEnabled && (
        <div className={styles.disabledOverlay}>
          <p className={(styles.disabledText, styles.errorText)}>
            Ticketing integration is not enabled for this workspace. Please enable it in the{' '}
            <a className={styles.navLink} onClick={() => navigate('/apps/category/ticketing-system')}>
              apps
            </a>{' '}
            page.
          </p>
        </div>
      )}
    </>
  )
}

export default TicketCreation
