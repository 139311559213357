import baseApi, { TAGS } from './baseApi'

export const sharepointApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSharepointApp: builder.query({
      query: (params) => ({
        url: '/v1/apps/sharepoint',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.SHAREPOINT.SHAREPOINT]
    }),

    getSharepointSites: builder.query({
      query: (params) => ({
        url: '/v1/apps/sharepoint/sites/sync',
        method: 'GET',
        params
      }),
      providesTags: [TAGS.SHAREPOINT.SHAREPOINT_SITES]
    }),

    uninstallSharepoint: builder.mutation({
      query: (params) => ({
        url: '/v1/apps/sharepoint',
        method: 'DELETE',
        params
      }),
      invalidatesTags: [TAGS.SHAREPOINT.SHAREPOINT]
    }),

    removeSharepointSite: builder.mutation({
      query: ({ site_id, workspace_id }) => ({
        url: `/v1/apps/sharepoint/sites/sync/${site_id}`,
        method: 'DELETE',
        params: {
          workspace_id
        }
      }),
      invalidatesTags: [TAGS.SHAREPOINT.SHAREPOINT_SITES]
    }),

    searchSharepointSites: builder.query({
      query: (params) => ({
        url: `/v1/apps/sharepoint/sites`,
        method: 'GET',
        params
      })
    }),

    addSharepointSite: builder.mutation({
      query: (data) => ({
        url: `/v1/apps/sharepoint/sites/sync`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.SHAREPOINT.SHAREPOINT_SITES]
    }),

    enableSyncSharepoint: builder.mutation({
      query: (params) => ({
        url: '/v1/apps/sharepoint/sync/enable',
        method: 'POST',
        params
      }),
      invalidatesTags: [TAGS.SHAREPOINT.SHAREPOINT, TAGS.SHAREPOINT.SHAREPOINT_SITES]
    })
  })
})

export const {
  useGetSharepointAppQuery,
  useGetSharepointSitesQuery,
  useUninstallSharepointMutation,
  useRemoveSharepointSiteMutation,
  useSearchSharepointSitesQuery,
  useAddSharepointSiteMutation,
  useEnableSyncSharepointMutation
} = sharepointApi
