import baseApi, { TAGS } from './baseApi'

export const azureADApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAzureADApp: builder.query({
      query: () => ({
        url: '/v1/apps/azure-ad',
        method: 'GET'
      }),
      providesTags: [TAGS.AZURE_AD.AZURE_AD]
    }),

    uninstallAzureAD: builder.mutation({
      query: () => ({
        url: '/v1/apps/azure-ad',
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.AZURE_AD.AZURE_AD, TAGS.APPS, TAGS.ACCOUNT, TAGS.USER_GROUPS]
    }),

    getAzureADAIActions: builder.query({
      query: () => ({
        url: '/v1/apps/azure-ad/ai-actions',
        method: 'GET'
      }),
      providesTags: [TAGS.AZURE_AD.AZURE_AD_AI_ACTIONS]
    })
  })
})

export const { useGetAzureADAppQuery, useUninstallAzureADMutation, useGetAzureADAIActionsQuery } = azureADApi
