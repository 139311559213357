import { useEffect } from 'react'

import Protected from './pages/protected'
import Pages from './pagesv3'
import { assistAI, assistAIv3 } from './react-ui'
import { useGetAccountQuery } from './servicesV3/authApi'

const ProtectedRoutes = () => {
  const { data: account } = useGetAccountQuery()

  useEffect(() => {
    document.body.classList.remove(assistAI)
    document.body.classList.remove(assistAIv3)

    if (account?.is_v3_enabled) {
      document.body.classList.add(assistAIv3)
    } else {
      document.body.classList.add(assistAI)
    }
  }, [account?.is_v3_enabled])

  return account?.is_v3_enabled ? <Pages /> : <Protected />
}

export default ProtectedRoutes
