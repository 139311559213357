import { Button } from '@happyfoxinc/web-components'
import cx from 'classnames'
import _ from 'lodash'
import { Fragment, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './AccessControl.module.css'

import EditIcon from 'Src/assetsv3/icons/edit.svg'
import TrashIcon from 'Src/assetsv3/icons/trash.svg'
import FormField from 'Src/componentsv3/FormField'
import ReactSelect from 'Src/componentsv3/ReactSelect'
import MultiSelect from 'Src/pagesv3/Modules/SoftwareAccessModule/components/MultiSelectCheckbox'
import { useGetAccessControlMetaQuery } from 'Src/servicesV3/softwareAccessModuleApi'

const AccessControlSkeleton = () => {
  return (
    <div className={styles.accessControlContainer}>
      <div className={styles.conditionsWrapper}>
        <div className={`${styles.skeletonFullAccess} ${styles.skeleton}`}>
          <div className={`${styles.skeletonTitle} ${styles.skeleton}`} />
          <div className={`${styles.skeletonToggle} ${styles.skeleton}`} />
        </div>
        <div className={`${styles.skeletonCondition} ${styles.skeleton}`}>
          <div className={styles.conditionHeader}>
            <div className={`${styles.skeletonTitle} ${styles.skeleton}`} />
          </div>
          <div className={styles.dropdownContainer}>
            <div className={`${styles.skeletonField} ${styles.skeleton}`} />
            <div className={`${styles.skeletonField} ${styles.skeleton}`} />
          </div>
        </div>
        <div className={`${styles.skeletonButton} ${styles.skeleton}`} />
      </div>
    </div>
  )
}

const AccessControlEdit = ({
  metadata,
  index,
  accessControl,
  conditions,
  removeField,
  addField,
  onRemoveAccessControl,
  showAccessControlDelete = false,
  showConditionDelete = false
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isSubmitted }
  } = useFormContext()

  const getFieldOptionsFilter = useCallback(() => {
    return metadata
      .map((field) => {
        if (accessControl.conditions.find((condition) => condition.label?.toString() === field.id.toString())) {
          return null
        }

        return field.id.toString()
      })
      .filter(Boolean)
  }, [accessControl.conditions, metadata])

  const getAccessControlFieldOptions = useCallback(() => {
    if (!metadata) {
      return []
    }

    const filteredFieldOptions = getFieldOptionsFilter()

    return metadata.map((field) => {
      return {
        label: field.label,
        value: field.id.toString(),
        hidden: !filteredFieldOptions.includes(field.id.toString())
      }
    })
  }, [getFieldOptionsFilter, metadata])
  const fieldOptions = getAccessControlFieldOptions()

  const getValueOptions = (index, conditionIndex) => {
    const fieldId = getValues(`access_controls.${index}.conditions.${conditionIndex}.label`)
    const selectedAccessControlCategory = fieldId ? metadata.find((f) => f.id === parseInt(fieldId)) : null

    if (!selectedAccessControlCategory || !selectedAccessControlCategory.allowed_values) {
      return []
    }
    return selectedAccessControlCategory.allowed_values.map((value) => ({
      label: value.label,
      value: value.id
    }))
  }

  const CustomOption = ({ innerProps, label, data, isSelected, isFocused }) => (
    <div
      {...innerProps}
      style={{
        padding: '6px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: isSelected ? '#f5f5f5' : isFocused ? '#f9f9f9' : 'transparent',
        color: 'var(--color-text-default)',
        transition: 'all 0.2s ease',
        fontSize: 'var(--text-sm)',
        borderRadius: 'var(--border-radius-md)',
        ...(data.hidden ? { display: 'none' } : {})
      }}
    >
      {label}
    </div>
  )

  return (
    <div className={styles.conditionContainer}>
      <div className={styles.conditionHeader}>
        <h3 className={styles.conditionTitle}>Access Condition {index + 1}</h3>
        <div className={styles.headerButtons}>
          {showAccessControlDelete && (
            <Button
              type='button'
              variant='outline'
              className={styles.closeButton}
              onClick={() => onRemoveAccessControl(index)}
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      </div>
      <div className={styles.dropdownRow}>
        <div className={styles.dropdownLabel} style={{ width: '240px' }}>
          Field
        </div>
        <div className={styles.dropdownLabel} style={{ width: '240px' }}>
          Value
        </div>
        <div className={styles.deleteIcon} style={{ width: '14px' }} />
      </div>

      {conditions.map((condition, conditionIndex) => {
        return (
          <Fragment key={`condition-${index}-${conditionIndex}-${condition.id || ''}`}>
            <div className={styles.dropdownContainer}>
              <FormField className={styles.fieldContainer}>
                <FormField.Field
                  className={styles.formField}
                  error={
                    (isSubmitted ||
                      (dirtyFields && dirtyFields[`access_controls.${index}.conditions.${conditionIndex}.label`])) &&
                    errors &&
                    errors.access_controls &&
                    errors.access_controls[index]?.conditions &&
                    errors.access_controls[index]?.conditions[conditionIndex]?.label &&
                    errors.access_controls[index]?.conditions[conditionIndex]?.label.message
                  }
                >
                  <Controller
                    name={`access_controls.${index}.conditions.${conditionIndex}.label`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ReactSelect
                          value={fieldOptions.find((option) => option.value === value)}
                          options={fieldOptions}
                          onChange={(option) => {
                            setValue(`access_controls.${index}.conditions.${conditionIndex}.value`, [])
                            onChange(option.value)
                          }}
                          placeholder='Select Field'
                          components={{
                            Option: CustomOption
                          }}
                        />
                      )
                    }}
                  />
                </FormField.Field>
              </FormField>
              <FormField className={styles.fieldContainer}>
                <FormField.Field
                  className={styles.formField}
                  error={
                    (isSubmitted ||
                      (dirtyFields && dirtyFields[`access_controls.${index}.conditions.${conditionIndex}.value`])) &&
                    errors &&
                    errors.access_controls &&
                    errors.access_controls[index]?.conditions &&
                    errors.access_controls[index]?.conditions[conditionIndex]?.value &&
                    errors.access_controls[index]?.conditions[conditionIndex]?.value.message
                  }
                >
                  <Controller
                    name={`access_controls.${index}.conditions.${conditionIndex}.value`}
                    control={control}
                    render={({ field }) => {
                      const valueOptions = getValueOptions(index, conditionIndex)
                      return <MultiSelect options={valueOptions} field={field} placeholder='Select Value' />
                    }}
                  />
                </FormField.Field>
              </FormField>
              {showConditionDelete && (
                <button type='button' className={styles.deleteFieldButton} onClick={() => removeField(conditionIndex)}>
                  <svg xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' width='12' height='11'>
                    <path
                      fill='none'
                      stroke='black'
                      strokeWidth='1'
                      strokeLinecap='round'
                      strokeLinejoin='bevel'
                      transform='matrix(0.707107 0.707107 -0.707107 0.707107 7.73165 2.68822)'
                      d='M0.25471932 0.25471932L0.25471932 6.8774219'
                      fillRule='evenodd'
                    />
                    <path
                      fill='none'
                      stroke='black'
                      strokeWidth='1'
                      strokeLinecap='round'
                      strokeLinejoin='bevel'
                      transform='matrix(0.707107 0.707107 -0.707107 0.707107 3.22881 2.86833)'
                      d='M0 0.25471932L6.6227026 0.25471932'
                      fillRule='evenodd'
                    />
                  </svg>
                </button>
              )}
            </div>
          </Fragment>
        )
      })}
      {fieldOptions.some((option) => !option.hidden) && (
        <Button type='button' variant='outline' className={styles.addFieldButton} onClick={addField}>
          + Add
        </Button>
      )}
    </div>
  )
}

const AccessControlReadOnly = ({ accessControl, index, metadata, updateAccessControl }) => {
  return (
    <div className={styles.savedConditionContainer}>
      <div className={styles.savedConditionHeader}>
        <h3 className={styles.savedConditionTitle}>Access Condition {index + 1}</h3>
        <Button
          type='button'
          variant='outline'
          className={styles.editButton}
          onClick={() =>
            updateAccessControl(index, {
              ...accessControl,
              isEdit: true
            })
          }
        >
          <EditIcon />
        </Button>
      </div>
      <div className={styles.savedConditionContent}>
        {accessControl.conditions.map((condition, conditionIndex) => {
          const fieldOption = metadata.find((f) => f.id.toString() === condition.label.toString())
          const values = condition.value.map((value) => {
            const valueOption = fieldOption?.allowed_values?.find((v) => v.id.toString() === value.toString())
            return valueOption && valueOption.label ? _.startCase(valueOption.label) : ''
          })

          if (conditionIndex >= 2) {
            return ''
          }

          return (
            <div
              id={`condition-${index}-${conditionIndex}-${condition.id || ''}-${fieldOption.name}`}
              key={`condition-${index}-${conditionIndex}-${condition.id || ''}-${fieldOption.name}`}
              className={styles.fieldContainer}
            >
              <div className={styles.fieldValue}>
                <span className={styles.fieldIcon}>{fieldOption && fieldOption.icon}</span>
                <span className={styles.fieldLabel}>
                  {fieldOption && fieldOption.name ? _.startCase(fieldOption.name) : ''}:
                </span>
                <span className={styles.fieldValueText} title={values.join(', ')}>
                  {values.length > 0
                    ? values.map((value, index) => (
                        <span key={`value-${index}`}>
                          {value}
                          {index < values.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    : '-'}
                </span>
              </div>
            </div>
          )
        })}
        <div
          style={{
            visibility: accessControl.conditions.length > 2 ? 'visible' : 'hidden'
          }}
          className={styles.remainingCount}
        >
          +{accessControl.conditions.length - 2}
        </div>
      </div>
    </div>
  )
}

const Conditions = ({ metadata, onAddAccessControl, updateAccessControl, onRemoveAccessControl }) => {
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const accessControls = watch('access_controls')

  return (
    <Fragment>
      {accessControls.map((accessControl, index) => {
        const conditions = watch(`access_controls.${index}.conditions`) || []

        const addField = () => {
          const updatedFields = [...conditions]
          updatedFields.push({
            label: null,
            value: null
          })
          const path = `access_controls.${index}.conditions`
          setValue(path, updatedFields)
        }

        const removeField = (conditionIndex) => {
          const updatedFields = [...conditions]
          updatedFields.splice(conditionIndex, 1)
          const path = `access_controls.${index}.conditions`
          setValue(path, updatedFields)
        }

        return (
          <Fragment key={`condition-group-${index}`}>
            {index / 2 !== 0 && (
              <div className={styles.controlSeparator}>
                <span className={styles.empty} />
                <span className={styles.text}>OR</span>
                <span className={styles.empty} />
              </div>
            )}
            {accessControl.isEdit ? (
              <AccessControlEdit
                key={`condition-group-${accessControl.id || index}`}
                metadata={metadata}
                index={index}
                accessControl={accessControl}
                conditions={conditions}
                removeField={removeField}
                addField={addField}
                showAccessControlDelete
                showConditionDelete={conditions.length > 1}
                onRemoveAccessControl={onRemoveAccessControl}
              />
            ) : (
              <AccessControlReadOnly
                accessControl={accessControl}
                metadata={metadata}
                index={index}
                key={`condition-readonly-${accessControl.id || index}`}
                updateAccessControl={updateAccessControl}
              />
            )}
          </Fragment>
        )
      })}
      <Button
        type='button'
        variant='outline'
        radius='full'
        className={styles.addConditionButton}
        onClick={() => {
          clearErrors('access_controls')
          onAddAccessControl()
        }}
      >
        + Add Condition
      </Button>
      {errors && errors.access_controls && (
        <span className={styles.conditionErrorMsg}>{errors.access_controls.message}</span>
      )}
    </Fragment>
  )
}

const AccessControl = ({ app, onAddAccessControl, onRemoveAccessControl, updateAccessControl, isLoading = false }) => {
  const { data: metadata, isSuccess, isLoading: isMetaLoading } = useGetAccessControlMetaQuery()

  const { control, watch } = useFormContext()

  const isFullAccess = watch('is_full_access')

  const accessControls = watch('access_controls')

  if (isLoading || isMetaLoading) {
    return <AccessControlSkeleton />
  }

  return (
    <Fragment>
      {isSuccess && (
        <div className={styles.accessControlContainer}>
          <div className={styles.conditionsWrapper}>
            <div
              className={cx(styles.fullAccessContainer, { [styles.mb16]: !isFullAccess && accessControls.length > 0 })}
            >
              {isFullAccess && (
                <Fragment>
                  <h3 className={styles.fullAccessTitle}>Full Access</h3>
                  <p className={styles.fullAccessDescription}>{` enabled for ${app?.name}`}</p>
                </Fragment>
              )}
              {!isFullAccess && (
                <Fragment>
                  <h3 className={styles.fullAccessTitle}>Conditional Access</h3>
                  <p className={styles.fullAccessDescription}>{` enabled for ${app?.name}`}</p>
                </Fragment>
              )}
              <div className={styles.fullAccessToggle}>
                <label className={styles.switch}>
                  <Controller
                    name='is_full_access'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input type='checkbox' value={value} checked={value} onChange={onChange} />
                    )}
                  />
                  <span className={styles.slider} />
                </label>
              </div>
            </div>
            {!isFullAccess && !isLoading && !isMetaLoading && (
              <Fragment>
                <Conditions
                  metadata={metadata}
                  onAddAccessControl={onAddAccessControl}
                  updateAccessControl={updateAccessControl}
                  onRemoveAccessControl={onRemoveAccessControl}
                />
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default AccessControl
