import * as yup from 'yup'

import noQueryParamsInUrl from 'Utils/inputValidation/noQueryParamsInUrl'

export const INSTALL_FORM_SCHEMAS = {
  helpdesk: yup
    .object()
    .shape({
      account_url: yup
        .string()
        .trim()
        .required('Account URL is required')
        .url('Invalid account url')
        .test(noQueryParamsInUrl(`Account URL`))
    })
    .required(),

  servicedesk: yup
    .object()
    .shape({
      account_url: yup
        .string()
        .trim()
        .required('Account URL is required')
        .url('Invalid account url')
        .test(noQueryParamsInUrl(`Account URL`))
    })
    .required(),

  okta: yup
    .object()
    .shape({
      account_url: yup.string().trim().required('Account URL is required').url('Invalid URL'),
      api_token: yup.string().trim().required('Token is required')
    })
    .required(),

  confluence: yup
    .object()
    .shape({
      account_url: yup.string().trim().required('Account URL is required').url('Please enter a valid url'),
      installation_code: yup.string().trim().required('Installation code is required')
    })
    .required(),

  guru: yup
    .object()
    .shape({
      email: yup.string().trim().required('Email is required').email('Invalid Email'),
      token: yup.string().trim().required('Token is required')
    })
    .required(),

  simpplr: yup
    .object()
    .shape({
      consumer_key: yup.string().trim().required('Consumer Token is required'),
      client_secret: yup.string().trim().required('Client Secret is required')
    })
    .required(),

  gitbook: yup
    .object()
    .shape({
      access_token: yup.string().trim().required('Access token is required')
    })
    .required(),

  outline: yup
    .object()
    .shape({
      client_url: yup.string().trim().required('Base URL is required').url('Invalid URL'),
      api_key: yup.string().trim().required('Token is required')
    })
    .required()
}
