import cx from 'classnames'
import { useNavigate } from 'react-router-dom'

import styles from './BackButton.module.css'

import ArrowLeft from './arrow-left.svg'

const BackButton = ({ className }) => {
  const navigate = useNavigate()

  const handleBack = () => {
    window.history.length > 1 ? navigate(-1) : navigate('/')
  }

  return (
    <button onClick={handleBack} className={cx(styles.backButton, className)}>
      <span className={styles.backArrow}>
        <ArrowLeft size={24} />
      </span>
    </button>
  )
}

export default BackButton
