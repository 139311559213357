import { Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'
import toast from 'react-hot-toast'

import styles from './Okta.module.css'

import { useFeature } from 'Src/components/Features'
import { ACCOUNT_TYPE } from 'Src/constants/account'
import { CLIENT_FEATURE_FLAGS } from 'Src/constants/feature-flags'
import AppFooter from 'Src/pages/protected/Apps/components/AppFooter'
import AiActions from 'Src/pagesv3/Apps/components/AiActions'
import AppInstalledCard from 'Src/pagesv3/Apps/components/AppInstalledCard'
import QuickActions from 'Src/pagesv3/Apps/components/QuickActions'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetAllOktaAIActionsQuery, useGetOktaAppQuery, useUninstallOktaMutation } from 'Src/servicesV3/oktaApi'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

import OktaSkeleton from './OktaSkeleton'

const Okta = () => {
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)

  const { currentWorkspaceId } = useWorkspace()
  const { data: account } = useGetAccountQuery()
  const { data: app = {}, isLoading } = useGetOktaAppQuery({ workspace_id: currentWorkspaceId })
  const { data: aiActionTableData = {}, isLoading: isAiActionTableLoading } = useGetAllOktaAIActionsQuery()
  const [uninstall, { isLoading: uninstallLoading }] = useUninstallOktaMutation()

  const isTicketingSystemConnected = account.is_ticketing_integration_connected
  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK

  const isDMWorkflowEnabled = useFeature([CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE])
  const enableQuickActionsConfig = isDMWorkflowEnabled && isTicketingSystemConnected

  const displayAiActions = isSlackAccountType && app.is_ai_actions_allowed

  const handleUninstall = () => {
    const promise = uninstall().unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: 'Uninstalling Okta',
      success: 'Okta integration uninstalled successfully',
      error: (err) => {
        const errorMessage = err?.data?.message || 'Unable to uninstall. Try again'
        return errorMessage
      }
    })
  }

  if (isLoading || isAiActionTableLoading) {
    return <OktaSkeleton />
  }

  return (
    <Flex width='100%' gap='25px' direction='column'>
      <AppInstalledCard title='Okta' accountUrl='https://www.notion.so/' showButton />
      {enableQuickActionsConfig && <QuickActions data={app.quick_actions} />}
      {displayAiActions && <AiActions data={aiActionTableData} />}

      <AppFooter app={app} isUninstalling={uninstallLoading} onUninstall={() => setIsUninstallModalOpen(true)} />

      <Modal
        size='small'
        title='Uninstall Okta'
        open={isUninstallModalOpen}
        onCancel={() => setIsUninstallModalOpen(false)}
        onOpenChange={(isOpen) => !isOpen && setIsUninstallModalOpen(false)}
        showFooter={false}
        bodyClassName={styles.modalBody}
      >
        <Flex direction='column' gap='12px' align='center'>
          Are you sure you want to uninstall Okta?
          <Flex gap='15px'>
            <Button variant='solid' disabled={uninstallLoading} onClick={handleUninstall}>
              Uninstall
            </Button>
            <Button variant='outline' onClick={() => setIsUninstallModalOpen(false)}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Okta
