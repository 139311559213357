// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ik7ecOz__b5NcMwYfp9N{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.aBrQvRSqYtO3dlgJJutQ{color:var(--color-text-default);font-size:var(--text-h1);font-weight:var(--font-weight-normal);text-align:left;margin-bottom:15px}.OtbmtmymjBAXT_24L3hQ{width:165px;height:165px}.Ir0GsqjlTEsodAnVlgcf{font-size:var(--text-lg);font-weight:var(--font-weight-normal)}.AcEZSBeZ06_sgHbTyJ0Y{padding:0 20px}.NafwZH_YeerNqA0REAtg{border-radius:0px 0px 8px 8px}.w70mROB_RUmlc89QIVCt{margin-top:10px}.HqdNqlHhWf1N3L908Hgu{margin:12px 0;font-size:var(--text-sm)}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/components/InstallScreen/InstallScreen.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,+BAAA,CACA,wBAAA,CACA,qCAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CAGF,sBACE,wBAAA,CACA,qCAAA,CAGF,sBACE,cAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,eAAA,CAGF,sBACE,aAAA,CACA,wBAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.installDescription {\n  color: var(--color-text-default);\n  font-size: var(--text-h1);\n  font-weight: var(--font-weight-normal);\n  text-align: left;\n  margin-bottom: 15px;\n}\n\n.installIconWrapper {\n  width: 165px;\n  height: 165px;\n}\n\n.formHeader {\n  font-size: var(--text-lg);\n  font-weight: var(--font-weight-normal);\n}\n\n.submitButton {\n  padding: 0 20px;\n}\n\n.modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n\n.redirectButton {\n  margin-top: 10px;\n}\n\n.redirectUrlSection {\n  margin: 12px 0;\n  font-size: var(--text-sm);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Ik7ecOz__b5NcMwYfp9N",
	"installDescription": "aBrQvRSqYtO3dlgJJutQ",
	"installIconWrapper": "OtbmtmymjBAXT_24L3hQ",
	"formHeader": "Ir0GsqjlTEsodAnVlgcf",
	"submitButton": "AcEZSBeZ06_sgHbTyJ0Y",
	"modalBody": "NafwZH_YeerNqA0REAtg",
	"redirectButton": "w70mROB_RUmlc89QIVCt",
	"redirectUrlSection": "HqdNqlHhWf1N3L908Hgu"
};
export default ___CSS_LOADER_EXPORT___;
