import { Box } from '@happyfoxinc/web-components'

import styles from './ModuleCard.module.css'

import ChevronRight from 'Src/assetsv3/icons/chevron-right.svg'
import CheckCircle from 'Src/assetsv3/icons/green-tick.svg'

const ModuleCard = ({ icon: Icon, title, description, is_active = false, onClick, isLoading = false }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick()
      event.preventDefault()
    }
  }

  if (isLoading) {
    return (
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <Box p='0 0 12px 0'>
            <div className={`${styles.icon} ${styles.skeleton} ${styles.skeletonIcon}`} />
          </Box>
        </div>

        <div className={styles.cardContent}>
          <div className={`${styles.skeleton} ${styles.skeletonTitle}`} />
          <div className={`${styles.skeleton} ${styles.skeletonDescription}`} />
          <div className={`${styles.skeleton} ${styles.skeletonDescription}`} />
        </div>

        <div className={styles.cardFooterNoConfig}>
          <div className={`${styles.arrowContainer} ${styles.skeleton}`}>
            <div className={styles.skeletonArrow} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={styles.card}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
      aria-label={`${title} module${is_active ? ' (Configured)' : ''}`}
    >
      <div className={styles.cardHeader}>
        <Box p='0 0 12px 0'>
          <Icon className={styles.icon} aria-hidden='true' />
        </Box>
      </div>

      <div className={styles.cardContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>

      <div className={is_active ? styles.cardFooter : styles.cardFooterNoConfig}>
        {is_active && (
          <div className={styles.configuredBadge}>
            <span>Configured</span>
            <CheckCircle aria-hidden='true' />
          </div>
        )}
        <div className={styles.arrowContainer}>
          <ChevronRight aria-hidden='true' />
        </div>
      </div>
    </div>
  )
}

export default ModuleCard
