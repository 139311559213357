import { Box, Button, Flex, Modal, Pagination, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import dayjs from 'dayjs'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import styles from './Answers.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import SearchIcon from 'Src/assetsv3/icons/search.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useDeleteAnswerMutation, useGetAnswersQuery } from 'Src/servicesV3/answersApi'
import debounce from 'Src/utils/debounce'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Src/utils/query-params'
import { useWorkspace } from 'Src/utilsV3/hooks/useWorkspaceContext'

const defaultParams = {
  defaultSearch: '',
  supportedSortColumns: ['created_at', 'updated_at'],
  defaultSortedColumn: 'created_at',
  defaultSortOrder: 'desc'
}

const getInitialParams = () => {
  return getInitialParamsForTable(defaultParams)
}

const getInitialApiQueryParams = (currentWorkspaceId) => {
  const initialParams = getInitialParams()
  return {
    ...getInitialApiQueryParamsForTable(initialParams),
    workspace_id: currentWorkspaceId
  }
}

const DeleteModal = (props) => {
  const { id, data, ...restProps } = props
  const [deleteAnswer, deleteAnswerApiResults] = useDeleteAnswerMutation()

  const handleDelete = useCallback(() => {
    const promise = deleteAnswer(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting answer',
      success: `Deleted answer '${data.name}'. Successfully...`,
      error: `Error while deleting answer '${data.name}'. Try again.`
    })
    props.onClose()
  }, [id, data, deleteAnswer, props])

  return (
    <Modal size='small' {...restProps}>
      <Flex direction='column' gap='12px' align='center'>
        Are you sure to delete this answer?
        <Flex gap='15px'>
          <Button variant='solid' disabled={deleteAnswerApiResults.isLoading} onClick={handleDelete}>
            Delete
          </Button>
          <Button variant='outline' disabled={deleteAnswerApiResults.isLoading} onClick={props.onClose}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

const AnswersList = () => {
  const { currentWorkspaceId } = useWorkspace()
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const initialParams = useMemo(getInitialParams, [])

  const [queryParams, setQueryParams] = useState(() => getInitialApiQueryParams(currentWorkspaceId))
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState(null)

  const { data: answersApiResponse = {}, isLoading: answerApiLoading } = useGetAnswersQuery(queryParams)
  const { results: answersList = [], meta: paginationDetails = {} } = answersApiResponse

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
        Cell: ({ cell: { value } }) => {
          return value.name
        }
      },
      {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({ cell: { value } }) => {
          return dayjs(value).format('MMM D, YYYY h:mm A')
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: answersList,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      // sort
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      workspace_id: currentWorkspaceId,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [currentWorkspaceId, pageIndex, pageSize, searchText, sortBy, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  const handleDeleteClick = (answer) => {
    setSelectedAnswer(answer)
    setIsDeleteModalOpen(true)
  }

  if (answerApiLoading) {
    return <TableLoader rows={5} columns={4} showOptions />
  }

  return (
    <Fragment>
      <Flex justify='space-between' className={styles.tableActionContainer}>
        <div className={styles.searchContainer}>
          <div className={styles.searchWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search answers'
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <Pagination
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </Flex>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            const answer = row.original
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell
                      key={key}
                      {...restCellProps}
                      onClick={() => navigate(answer.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                <TableOptions>
                  <TableOption onClick={() => navigate(`${row.original.id}`)}>Edit</TableOption>
                  <TableOption onClick={() => handleDeleteClick(answer)}>Delete</TableOption>
                </TableOptions>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
      <DeleteModal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
          setSelectedAnswer(null)
        }}
        id={selectedAnswer?.id}
        data={selectedAnswer}
        title='Delete Answer'
        showFooter={false}
        bodyClassName={styles.modalBody}
      />
    </Fragment>
  )
}

const Answers = () => {
  const navigate = useNavigate()

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='12px'>
          <h1 className='heading'>Answers</h1>
          <Button
            variant='solid'
            size='small'
            radius='full'
            className={styles.addButton}
            onClick={() => navigate('create')}
          >
            <PlusIcon />
          </Button>
        </Flex>
      </div>
      <div className={styles.listContainer}>
        <AnswersList />
      </div>
    </Box>
  )
}

export default Answers
