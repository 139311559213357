import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import cx from 'classnames'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import styles from './ConfigModule.module.css'

import QuestionsLoader from 'Src/pagesv3/Modules/SurveyManagementModule/ConfigModule/components/QuestionsLoader/QuestionsLoader'
import { useGetQuestionsQuery } from 'Src/servicesV3/surveyApi'

import PencilIcon from '../../../../assets/icons/pencil.svg'
import StartFlagIcon from '../../../../assetsv3/icons/start-flag.svg'
import { QuestionBlock, SortableQuestionBlock } from './components/QuestionBlock/QuestionBlock'
import { QUESTION_TYPES } from './constants'
import SurveyConfiguration from './SurveyConfiguration'

const ConfigModule = ({
  currentScreen,
  surveyId,
  handleTransformedQuestions,
  isEdit,
  isLoading: isSurveyLoading,
  onValidationError
}) => {
  const [questions, setQuestions] = useState([])
  const [validationErrors, setValidationErrors] = useState({})
  const [showDropdown, setShowDropdown] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const {
    data: questionData,
    isLoading: isQuestionsLoading,
    refetch: refetchQuestions
  } = useGetQuestionsQuery(surveyId, {
    skip: !surveyId
  })

  const { watch } = useFormContext()
  const surveyName = watch('surveyName')

  useEffect(() => {
    if (isEdit && questionData?.questions) {
      refetchQuestions()
      setQuestions(
        questionData.questions.length > 0
          ? questionData.questions.map((q) => ({
              id: q.id,
              text: q.text,
              type: q.question_type,
              options: q.choices || [],
              order: q.order,
              isRequired: q.is_required ?? true,
              originalData: { ...q },
              isModified: false,
              isDeleted: false
            }))
          : [getDefaultQuestion()]
      )
    } else if (!isEdit && !questionData) {
      setQuestions([getDefaultQuestion()])
    }
  }, [questionData, isEdit, refetchQuestions])

  const getDefaultQuestion = () => ({
    id: `new-${uuidv4()}`,
    text: '',
    type: 'single_choice',
    options: ['', '', ''],
    order: 1,
    isRequired: true,
    isModified: false,
    isDeleted: false
  })

  useEffect(() => {
    if (questions.length > 0) {
      const transformedQuestions = {
        newQuestions: questions
          .filter((q) => !q.originalData)
          .map((q) => ({
            text: q.text,
            question_type: q.type,
            choices: q.type !== 'open_ended' ? q.options?.filter((opt) => opt?.trim() !== '') : [],
            order: q.order,
            is_required: q.isRequired
          })),
        modifiedQuestions: questions
          .filter((q) => q.originalData && (q.isModified || q.order !== q.originalData.order) && !q.isDeleted)
          .map((q) => ({
            id: q.id,
            text: q.text,
            question_type: q.type,
            choices: q.options,
            order: q.order,
            is_required: q.isRequired
          })),
        deletedQuestions: questions.filter((q) => q.originalData && q.isDeleted).map((q) => q.id)
      }
      handleTransformedQuestions(transformedQuestions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions])

  const validateQuestions = () => {
    const errors = {}
    let hasError = false

    questions.forEach((question, index) => {
      if (
        (question.type === 'single_choice' || question.type === 'multiple_choice') &&
        (!question.options || question.options.every((opt) => !opt?.trim()))
      ) {
        errors[question.id] = { text: 'At least one choice is required' }
        hasError = true
      }

      if (!question.text?.trim()) {
        errors[question.id] = { text: 'Question text is required' }
        hasError = true
      }
    })

    setValidationErrors(errors)
    if (hasError) {
      const firstErrorId = Object.keys(errors)[0]
      const errorElement = document.getElementById(`question-${firstErrorId}`)
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
    return !hasError
  }

  useEffect(() => {
    if (onValidationError) {
      onValidationError(validateQuestions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, onValidationError])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor)
  )

  const handleDragStart = (event) => {
    setActiveId(event.active.id)
  }

  const handleDragOver = (event) => {
    const { active, over } = event

    if (!over || active.id === over.id) {
      return
    }

    setQuestions((prevQuestions) => {
      const oldIndex = prevQuestions.findIndex((q) => q.id === active.id)
      const newIndex = prevQuestions.findIndex((q) => q.id === over.id)

      return arrayMove(prevQuestions, oldIndex, newIndex).map((q, index) => ({
        ...q,
        order: index + 1,
        isModified: q.originalData ? q.isModified || index + 1 !== q.originalData.order : false
      }))
    })
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = questions.findIndex((q) => q.id === active.id)
      const newIndex = questions.findIndex((q) => q.id === over.id)

      const newQuestionsArray = [...questions]
      const [movedItem] = newQuestionsArray.splice(oldIndex, 1)
      newQuestionsArray.splice(newIndex, 0, movedItem)

      const reorderedQuestions = newQuestionsArray.map((q, index) => ({
        ...q,
        order: index + 1,
        isModified: q.originalData ? q.isModified || index + 1 !== q.originalData.order : false
      }))

      setQuestions(reorderedQuestions)
    }
    setActiveId(null)
  }

  const handleAddQuestion = (questionType) => {
    const newQuestion = {
      id: `new-${uuidv4()}`,
      text: '',
      type: questionType,
      options: questionType !== 'open_ended' ? ['', '', ''] : undefined,
      order: questions.length + 1,
      isRequired: true,
      isModified: false,
      isDeleted: false
    }

    setQuestions((prev) => [...prev, newQuestion])
    setShowDropdown(false)
  }

  const handleQuestionChange = useCallback((index, updatedQuestion) => {
    setQuestions((prev) => {
      const newQuestionsArray = [...prev]
      const originalQuestion = newQuestionsArray[index]
      const hasChanged =
        originalQuestion.originalData &&
        (originalQuestion.originalData.text !== updatedQuestion.text ||
          originalQuestion.originalData.type !== updatedQuestion.type ||
          JSON.stringify(originalQuestion.originalData.options) !== JSON.stringify(updatedQuestion.options))

      newQuestionsArray[index] = {
        ...updatedQuestion,
        isModified: hasChanged,
        isDeleted: originalQuestion.isDeleted
      }

      return newQuestionsArray
    })
  }, [])

  const removeQuestion = (indexToRemove) => {
    setQuestions((prev) => {
      const questionToRemove = prev[indexToRemove]
      if (questionToRemove.originalData) {
        return prev.map((q, index) => (index === indexToRemove ? { ...q, isDeleted: true } : q))
      } else {
        const updatedQuestions = prev.filter((_, index) => index !== indexToRemove)
        return updatedQuestions.map((q, idx) => ({
          ...q,
          order: idx + 1
        }))
      }
    })
  }

  const handleDuplicateQuestion = (indexToDuplicate) => {
    const questionToDuplicate = questions[indexToDuplicate]
    const newQuestion = {
      id: `new-${uuidv4()}`,
      text: questionToDuplicate.text,
      type: questionToDuplicate.type,
      options: questionToDuplicate.options ? [...questionToDuplicate.options] : undefined,
      order: questionToDuplicate.order + 1,
      isRequired: questionToDuplicate.isRequired,
      isModified: false,
      isDeleted: false
    }

    setQuestions((prev) => {
      const newQuestions = [...prev]
      newQuestions.splice(indexToDuplicate + 1, 0, newQuestion)
      return newQuestions.map((q, idx) => ({
        ...q,
        order: idx + 1
      }))
    })
  }

  const renderDropdown = () => (
    <div className={styles.stepDropdown}>
      {QUESTION_TYPES.map((type) => (
        <button key={type.key} className={styles.dropdownItem} onClick={() => handleAddQuestion(type.key)}>
          <div className={styles.dropdownItemContent}>
            <span className={styles.dropdownItemText}>{type.label}</span>
          </div>
          <span className={styles.dropdownItemArrow}>›</span>
        </button>
      ))}
    </div>
  )

  return (
    <div className={styles.configurationSection}>
      {currentScreen === 0 ? (
        <SurveyConfiguration isLoading={isSurveyLoading} currentScreen={0} />
      ) : currentScreen === 1 ? (
        <div className={styles.questionsContainer}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{surveyName}</h2>
            <PencilIcon className={styles.pencilIcon} />
          </div>
          <div className={styles.workflowContainer}>
            <div className={styles.startIconWrapper}>
              <StartFlagIcon />
            </div>
            <Fragment>
              {isQuestionsLoading ? (
                <QuestionsLoader questions={3} />
              ) : (
                <Fragment>
                  {questions.length > 0 && <div className={cx(styles.stepLine, styles.stepLineTop)} />}
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                  >
                    <SortableContext
                      items={questions.filter((q) => !q.isDeleted).map((q) => q.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {questions
                        .filter((q) => !q.isDeleted)
                        .map((question, index) => (
                          <Fragment key={question.id}>
                            <SortableQuestionBlock
                              id={question.id}
                              questionNumber={index + 1}
                              question={question}
                              onChange={(updated) => handleQuestionChange(index, updated)}
                              onRemove={() => removeQuestion(questions.findIndex((q) => q.id === question.id))}
                              onDuplicate={() =>
                                handleDuplicateQuestion(questions.findIndex((q) => q.id === question.id))
                              }
                              isDragging={activeId === question.id}
                              errors={validationErrors[question.id]}
                            />
                            {index < questions.filter((q) => !q.isDeleted).length - 1 && (
                              <div className={cx(styles.stepLine, styles.stepLineBetween)} />
                            )}
                          </Fragment>
                        ))}
                    </SortableContext>
                    <DragOverlay>
                      {activeId ? (
                        <QuestionBlock
                          question={questions.find((q) => q.id === activeId)}
                          questionNumber={questions.findIndex((q) => q.id === activeId) + 1}
                          className={styles.dragOverlay}
                        />
                      ) : null}
                    </DragOverlay>
                  </DndContext>
                  <div className={styles.addStepContainer}>
                    <div className={styles.stepLineToAdd} />
                    <button className={styles.addStepWrapper} onClick={() => setShowDropdown(!showDropdown)}>
                      <span className={styles.addStepButton}>+</span>
                      Add Question
                    </button>
                    {showDropdown && renderDropdown()}
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      ) : (
        <SurveyConfiguration isLoading={isSurveyLoading} currentScreen={2} surveyId={surveyId} />
      )}
    </div>
  )
}

export default ConfigModule
