// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fUikRF1xuHdfCB21zWEm{width:100%}.iu7AJv6UKy1zAyAalLpM{border-radius:0px 0px 8px 8px}.VwGc7AG4VihtJy0m7r9S{font-size:var(--text-md);color:var(--color-text-default);font-weight:var(--font-weight-normal);margin:6px 0}.FBOQLjJqHBmTxVMjZ2as{padding:16px;color:var(--color-text-muted);font-size:var(--text-md);text-align:center}.iVTxHfLDtVI08GdlMe66{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/Simpplr/Simpplr.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,wBAAA,CACA,+BAAA,CACA,qCAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n\n.heading {\n  font-size: var(--text-md);\n  color: var(--color-text-default);\n  font-weight: var(--font-weight-normal);\n  margin: 6px 0;\n}\n\n.emptyState {\n  padding: 16px;\n  color: var(--color-text-muted);\n  font-size: var(--text-md);\n  text-align: center;\n}\n\n.tableActionContainer {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "fUikRF1xuHdfCB21zWEm",
	"modalBody": "iu7AJv6UKy1zAyAalLpM",
	"heading": "VwGc7AG4VihtJy0m7r9S",
	"emptyState": "FBOQLjJqHBmTxVMjZ2as",
	"tableActionContainer": "iVTxHfLDtVI08GdlMe66"
};
export default ___CSS_LOADER_EXPORT___;
