export const getFieldOptionsFromAccessControl = (accessControlData) => {
  if (!accessControlData) return []

  const fieldIcons = {
    Location: '📍',
    Departments: '🏢',
    Role: '👤',
    user_groups: '👥'
  }

  return accessControlData.map((field) => ({
    id: field.id,
    name: field.name,
    label: field.label,
    icon: fieldIcons[field.name] || '⚡',
    values: field.allowed_values
  }))
}

export const getFieldValuesFromAccessControl = (fieldId, accessControlData) => {
  if (!accessControlData) return []

  const field = accessControlData.find((f) => f.id.toString() === fieldId?.toString())
  return field?.allowed_values || []
}
