import { Label, Text } from '@happyfoxinc/react-ui'
import { Button } from '@happyfoxinc/web-components'
import cx from 'classnames'
import { Fragment } from 'react'

import styles from './SyncedUsersStatus.module.css'

import UserIcon from 'Icons/user.svg'

import InProgressCard from 'Components/InProgressCard'
import Tooltip, { TooltipContent, TooltipTrigger } from 'Components/Tooltip'
import { ACCOUNT_TYPE } from 'Constants/account'
import ReloadIcon from 'Src/assetsv3/icons/reload.svg'
import WarningIcon from 'Src/assetsv3/icons/warning.svg'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'

const getMessage = (count, account_type) => {
  const isSlackAccountType = account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account_type === ACCOUNT_TYPE.MS_TEAMS
  const staticMessage = `${count} ${count === 1 ? 'user' : 'users'} could not be imported. `
  if (isSlackAccountType) {
    return `${staticMessage} They may not be a member of Slack workspace.`
  }

  if (isMsTeamsAccountType) {
    return `${staticMessage} They may not be a member of Teams workspace, or they may not have Assist AI installed on Teams yet.`
  }

  return ''
}

const AlertToolTip = ({ message }) => {
  return (
    <Tooltip delayDuration={150}>
      <TooltipTrigger className={styles.alertToolTip}>
        <WarningIcon className={styles.alertIcon} />
      </TooltipTrigger>
      <TooltipContent className={styles.tooltipContent}>
        <span variant='muted'>{message}</span>
      </TooltipContent>
    </Tooltip>
  )
}

const SyncedUsersStatus = ({ userGroup, isRefreshing, onReSync, onRefresh }) => {
  const {
    is_syncing: isAppSyncing,
    users_count: usersCount,
    sync_from_group: syncedFromGroup,
    sync_failed_users_count: syncFailedUsersCount
  } = userGroup
  const { data: account = {} } = useGetAccountQuery()
  const account_type = account.account_type

  const showFailedSyncMessage = !isAppSyncing && syncFailedUsersCount > 0

  return (
    <Fragment>
      <div>
        <Label>Users</Label>
        {isAppSyncing && (
          <InProgressCard isRefreshing={isRefreshing} onRefresh={onRefresh}>
            <UserIcon className={styles.usersIcon} />
            <div> We are syncing your users now</div>
            <Text variant='muted'>It'll be ready in a bit. Please come back later.</Text>
          </InProgressCard>
        )}
        {!isAppSyncing && (
          <div className={styles.usersContainer}>
            <div className={styles.syncedUsersDetails}>
              <div className={styles.syncedUsersCountBadge}>
                <div className={styles.syncedUsersCountNo}>{usersCount}</div>
                <div className={styles.syncedUsersCountText}>Users</div>
              </div>
              <div className={styles.syncedUserFrom}>
                <Text isInline variant='muted'>
                  Imported from
                </Text>
                {syncedFromGroup?.name}
                {showFailedSyncMessage && <AlertToolTip message={getMessage(syncFailedUsersCount, account_type)} />}
              </div>
              <Button
                type='button'
                variant='outline'
                onClick={onReSync}
                className={cx(styles.actionButton, styles.reSyncButton)}
              >
                Re-sync <ReloadIcon className={styles.reloadIcon} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default SyncedUsersStatus
