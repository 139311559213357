import baseApi, { TAGS } from './baseApi'

export const settingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => 'v1/notifications',
      providesTags: [TAGS.NOTIFICATIONS]
    }),
    updateNotifications: builder.mutation({
      query: (payload) => {
        return {
          method: 'PATCH',
          url: 'v1/notifications',
          body: payload
        }
      },
      invalidatesTags: [TAGS.NOTIFICATIONS]
    }),
    getAllTimeZones: builder.query({
      query: () => '/v1/timezone/all'
    }),
    getUserTimeZone: builder.query({
      query: () => '/v1/timezone/user',
      providesTags: [TAGS.TIME_ZONES]
    }),
    updateUserTimeZone: builder.mutation({
      query: (data) => ({
        url: '/v1/timezone/user',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: [TAGS.TIME_ZONES]
    })
  })
})

export const {
  useGetNotificationsQuery,
  useUpdateNotificationsMutation,
  useGetAllTimeZonesQuery,
  useGetUserTimeZoneQuery,
  useUpdateUserTimeZoneMutation
} = settingsApi
