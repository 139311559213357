import FEATURE_FLAGS from 'Src/constants/feature-flags'

const notificationsConfig = [
  {
    title: 'New Ticket Notification',
    description: 'Notify me when new tickets are created',
    key: 'new_tickets',
    type: 'boolean'
  },
  {
    title: 'Ticket Assigned',
    description: 'Notify me when Tickets are assigned to me',
    key: 'assigned_tickets',
    type: 'boolean',
    requiredFeatures: [FEATURE_FLAGS.CONVERSATIONAL_TICKETING]
  },
  {
    title: 'Ticket Subscribed',
    description: 'Notify me when replies are added to subscribed tickets',
    key: 'subscribed_tickets',
    type: 'boolean',
    requiredFeatures: [FEATURE_FLAGS.CONVERSATIONAL_TICKETING]
  }
]

export default notificationsConfig
