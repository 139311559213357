import cx from 'classnames'
import React from 'react'

import styles from '../ConfigModule.module.css'

import RemoveChoice from 'Src/assetsv3/icons/remove-x.svg'
import { isValid } from 'Utils/date'

export const CustomCheckbox = ({ checked, onChange }) => (
  <div className={cx(styles.checkboxIcon, { [styles.checked]: checked })} onClick={onChange} />
)

export const CustomClose = ({ onClick }) => (
  <div className={styles.closeIcon} onClick={onClick}>
    <RemoveChoice />
  </div>
)

export const DragHandle = ({ dragHandleProps }) => (
  <div className={styles.dragHandle} {...dragHandleProps}>
    <div className={styles.dragHandleIcon}>
      <div className={styles.dragHandleDots}>
        <div className={styles.dragHandleDot} />
        <div className={styles.dragHandleDot} />
      </div>
      <div className={styles.dragHandleDots}>
        <div className={styles.dragHandleDot} />
        <div className={styles.dragHandleDot} />
      </div>
    </div>
  </div>
)

export const formatDateForInput = (isoString) => {
  if (!isoString) return ''
  try {
    if (!isValid(isoString)) {
      return ''
    }
    const date = new Date(isoString)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
  } catch (error) {
    return ''
  }
}
