// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NjZswcqvupgnbUuc3KlH{display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-radius:var(--border-radius-sm);background-color:#f3faf3;padding:0px 30px}.ghkQOelWg_m0pty1eaqz{flex:1}.VN_o3ffQY1TTjFoPtrjD{font-size:var(--text-md);font-weight:var(--font-weight-normal);color:var(--color-text-default)}.FGj81mEDTmNKsDiMIpNc{font-weight:var(--font-weight-medium)}.hCB1y6AcdGZry7jAacCw{margin-left:16px;min-width:-moz-fit-content;min-width:fit-content}.ZjDBXqddlutbe_C9ZRCI{padding:0px 10px;background-color:#fafafc;border:1px solid var(--color-border-default)}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/components/AppInstalledCard/AppInstalledCard.module.css"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,qCAAA,CACA,wBAAA,CACA,gBAAA,CAGF,sBACE,MAAA,CAGF,sBACE,wBAAA,CACA,qCAAA,CACA,+BAAA,CAGF,sBACE,qCAAA,CAGF,sBACE,gBAAA,CACA,0BAAA,CAAA,qBAAA,CAGF,sBACE,gBAAA,CACA,wBAAA,CACA,4CAAA","sourcesContent":["/* AppInstalledCard.module.css */\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  border-radius: var(--border-radius-sm);\n  background-color: rgba(243, 250, 243, 1);\n  padding: 0px 30px;\n}\n\n.contentWrapper {\n  flex: 1;\n}\n\n.successMessage {\n  font-size: var(--text-md);\n  font-weight: var(--font-weight-normal);\n  color: var(--color-text-default);\n}\n\n.boldText {\n  font-weight: var(--font-weight-medium);\n}\n\n.buttonContainer {\n  margin-left: 16px;\n  min-width: fit-content;\n}\n\n.button {\n  padding: 0px 10px;\n  background-color: #FAFAFC;\n  border: 1px solid var(--color-border-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "NjZswcqvupgnbUuc3KlH",
	"contentWrapper": "ghkQOelWg_m0pty1eaqz",
	"successMessage": "VN_o3ffQY1TTjFoPtrjD",
	"boldText": "FGj81mEDTmNKsDiMIpNc",
	"buttonContainer": "hCB1y6AcdGZry7jAacCw",
	"button": "ZjDBXqddlutbe_C9ZRCI"
};
export default ___CSS_LOADER_EXPORT___;
