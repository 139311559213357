import { Flex } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

export const SharepointSitesSkeleton = () => (
  <div>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />
    <Flex gap='12px' className={styles.mb24}>
      <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w300}`} />
      <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w80}`} />
    </Flex>

    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />

    <Flex justify='flex-end' className={styles.mb16}>
      <div className={styles.paginationContainer}>
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w80}`} />
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
      </div>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={styles.headerBg}>
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w120}`} />
      </div>
      {[1, 2, 3, 4, 5].map((row) => (
        <div key={row} className={styles.tableRow}>
          <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
        </div>
      ))}
    </div>
  </div>
)

export const KnowledgeConfigurationSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w240} ${styles.mb16}`} />

    <Flex justify='flex-end' className={styles.mb16}>
      <div className={styles.paginationContainer}>
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
        <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w80}`} />
        <div className={`${styles.shimmerBg} ${styles.h32} ${styles.w32}`} />
      </div>
    </Flex>

    <div className={styles.tableContainer}>
      <div className={`${styles.grid} ${styles.gridCols3} ${styles.headerBg}`}>
        <div className={`${styles.shimmerBg} ${styles.h16}`} />
        <div className={`${styles.shimmerBg} ${styles.h16}`} />
        <div className={`${styles.shimmerBg} ${styles.h16}`} />
      </div>

      {[1, 2, 3, 4].map((row) => (
        <div key={row} className={`${styles.grid} ${styles.gridCols3} ${styles.tableRow}`}>
          <Flex gap='8px' align='center'>
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w16}`} />
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w200}`} />
          </Flex>
          <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w80} ${styles.badgeRadius}`} />
          <div className={`${styles.shimmerBg} ${styles.h24} ${styles.w24}`} />
        </div>
      ))}
    </div>
  </div>
)

const SharepointSkeleton = () => {
  return (
    <Flex gap='15px' direction='column'>
      <div className={styles.cardSkeleton}>
        <Flex gap='12px' align='center'>
          <div className={styles.iconSkeleton} />
          <div>
            <div className={`${styles.shimmerBg} ${styles.h20} ${styles.w180} ${styles.mb8}`} />
            <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w240} ${styles.opacity70}`} />
          </div>
        </Flex>
      </div>

      <SharepointSitesSkeleton />

      <KnowledgeConfigurationSkeleton />

      <div className={styles.footerSkeleton}>
        <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
      </div>
    </Flex>
  )
}

export default SharepointSkeleton
