import { Box, Button, Flex, Modal } from '@happyfoxinc/web-components'
import { useState } from 'react'

import styles from './InstallScreen.module.css'

import InstallIcon from 'Src/assetsv3/icons/install-app.png'
import { externalRedirect, getDomainFromUrl } from 'Src/utils/url'

import { useInstallApp } from '../../hooks/useInstallApp'
import InstallForm from './InstallForm'

const RedirectModal = ({ isOpen, onClose, appTitle, redirectUrl }) => {
  if (!isOpen) return null

  return (
    <Modal
      size='default'
      open={isOpen}
      onOpenChange={onClose}
      onCancel={onClose}
      title={`Redirect to ${appTitle}`}
      showFooter={false}
      bodyClassName={styles.modalBody}
    >
      <p className={styles.redirectModalDescription}>
        You will be redirected to {appTitle} to complete the installation process
      </p>
      <p className={styles.redirectUrlSection}>Redirect URL : {getDomainFromUrl(redirectUrl)} </p>
      <Button
        size='small'
        radius='medium'
        className={styles.redirectButton}
        onClick={() => {
          externalRedirect(redirectUrl)
          onClose()
        }}
      >
        Redirect
      </Button>
    </Modal>
  )
}

const InstallScreen = ({ app }) => {
  const [showForm, setShowForm] = useState(false)
  const [modalState, setModalState] = useState({
    isOpen: false,
    redirectUrl: null
  })

  const { redirectUrl: apiRedirectUrl, isLoading, isRedirectApp, installApp } = useInstallApp(app.id)

  const handleInitialInstall = () => {
    if (isRedirectApp && apiRedirectUrl) {
      setModalState({
        isOpen: true,
        redirectUrl: apiRedirectUrl
      })
      return
    }
    setShowForm(true)
  }

  const handleFormSubmit = async (formData) => {
    const result = await installApp(formData)

    if (result?.success && result?.requiresRedirect && result?.redirectUrl) {
      setModalState({
        isOpen: true,
        redirectUrl: result.redirectUrl
      })
    }
  }

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      redirectUrl: null
    })
  }

  if (showForm) {
    return (
      <Flex direction='column' gap='20px'>
        <h4 className={styles.formHeader}>Connect to {app.title}</h4>
        <InstallForm
          appId={app.id}
          schema={app.formSchema}
          fields={app.installFields}
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
        />
        <RedirectModal
          isOpen={modalState.isOpen}
          onClose={handleCloseModal}
          appTitle={app.title}
          redirectUrl={modalState.redirectUrl}
        />
      </Flex>
    )
  }

  return (
    <div className={styles.container}>
      <Box width='650px'>
        <p className={styles.installDescription}>
          You haven't linked your {app.title} account yet, click Install to begin the process
        </p>
        <Button
          size='small'
          radius='medium'
          className={styles.submitButton}
          onClick={handleInitialInstall}
          loading={isLoading}
        >
          Install
        </Button>
      </Box>
      <div className={styles.installIconWrapper}>
        <img src={InstallIcon} alt='Install icon' />
      </div>
      <RedirectModal
        isOpen={modalState.isOpen}
        onClose={handleCloseModal}
        appTitle={app.title}
        redirectUrl={modalState.redirectUrl}
      />
    </div>
  )
}

export default InstallScreen
