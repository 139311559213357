import { Sidebar } from '@happyfoxinc/web-components'
import { useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import styles from './Main.module.css'

import { CLIENT_FEATURE_FLAGS, FEATURE_FLAGS } from 'Constants/feature-flags'
import AnswersIcon from 'Src/assetsv3/icons/answers.svg'
import AppsIcon from 'Src/assetsv3/icons/apps.svg'
import ChannelsIcon from 'Src/assetsv3/icons/channels.svg'
import DashboardIcon from 'Src/assetsv3/icons/dashboard.svg'
import ManageAdminsIcon from 'Src/assetsv3/icons/manage-admin.svg'
import ModulesIcon from 'Src/assetsv3/icons/modules.svg'
import ReportsIcon from 'Src/assetsv3/icons/reports.svg'
import UserGroupsIcon from 'Src/assetsv3/icons/user-groups.svg'
import WorkspaceIcon from 'Src/assetsv3/icons/workspace.svg'
import { useFeaturesContext } from 'Src/components/Features'

import DashboardImg from '../../assetsv3/images/backgrounds/dashboard-bg.png'
import SecondaryBgImg from '../../assetsv3/images/backgrounds/secondary-bg-img.png'
import Topbar from '../Topbar'

const backgroundConfig = {
  '/dashboard': DashboardImg,
  '/modules': SecondaryBgImg,
  '/ai-assistant': SecondaryBgImg,
  default: SecondaryBgImg
}

const canShowMenuItem = (enabledFeatures, requiredFeatures) => {
  if (!requiredFeatures || requiredFeatures.length === 0) return true
  return requiredFeatures.every((feature) => enabledFeatures.includes(feature))
}

const AllMenuItems = [
  {
    icon: DashboardIcon,
    label: 'Dashboard',
    path: '/dashboard'
  },
  {
    icon: ModulesIcon,
    label: 'Modules',
    path: '/modules'
  },
  { type: 'divider' },
  {
    icon: AnswersIcon,
    label: 'Answers',
    path: '/answers'
  },
  // {
  //   icon: AiAssistantIcon,
  //   label: 'AI Assistant',
  //   path: '/ai-assistant'
  // },
  { type: 'divider' },
  {
    icon: AppsIcon,
    label: 'Apps',
    path: '/apps'
  },
  {
    icon: ChannelsIcon,
    label: 'Channels',
    path: '/channels',
    requiredFeatures: [CLIENT_FEATURE_FLAGS.CUSTOMISE_CHANNELS]
  },
  // { type: 'divider' },
  {
    icon: ReportsIcon,
    label: 'Reports',
    path: '/reports',
    requiredFeatures: [FEATURE_FLAGS.REPORTS]
  },
  { type: 'divider' },
  {
    icon: UserGroupsIcon,
    label: 'User Groups',
    path: '/user-groups',
    requiredFeatures: [FEATURE_FLAGS.USER_GROUPS]
  },
  {
    icon: ManageAdminsIcon,
    label: 'Manage Admins',
    path: '/admins'
  },
  {
    icon: WorkspaceIcon,
    label: 'Workspace',
    path: '/workspaces',
    requiredFeatures: [CLIENT_FEATURE_FLAGS.WORKSPACES]
  }
  // { icon: InboxIcon, label: 'Inbox', path: '/inbox' }
]

const Main = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isSearchActive, setIsSearchActive] = useState(false)

  const enabledFeatures = useFeaturesContext()

  const filteredMenuItems = useMemo(() => {
    return AllMenuItems.filter((item) => {
      if (item.type === 'divider') return true

      return canShowMenuItem(enabledFeatures, item.requiredFeatures)
    })
  }, [enabledFeatures])

  const currentBackground = useMemo(() => {
    if (location.pathname.startsWith('/modules')) {
      return backgroundConfig['/modules']
    }

    return backgroundConfig[location.pathname] || backgroundConfig.default
  }, [location.pathname])

  return (
    <div
      className={styles.MainContainer}
      style={{
        backgroundImage: `url(${currentBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Sidebar
        menuItems={filteredMenuItems}
        brandName='Assist AI'
        currentPath={location.pathname}
        onNavigate={(path) => navigate(path)}
      />
      <div className={styles.PageContainer}>
        <Topbar isSearchActive={isSearchActive} setIsSearchActive={setIsSearchActive} />
        <article className={styles.Content}>
          <Outlet />
        </article>
      </div>
    </div>
  )
}

export default Main
