// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T4S4dBipheJ7jUT2Ej66{margin-top:24px;color:rgba(21,23,26,.82);font-size:var(--text-sm);line-height:20px;margin:15px 0}.MS0qVm_p6bbY2VtCWPvY{border:1px solid var(--border-color-lighter);padding:10px 12px;border-radius:var(--border-radius);margin-top:9px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Reports/ContentOutline/ContentOutline.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,wBAAA,CACA,wBAAA,CACA,gBAAA,CACA,aAAA,CAGF,sBACE,4CAAA,CACA,iBAAA,CACA,kCAAA,CACA,cAAA","sourcesContent":[".title {\n  margin-top: 24px;\n  color: rgba(21, 23, 26, 0.82);\n  font-size: var(--text-sm);\n  line-height: 20px;\n  margin: 15px 0;\n}\n\n.outline {\n  border: 1px solid var(--border-color-lighter);\n  padding: 10px 12px;\n  border-radius: var(--border-radius);\n  margin-top: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "T4S4dBipheJ7jUT2Ej66",
	"outline": "MS0qVm_p6bbY2VtCWPvY"
};
export default ___CSS_LOADER_EXPORT___;
