import CompanyPolicyIcon from 'Src/assetsv3/logos/policy-logo.svg'
import SoftwareAccessIcon from 'Src/assetsv3/logos/software-access-logo.svg'
import SurveysIcon from 'Src/assetsv3/logos/surveys-logo.svg'

export const MODULES = {
  'software-access': {
    icon: SoftwareAccessIcon,
    title: 'Software Access Request Management',
    description:
      'Manage and streamline employee requests for software access, ensuring quick approvals and proper authorization.',
    path: 'software-access'
  },
  'company-policies': {
    icon: CompanyPolicyIcon,
    title: 'Company Policy Requests Management',
    description:
      'Centralize and track requests related to company policies, making it easy to manage policy inquiries, updates, or approvals.',
    path: 'company-policy'
  },
  'survey-management': {
    icon: SurveysIcon,
    title: 'Survey Management',
    description:
      'Easily create and manage surveys to gather user feedback, enabling quick insights and informed decision-making.',
    path: 'survey-management'
  }
}
