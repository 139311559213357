import { Box, Flex } from '@happyfoxinc/web-components'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const TabsSkeleton = () => (
  <Flex align='center' gap='60px' className={styles.mb24}>
    {[1, 2].map((tab) => (
      <div key={tab} className={`${styles.shimmerBg} ${styles.h32} ${styles.w150}`} />
    ))}
  </Flex>
)

const FormFieldSkeleton = ({ hasHelpText, width = '400px' }) => (
  <div className={styles.mb24}>
    <div className={`${styles.shimmerBg} ${styles.h16} ${styles.w120} ${styles.mb8}`} />
    <Box width={width}>
      <div className={`${styles.shimmerBg} ${styles.h36}`} />
    </Box>
    {hasHelpText && (
      <div className={`${styles.shimmerBg} ${styles.h12} ${styles.w200} ${styles.mt4} ${styles.opacity70}`} />
    )}
  </div>
)

const TicketConfigurationSkeleton = () => (
  <Flex direction='column'>
    <FormFieldSkeleton hasHelpText />

    <FormFieldSkeleton />

    <FormFieldSkeleton />

    <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
  </Flex>
)

const ServicedeskSkeleton = () => {
  return (
    <Flex direction='column' gap='24px' width='100%'>
      <TabsSkeleton />
      <TicketConfigurationSkeleton />
      <div className={styles.footerSkeleton}>
        <div className={`${styles.shimmerBg} ${styles.h36} ${styles.w120}`} />
      </div>
    </Flex>
  )
}

export default ServicedeskSkeleton
