import { baseApi, TAGS } from './baseApi'

export const confluenceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfluenceApp: builder.query({
      query: () => ({
        url: '/v1/apps/confluence',
        method: 'GET'
      }),
      providesTags: [TAGS.CONFLUENCE]
    }),

    installConfluence: builder.mutation({
      query: (data) => ({
        url: '/v1/apps/confluence',
        method: 'POST',
        body: data
      }),
      invalidatesTags: [TAGS.CONFLUENCE, TAGS.APPS, TAGS.ACCOUNT]
    }),

    uninstallConfluence: builder.mutation({
      query: () => ({
        url: '/v1/apps/confluence',
        method: 'DELETE'
      }),
      invalidatesTags: [TAGS.CONFLUENCE, TAGS.APPS, TAGS.ACCOUNT]
    }),

    enableSyncConfluence: builder.mutation({
      query: () => ({
        url: '/v1/apps/confluence/sync/enable',
        method: 'POST'
      }),
      invalidatesTags: [TAGS.CONFLUENCE]
    })
  })
})

export const {
  useGetConfluenceAppQuery,
  useInstallConfluenceMutation,
  useUninstallConfluenceMutation,
  useEnableSyncConfluenceMutation
} = confluenceApi
