import { Flex } from '@happyfoxinc/web-components'
import React from 'react'

import styles from 'Src/pagesv3/Apps/components/Loader/common-skeleton.module.css'

const AppInstalledCardSkeleton = () => (
  <div className={styles.cardSkeleton}>
    <div className={`${styles.flexCenter} ${styles.gap12}`}>
      <div className={styles.iconSkeleton} />
      <div>
        <div className={`${styles.titleSkeleton} ${styles.w180}`} />
        <div className={`${styles.textSkeleton} ${styles.w240} ${styles.opacity70}`} />
      </div>
    </div>
  </div>
)

const KnowledgeConfigurationSkeleton = () => (
  <div className={styles.configPanel}>
    <div className={`${styles.titleSkeleton} ${styles.w200} ${styles.mb24}`} />

    <div className={`${styles.flexBetween} ${styles.mb16}`}>
      <div className={`${styles.buttonSkeleton} ${styles.w32}`} />
      <div className={`${styles.textSkeleton} ${styles.w100}`} />
      <div className={`${styles.buttonSkeleton} ${styles.w32}`} />
    </div>

    <div className={styles.tableContainer}>
      <div className={`${styles.tableHeader} ${styles.gridCols3}`}>
        <div className={`${styles.textSkeleton} ${styles.w200}`} />
        <div className={`${styles.textSkeleton} ${styles.w120}`} />
        <div className={`${styles.textSkeleton} ${styles.w60}`} />
      </div>

      <div>
        {[1, 2, 3, 4].map((index) => (
          <div key={index} className={`${styles.tableRow} ${styles.gridCols3}`}>
            <div className={`${styles.flexCenter} ${styles.gap8}`}>
              <div className={`${styles.circleSkeleton} ${styles.h24} ${styles.w24}`} />
              <div className={`${styles.textSkeleton} ${styles.w200}`} />
            </div>
            <div className={`${styles.badgeSkeleton} ${styles.w80}`} />
            <div className={`${styles.circleSkeleton} ${styles.h24} ${styles.w24}`} />
          </div>
        ))}
      </div>
    </div>
  </div>
)

const AppFooterSkeleton = () => (
  <div className={styles.flexBetween}>
    <div className={`${styles.buttonSkeleton} ${styles.w120}`} />
  </div>
)

const ConfluenceSkeleton = () => {
  return (
    <Flex width='100%' gap='25px' direction='column'>
      <AppInstalledCardSkeleton />
      <KnowledgeConfigurationSkeleton />
      <AppFooterSkeleton />
    </Flex>
  )
}

export default ConfluenceSkeleton
