import { Box, Button, Flex, Table, TableOption, TableOptions } from '@happyfoxinc/web-components'
import { Fragment, useMemo, useState } from 'react'
import { useTable } from 'react-table'

import styles from './Workspaces.module.css'

import PlusIcon from 'Src/assetsv3/icons/plus.svg'
import TableLoader from 'Src/componentsv3/TableLoader'
import { useGetWorkspacesQuery } from 'Src/servicesV3/workspaceApi'

import CreateWorkspace from './CreateWorkspace'
import EditWorkspace from './EditWorkspace'

const WorkspacesList = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const { data: workspacesApiResponse = {}, isLoading: isWorkspaceApiLoading } = useGetWorkspacesQuery()
  const { results: workspacesList = [] } = workspacesApiResponse

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: (row) => row,
        Cell: ({ value }) => (
          <div className={styles.workspaceNameCell}>
            {value.name}
            {value.settings && value.settings.default ? <div className={styles.defaultLabel}>Default</div> : null}
          </div>
        )
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => {
          const MAX_LENGTH = 120
          return value && value.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value || '~'
        }
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data: workspacesList
  })

  const handleEditClick = (workspace) => {
    setSelectedWorkspace(workspace)
  }

  const handleCloseEditModal = () => {
    setSelectedWorkspace(null)
  }

  if (isWorkspaceApiLoading) {
    return <TableLoader row={5} columns={2} showOptions />
  }

  return (
    <Fragment>
      <Table.Root {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
            return (
              <Table.Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps()
                  return (
                    <Table.ColumnHeaderCell key={key} {...restColumnProps}>
                      {column.render('Header')}
                    </Table.ColumnHeaderCell>
                  )
                })}
                <Table.ColumnHeaderCell isOption />
              </Table.Row>
            )
          })}
        </Table.Header>

        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...restRowProps } = row.getRowProps()
            return (
              <Table.Row key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps()
                  return (
                    <Table.Cell
                      key={key}
                      {...restCellProps}
                      onClick={() => handleEditClick(row.original)}
                      style={{ cursor: 'pointer' }}
                    >
                      {cell.render('Cell')}
                    </Table.Cell>
                  )
                })}
                <TableOptions>
                  <TableOption onClick={() => handleEditClick(row.original)}>Edit</TableOption>
                  {!row?.original?.settings?.default && <TableOption>Delete</TableOption>}
                </TableOptions>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>

      {selectedWorkspace && (
        <EditWorkspace
          isOpen={Boolean(selectedWorkspace)}
          onClose={handleCloseEditModal}
          workspace={selectedWorkspace}
        />
      )}
    </Fragment>
  )
}

const Workspaces = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='12px'>
          <h1 className='heading'>Workspace</h1>
          <Button variant='solid' size='small' radius='full' className={styles.addButton} onClick={handleOpenModal}>
            <PlusIcon />
          </Button>
        </Flex>
        <p className='sub-heading'>
          Workspace classification directs your requests to the appropriate department. Each workspace is designed to
          manage specific tasks and projects.
        </p>
      </div>
      <WorkspacesList />
      <CreateWorkspace isOpen={isModalOpen} onClose={handleCloseModal} />
    </Box>
  )
}

export default Workspaces
