import styles from './AppLoader.module.css'

import HappyFoxLoader from './happyfox-loader.gif'

const AppLoader = ({ isLoading = false, children }) => {
  if (isLoading) {
    return (
      <div className={styles.Container}>
        <img width='50' height='50' src={HappyFoxLoader} alt='Loading...' />
      </div>
    )
  }

  return children
}

export default AppLoader
