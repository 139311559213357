// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LY_U4A1t1br09kIzYvWX{width:100%}.u_SiNisXcm1izX_x_ttJ{border-radius:0px 0px 8px 8px}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Apps/pages/AppDetails/apps/Guru/Guru.module.css"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAIF,sBACE,6BAAA","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n\n.modalBody {\n  border-radius: 0px 0px 8px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "LY_U4A1t1br09kIzYvWX",
	"modalBody": "u_SiNisXcm1izX_x_ttJ"
};
export default ___CSS_LOADER_EXPORT___;
