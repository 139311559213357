import { Box, Flex } from '@happyfoxinc/web-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Navigate, useParams } from 'react-router-dom'

import styles from './UserGroups.module.css'

import PageLoader from 'Components/PageLoader'
import { APP_SYNCED_FROM_DEFINITIONS, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import BackButton from 'Src/componentsv3/BackButton'
import { useGetAccountQuery } from 'Src/servicesV3/authApi'
import { useGetUserGroupQuery, useUpdateUserGroupMutation } from 'Src/servicesV3/userGroupsApi'
import parseErrorMessage from 'Utils/error-message-parser'

import { useAccessCondition } from './AccessControl/AccessConditionContext'
import UserGroupForm from './UserGroupForm'
import userGroupFormValidationSchema from './UserGroupForm/user-group-form-validation-schema'
import { createPayloadFromUserGroupForm } from './usergroups-helper'

const { MANUAL, CONDITIONAL } = USER_MANAGEMENT_TYPE

const createFormValues = (ug, options) => {
  const addedFrom =
    ug.user_management_type === MANUAL
      ? APP_SYNCED_FROM_DEFINITIONS[MANUAL]
      : ug.user_management_type === CONDITIONAL
      ? APP_SYNCED_FROM_DEFINITIONS[CONDITIONAL]
      : APP_SYNCED_FROM_DEFINITIONS[ug.sync_from_app]

  const default_values = {
    name: ug.name,
    users: ug.users,
    addedFrom,
    syncedFromGroup: ug.sync_from_group
  }

  if (ug.user_management_type === CONDITIONAL) {
    const conditions = []
    if (ug.conditions) {
      ug.conditions.forEach((condition) => {
        condition.sub_conditions.forEach((subCondition) => {
          conditions.push({
            field: options.fields.find((option) => option.id === subCondition.id),
            values: options.values[subCondition.id].filter((option) => subCondition.values.includes(option.name))
          })
        })
      })
    }
    default_values.conditions = conditions
  }

  return default_values
}

const UserGroupFormHandler = (props) => {
  const { userGroup, isRefreshing, onRefresh } = props
  const { data: account } = useGetAccountQuery()
  const accountType = account.account_type

  const { dropdownOptions } = useAccessCondition()

  const formMethods = useForm({
    defaultValues: createFormValues(userGroup, dropdownOptions),
    resolver: yupResolver(userGroupFormValidationSchema)
  })
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods

  const [updateUserGroup, updateUserGroupResult] = useUpdateUserGroupMutation()

  const handleFormSubmit = async (formData) => {
    const payload = {
      ...createPayloadFromUserGroupForm(formData, accountType),
      id: userGroup.id,
      accountType
    }
    const promise = updateUserGroup(payload).unwrap()

    toast.promise(promise, {
      loading: 'Updating user group',
      success: 'User group updated successfully',
      error: parseErrorMessage('Unable to update user group. Try again...')
    })
    promise.then((value) => formMethods.reset(createFormValues(value))).catch(() => {})
  }

  return (
    <Box className='page-content'>
      <div className='heading-container'>
        <Flex align='center' gap='4px'>
          <BackButton className='back-btn' />
          <h1 className='heading'>Edit User Group</h1>
        </Flex>
      </div>
      <Box p='30px' className={styles.formContainer}>
        <FormProvider {...formMethods}>
          <UserGroupForm
            isEdit
            userGroup={userGroup}
            onSubmit={handleSubmit(handleFormSubmit)}
            isLoading={isSubmitting || updateUserGroupResult.isLoading}
            isRefreshing={isRefreshing}
            onRefresh={onRefresh}
          />
        </FormProvider>
      </Box>
    </Box>
  )
}

const EditUserGroup = () => {
  const { id } = useParams()
  const { isLoading, isFetching, data: userGroup, isError, error, refetch } = useGetUserGroupQuery(id)

  const handleRefresh = () => {
    refetch()
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (isError && error.originalStatus === 404) {
    return <Navigate to='..' replace />
  }

  return <UserGroupFormHandler userGroup={userGroup} isRefreshing={isFetching} onRefresh={handleRefresh} />
}

export default EditUserGroup
