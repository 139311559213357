// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NRtArQ2D8myLsoDAqSZV{list-style:none;height:100%;overflow-y:auto}.NRtArQ2D8myLsoDAqSZV .wy5LtknH13vVmse6jazg{color:var(--muted);padding:4px 0;border-bottom:1px solid var(--border-color-lighter)}.NRtArQ2D8myLsoDAqSZV .wy5LtknH13vVmse6jazg:last-of-type{border:0}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Reports/ContentListWithCount/ContentListWithCount.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,WAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CACA,aAAA,CACA,mDAAA,CAGF,yDACE,QAAA","sourcesContent":[".listContainer {\n  list-style: none;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.listContainer .listItem {\n  color: var(--muted);\n  padding: 4px 0;\n  border-bottom: 1px solid var(--border-color-lighter);\n}\n\n.listContainer .listItem:last-of-type {\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": "NRtArQ2D8myLsoDAqSZV",
	"listItem": "wy5LtknH13vVmse6jazg"
};
export default ___CSS_LOADER_EXPORT___;
