import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  useGetProvisioningMetaQuery,
  useGetSoftwareProvisionStepsQuery,
  useSaveSoftwareProvisionStepsMutation
} from 'Src/servicesV3/softwareAccessModuleApi'

const provisionFormSchema = yup.object().shape({
  steps: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      fields: yup.array().of(
        yup.object().shape({
          recipients: yup
            .array()
            .of(yup.string())
            .nullable()
            .test('recipients-required', 'At least one recipient is required', function (value) {
              if (this.from[1].value.name === 'send_instruction' && this.from[0].value.name === 'recipients') {
                const recipients = value || this.from[0].value.recipients || null
                return recipients && recipients.length > 0
              }
              return true
            }),
          instruction: yup
            .string()
            .nullable()
            .test('instruction-required', 'Instruction is required', function (value) {
              if (this.from[1].value.name === 'send_instruction' && this.from[0].value.name === 'instruction') {
                return !!value
              }
              return true
            }),
          user_acknowledgement: yup.boolean().notRequired(),
          approvers: yup
            .array()
            .test('approvers-required', 'At least one approver is required', function (value) {
              if (this.from[1].value.name === 'approval_process' && this.from[0].value.name === 'approvers') {
                return value && value.length > 0
              }
              return true
            })
            .of(yup.string())
            .nullable()
        })
      )
    })
  )
})

const prepareSteps = (data, metadata) => {
  return data.map((step) => {
    if (step.name === 'send_instruction') {
      const sendInstruction = metadata.find((item) => item.name === 'send_instruction')
      return {
        ...sendInstruction,
        isEdit: false,
        fields: sendInstruction.fields.map((field) => {
          if (field.name === 'recipients') {
            return {
              ...field,
              recipients: step.properties.recipients.map((item) => item.toString()) || []
            }
          } else if (field.name === 'instruction') {
            return {
              ...field,
              instruction: step.properties.instruction
            }
          } else if (field.name === 'user_acknowledgement') {
            return {
              ...field,
              user_acknowledgement: step.properties.user_acknowledgement
            }
          } else {
            return field
          }
        })
      }
    } else if (step.name === 'approval_process') {
      const approvalProcess = metadata.find((item) => item.name === 'approval_process')
      return {
        ...approvalProcess,
        isEdit: false,
        fields: approvalProcess.fields.map((field) => {
          if (field.name === 'approvers') {
            return {
              ...field,
              approvers: step.properties.approvers.map((item) => item.toString()) || []
            }
          } else {
            return field
          }
        })
      }
    } else if (step.name === 'access_provision') {
      const accessProvision = metadata.find((item) => item.name === 'access_provision')
      return {
        ...accessProvision,
        ...step,
        isEdit: false
      }
    } else {
      return {
        ...step,
        isEdit: false
      }
    }
  })
}

const prepareDefaultValues = (response, metadata) => {
  if (!metadata || metadata.length === 0) {
    return {
      steps: []
    }
  }

  const accessProvision = metadata.find((item) => item.name === 'access_provision')
  if (!response || response.length === 0) {
    return {
      steps: [{ ...accessProvision, isEdit: false }]
    }
  }

  return {
    steps: prepareSteps(response, metadata)
  }
}

export const useProvisionForm = (catalogItemId, isActive) => {
  const { data: metadata, isLoading: isMetadataLoading } = useGetProvisioningMetaQuery()

  const { data: response, isLoading: isProvisionStepsLoading } = useGetSoftwareProvisionStepsQuery(
    { catalogItemId },
    {
      skip: !catalogItemId || !isActive,
      refetchOnMountOrArgChange: true
    }
  )

  const isLoading = isMetadataLoading || isProvisionStepsLoading

  const methods = useForm({
    resolver: yupResolver(provisionFormSchema),
    defaultValues: prepareDefaultValues(response, metadata),
    mode: 'onChange'
  })

  const [save, saveResults] = useSaveSoftwareProvisionStepsMutation()

  const { append, remove, update, insert, move } = useFieldArray({
    control: methods.control,
    name: 'steps'
  })

  useEffect(() => {
    let mounted = true

    if (response && metadata && isActive && mounted) {
      const values = prepareDefaultValues(response, metadata)
      methods.reset(values, {
        keepDefaultValues: true,
        keepDirty: false
      })
    }

    return () => {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, response])

  if (!isActive) {
    return { methods: null, isLoading: false }
  }

  const resetForm = () => {
    methods.reset(prepareDefaultValues(response, metadata), {
      errors: {},
      keepIsSubmitted: false
    })
  }

  const isProvisionFormDirty = () => {
    return !_.isEqual(methods.getValues(), prepareDefaultValues(response, metadata))
  }

  const handleSave = async (formData) => {
    await methods.trigger()
    const data = formData || methods.getValues()
    const steps = data.steps.map((step) => {
      if (step.name === 'send_instruction') {
        return {
          name: step.name,
          properties: {
            recipients: step.fields.find((field) => field.name === 'recipients')?.recipients || [],
            instruction: step.fields.find((field) => field.name === 'instruction')?.instruction || null,
            user_acknowledgement:
              step.fields.find((field) => field.name === 'user_acknowledgement')?.user_acknowledgement || false
          }
        }
      } else if (step.name === 'approval_process') {
        return {
          name: step.name,
          properties: {
            approvers: step.fields.find((field) => field.name === 'approvers')?.approvers || []
          }
        }
      } else {
        return {
          name: step.name,
          properties: {}
        }
      }
    })

    return save({ catalogItemId, steps })
      .unwrap()
      .then((response) => {
        const steps = prepareSteps(response, metadata)
        methods.setValue('steps', steps)
        return response
      })
      .catch(() => {
        return Promise.reject(new Error('Failed to save provisioning steps'))
      })
  }

  return {
    methods,
    addStep: append,
    removeStep: remove,
    updateStep: update,
    moveStep: move,
    insertStep: insert,
    resetForm,
    save: handleSave,
    saveResults,
    isLoading,
    isProvisionFormDirty
  }
}
