// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".idjh2ETVGFs9Wu45F_Dl{position:relative;margin:24px 0}.Ls8xjAmzZlK2lGbF_mcg{position:absolute;z-index:1}.OuUeirlo5ISB2z0ChtM_{position:absolute;left:11px;top:0;bottom:0;width:2px;border-left:1px solid var(--color-border-default);z-index:0}.hoU5vo7lMuRKIVzYY3cM{min-height:33rem}.cLfZ5OarY4_4MEQtSFTA{left:57px}.cZGxWmxGNTe3nTkKWN9a{position:relative;margin:16px 0}.b6TarJ9MwktbksqSVMug{width:24px;height:24px;background-color:var(--color-surface-default);border-radius:50%;color:var(--color-black);font-size:var(--text-xs);font-weight:var(--font-weight-medium);border:1px solid var(--color-border-default);display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./../src/pagesv3/Modules/SoftwareAccessModule/Provisioning/Provisioning.module.css"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,KAAA,CACA,QAAA,CACA,SAAA,CACA,iDAAA,CACA,SAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,SAAA,CAGF,sBACE,iBAAA,CACA,aAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,6CAAA,CACA,iBAAA,CACA,wBAAA,CACA,wBAAA,CACA,qCAAA,CACA,4CAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".workflowContainer {\n  position: relative;\n  margin: 24px 0;\n}\n\n.startIconWrapper {\n  position: absolute;\n  z-index: 1;\n}\n\n.stepLine {\n  position: absolute;\n  left: 11px;\n  top: 0;\n  bottom: 0;\n  width: 2px;\n  border-left: 1px solid var(--color-border-default);\n  z-index: 0;\n}\n\n.firstLine {\n  min-height: 33rem;\n}\n\n.secondLine {\n  left: 57px;\n}\n\n.stepWrapper {\n  position: relative;\n  margin: 16px 0;\n}\n\n.stepNumber {\n  width: 24px;\n  height: 24px;\n  background-color: var(--color-surface-default);\n  border-radius: 50%;\n  color: var(--color-black);\n  font-size: var(--text-xs);\n  font-weight: var(--font-weight-medium);\n  border: 1px solid var(--color-border-default);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflowContainer": "idjh2ETVGFs9Wu45F_Dl",
	"startIconWrapper": "Ls8xjAmzZlK2lGbF_mcg",
	"stepLine": "OuUeirlo5ISB2z0ChtM_",
	"firstLine": "hoU5vo7lMuRKIVzYY3cM",
	"secondLine": "cLfZ5OarY4_4MEQtSFTA",
	"stepWrapper": "cZGxWmxGNTe3nTkKWN9a",
	"stepNumber": "b6TarJ9MwktbksqSVMug"
};
export default ___CSS_LOADER_EXPORT___;
